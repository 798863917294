import React, {useState} from "react";
import {PlusIcon} from "@assets/icons"
import {
    Button, ButtonText, Drawer, InputSearch, List, Popover,
    Space, Tag, TypographyText, UploadItem, UploadItemProps
} from "@components"
import {toDateTime, useTranslator} from "@helpers";
import {FileItemProps} from "@types";
import clsx from "clsx";

import "./FilesList.less";

const prefixCls = "cmp-files-list";

export interface FilesListProps {
    size?: UploadItemProps["size"]
    files?: any[]
    value?: any[]
    readonly?: boolean
    onChange?: (value: any[]) => void
    onCreate?: () => void
}

export const FilesList = (props: FilesListProps) => {

    const {files = [], value = [], onChange, onCreate, readonly = false, size = "middle"} = props;

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const {getLocalisation} = useTranslator("components")

    const handleChangeFile = (key: any) => {
        onChange && onChange([...value, key])
        setDrawerVisible(false);
        setSearch('');
    }

    const handleSearch = (e: any) => setSearch(e.currentTarget.value)

    const handleCancelItem = (id: any) => {
        onChange && onChange(value.filter((fileId) => fileId !== id))
    }

    const handleCreate = () => {
        setPopoverVisible(false);
        onCreate && onCreate();
    }

    const handleClose = () => {
        setDrawerVisible(false)
    }

    const getFiles = () => files
        .filter(({id}: FileItemProps) => !value.includes(id))
        .filter(({fileName}: FileItemProps) => search.length > 0 ? fileName?.toLowerCase().includes(search.toLowerCase()) : true)
        .map((file: FileItemProps) => renderItem(file))

    const renderItem = (file: FileItemProps) => {

        const {id, size, description, member_name, tags, fileName, projects, is_published, modifyDate} = file;

        return ({
            value: id,
            label: <Space>{fileName}</Space>,
            getPrefix: () => <UploadItem record={{...file}}/>,
            description: <Space direction="horizontal">
                <Space direction="vertical">
                    {size && <Space size='small' direction="horizontal">
                        <TypographyText disabled level="small">
                            {getLocalisation('size')}: {size}
                        </TypographyText>
                    </Space>}
                    {(tags || !!is_published) && <Space size='small' direction="horizontal">
                        <TypographyText disabled level="small">{getLocalisation('tags')}:</TypographyText>
                        {!!is_published && <Tag size="small" type="error">{getLocalisation('published')}</Tag>}
                        {tags && tags.map(({label, value, type}: any) => (
                            <Tag size="small" key={value} type={type}>{label}</Tag>
                        ))}
                    </Space>}
                    {projects && <Space size='small' direction="horizontal">
                        <TypographyText disabled level="small">{getLocalisation('projects')}:</TypographyText>
                        {projects.map(({ label, value, type }: any) => (
                            <Tag size="small" key={value} type={type}>{label}</Tag>
                        ))}
                    </Space>}
                    {member_name && <Space size='small' direction="horizontal">
                        <TypographyText disabled level="small">
                            {getLocalisation('change')}: {member_name} {toDateTime(modifyDate)}
                        </TypographyText>
                    </Space>}
                    {description && <Space size='small' direction="vertical">
                        <TypographyText level="small">
                            {getLocalisation('description')}:
                        </TypographyText>
                        <TypographyText level="small">
                            {description}
                        </TypographyText>
                    </Space>}
                </Space>
            </Space>
        })
    }


    return (
        <>

            <div className={clsx(`${prefixCls}-field`)}>
                {value && !!value.length && value.map((id: any) => {
                    const file = files.find((file: FileItemProps) => file.id === id);

                    return file && <UploadItem
                        size={size}
                        key={file.id}
                        record={{...file, tooltip: file.fileName}}
                        onRemove={!readonly ? handleCancelItem : undefined}
                    />
                })}
                {!readonly && <Popover
                    placement="bottomLeft"
                    visible={popoverVisible}
                    onVisibleChange={(v) => setPopoverVisible(v)}
                    content={<Space direction="vertical" size="middle">
                        <ButtonText
                            size="small"
                            type="primary"
                            onClick={() => {
                                setDrawerVisible(true);
                                setPopoverVisible(false);
                            }}>{getLocalisation('file_from_system')}</ButtonText>
                        {onCreate && <ButtonText
                            size="small"
                            type="primary"
                            onClick={handleCreate}
                        >{getLocalisation('new_file')}</ButtonText>}
                    </Space>}
                >
                    <Button
                        size="small"
                        type="dropdown"
                        beforeIcon={<PlusIcon/>}
                    />
                </Popover>}
            </div>

            <Drawer
                size="middle"
                visible={drawerVisible}
                onClose={handleClose}
            >
                <Space size='large' direction='vertical'>
                    <TypographyText level="large">{getLocalisation('files')}</TypographyText>
                    <InputSearch
                        size="small"
                        onChange={handleSearch}
                        className={clsx(`${prefixCls}-search`)}
                        placeholder={getLocalisation("search")}
                    />
                    <List
                        onChange={handleChangeFile}
                        items={getFiles()}
                    />
                </Space>
            </Drawer>

        </>
    )
}