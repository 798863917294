import { useEffect, useRef, useState } from "react";
import { Progress, ProgressProps, Space, TypographyText } from "@components";
import clsx from "clsx";
import { useTranslator } from "@helpers";

import './ProgressPassword.less'

const getAsyncZxcvbn = async () => import('zxcvbn')

export interface ProgressPasswordProps {
    className?: ProgressProps["className"];
    password: string;
    onChangeScore?: (score: number) => void
}

const prefixCls = "cmp-progress-password";

export const ProgressPassword: React.FC<ProgressPasswordProps> = (props) => {
    const { className, password, onChangeScore } = props;
    const ZxcvbnRef = useRef<any>();

    const [isLoaded, setIsLoaded] = useState(false);
    const [score, setScore] = useState<number>()

    const { getLocalisation } = useTranslator("components");

    const getStatus = (score: number): ProgressProps['status'] => {
        switch (score) {
            case 0:
                return 'exception';
            case 1:
                return 'exception';
            case 2:
                return 'warning';
            case 3:
                return 'success';
            case 4:
                return 'success';
            default:
                return 'exception'
        }
    }

    const getTitle = (score: number): string => {
        switch (score) {
            case 0:
                return getLocalisation('password_too_simple');
            case 1:
                return getLocalisation('password_too_simple');
            case 2:
                return getLocalisation('password_easy_guess');
            case 3:
                return getLocalisation('strong_password');
            case 4:
                return getLocalisation('strong_password');
            default:
                return getLocalisation('error');
        }
    }

    const getPercent = (score: number): ProgressProps['percent'] => {
        switch (score) {
            case 0:
                return 30;
            case 1:
                return 30;
            case 2:
                return 60;
            case 3:
                return 90;
            case 4:
                return 90;
            default:
                return 30
        }
    }


    useEffect(() => {
        getAsyncZxcvbn().then((zxcvbn) => {
            ZxcvbnRef.current = zxcvbn;
            setIsLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (isLoaded) {
            const { score } = ZxcvbnRef.current.default(password)
    
            setScore(score)
            onChangeScore?.(score)
        }
    }, [isLoaded, onChangeScore, password])

    return (
        <Space direction='vertical'>
            <TypographyText
                className={clsx(
                    `${prefixCls}-text`,
                    `${prefixCls}-status-text-${getStatus(score || 0)}`)
                }
                level="middle"
            >
                {getTitle(score || 0)}
            </TypographyText>
            <Progress
                steps={3}
                className={className}
                status={getStatus(score || 0)}
                percent={getPercent(score || 0)}
            />
        </Space>
    );
};