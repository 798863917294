import React, { useState } from "react";
import { Button, ButtonText, Flag, FlagProps, Popover, Space } from "@components";
import { LanguageItemProps } from "@types";
import { PlusIcon } from "@assets/icons";
import clsx from "clsx";

import "./FlagsList.less";
import { useTranslator } from "@helpers";

const prefixCls = "cmp-flags-list";

export interface FlagsListProps {
    value?: Array<any>;
    options?: Array<LanguageItemProps>
    inputType?: 'select' | 'radio' | "single";
    onChange?: FlagProps["onChange"];
    onCreate?: (action: "create" | "link") => void;
    onRemove?: FlagProps["onRemove"];
    className?: string;
    size?: FlagProps["size"]
}
export const FlagsList: React.FC<FlagsListProps> = (props: FlagsListProps) => {

    const [popBranchVisible, setPopBranchVisible] = useState<boolean>(false);
    
    const { getLocalisation } = useTranslator("components")

    let {
        value = [], options, onChange, onCreate, onRemove,
        className, size = 'default', inputType = 'radio'
    } = props;

    const handleClick = (newValue: any) => {
        if (inputType === 'select') {
            if (value.find((item: string) => item === newValue)) {
                value = value.filter((item: string) => newValue !== item);
            } else {
                value.push(newValue);
            }
            onChange && onChange([...value]);
        }

        if (inputType === 'radio') {
            onChange && onChange(newValue || value);
        }

        if (inputType === "single") {
            onChange && onChange((newValue !== value && newValue) || undefined);
        }
    };

    const hasActive = (item: LanguageItemProps) => value && (item.id === value || (value.indexOf && value.indexOf(item.id) !== -1))

    return (
        <div className={clsx(className, prefixCls)}>
            <div className={clsx(`${prefixCls}-list`)}>
                {(options || []).map((item: LanguageItemProps) => (
                    <Flag
                        size={size}
                        item={item}
                        value={value}
                        key={item.id}
                        onChange={handleClick}
                        checked={hasActive(item)}
                        onRemove={!hasActive(item) ? onRemove : undefined}
                    />
                ))}
            </div>
            {onCreate &&
                <Popover
                    placement="bottom"
                    visible={popBranchVisible}
                    onVisibleChange={(v) => setPopBranchVisible(v)}
                    content={(
                        <Space direction="vertical" size="middle">
                            <ButtonText
                                size="small"
                                type="primary"
                                onClick={() => {
                                    onCreate && onCreate("create");
                                    setPopBranchVisible(false);
                                }}
                            >{getLocalisation('add_lang')}</ButtonText>
                            <ButtonText
                                size="small"
                                type="primary"
                                onClick={() => {
                                    onCreate && onCreate("link");
                                    setPopBranchVisible(false);
                                }}
                            >{getLocalisation('select_lang')}</ButtonText>
                        </Space>
                    )}
                >
                    <Button
                        size="small"
                        type="dropdown"
                        beforeIcon={<PlusIcon />}
                        className={clsx({
                            [`${prefixCls}-create-small`]: size === 'small'
                        })}
                    />
                </Popover>
            }
        </div>
    )
};
