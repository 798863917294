import React from "react";
import { KnowledgeItemProps, KnowledgeVersionProps } from "@types";
import { useTranslator } from "@helpers";
import { FilesList, Space, TypographyText } from "@components";
import clsx from "clsx";

import "./CompareVersionItem.less";
import { CompareProvider, useCompareLibs } from "./CompareProvider";


export interface CompareVersionItemProps {

    currentVersion?: KnowledgeVersionProps
    currentKnowledgeItem?: KnowledgeItemProps

    differentVersion?: KnowledgeVersionProps
    differentKnowledgeItem?: KnowledgeItemProps

    type?: 'versions' | 'other',
    currentContent?: string,
    newContent?: string,
    leftTitle?: string,
    rightTitle?: string
}

const htmlOptions = {
    indent_size: 4,
    html: {
        end_with_newline: true
    }
};

const prefixCls = "cmp-compare-version-item";

export const CompareVersion: React.FC<CompareVersionItemProps> = (props: CompareVersionItemProps) => {
    const { JsBeautify, ReactDiff } = useCompareLibs();
    const { getLocalisation } = useTranslator("components")
    const {
        currentVersion, differentVersion, currentKnowledgeItem, differentKnowledgeItem,
        type = 'versions', currentContent, newContent, leftTitle, rightTitle
    } = props;

    const formatHTML = (htmlStr: string) => {
        const formattedHTML = JsBeautify.html(htmlStr, htmlOptions);
        return new DOMParser().parseFromString(formattedHTML, "text/html").body.innerText;
    }

    return (<>
        {type === 'versions' && (<Space direction="vertical" size='large' className={clsx(`${prefixCls}`)}>
            {!!currentKnowledgeItem?.content_change && <Space direction='vertical'>
                <TypographyText>{getLocalisation('annotation')}:</TypographyText>
                <div className={clsx(`${prefixCls}-description`)} dangerouslySetInnerHTML={{ __html: currentKnowledgeItem.content_change }} />
            </Space>}

            {!!currentVersion?.content && <Space direction='vertical'>
                <TypographyText>{getLocalisation('source')}:</TypographyText>
                <div className={clsx(`${prefixCls}-description`)} dangerouslySetInnerHTML={{ __html: currentVersion.content }} />
            </Space>}

            {!!(differentKnowledgeItem?.content || currentKnowledgeItem?.content) && <div className={clsx({
                [`${prefixCls}-empty`]: !differentKnowledgeItem || !differentKnowledgeItem.content
            })}>
                {differentKnowledgeItem && differentKnowledgeItem.content && <ReactDiff.default
                    showDiffOnly={false}
                    splitView={true}
                    newValue={formatHTML(currentKnowledgeItem?.content || '')}
                    oldValue={formatHTML(differentKnowledgeItem?.content || '')}
                    compareMethod={ReactDiff.DiffMethod.WORDS}
                    rightTitle={currentVersion && `${getLocalisation('content_version')}: ${currentVersion?.name}`}
                    leftTitle={differentVersion && `${getLocalisation('content_version')}: ${differentVersion?.name}`}
                />}

                {!differentKnowledgeItem && <Space direction='vertical'>
                    <TypographyText
                    >{currentVersion && `${getLocalisation('content_version')}: ${currentVersion?.name}`}</TypographyText>
                    <div
                        className={clsx(`${prefixCls}-content-body`)}
                        dangerouslySetInnerHTML={{ __html: currentKnowledgeItem?.content || '' }}
                    />
                </Space>}
            </div>}

            {!!(differentKnowledgeItem?.files?.length || currentKnowledgeItem?.files?.length) &&
                <div className={clsx(`${prefixCls}-files`)}>
                    {!!differentKnowledgeItem?.files?.length && <Space direction='vertical'>
                        <TypographyText
                        >{`${getLocalisation('version_documents')} ${differentVersion?.name}:`}</TypographyText>
                        <FilesList
                            size="middle"
                            readonly={true}
                            files={differentKnowledgeItem.files}
                            value={differentKnowledgeItem.files.map(({ id }: any) => id)}
                        />
                    </Space>}
                    {!!currentKnowledgeItem?.files?.length && <Space direction='vertical'>
                        <TypographyText
                        >{`${getLocalisation('version_documents')} ${currentVersion?.name}:`}</TypographyText>
                        <FilesList
                            size="middle"
                            readonly={true}
                            files={currentKnowledgeItem.files}
                            value={currentKnowledgeItem.files.map(({ id }: any) => id)}
                        />
                    </Space>}
                </div>
            }
        </Space>)}

        {type === 'other' && <Space className={clsx(`${prefixCls}`)}><ReactDiff.default
            showDiffOnly={false}
            splitView={true}
            oldValue={formatHTML(newContent || '')}
            newValue={formatHTML(currentContent || '')}
            compareMethod={ReactDiff.DiffMethod.WORDS}
            leftTitle={leftTitle}
            rightTitle={rightTitle}
        /></Space>}

    </>
    );
}


const CompareVersionAsync = (props: CompareVersionItemProps) => {
    const { isLoaded } = useCompareLibs();

    if (!isLoaded) {
        return null;
    }

    return <CompareVersion {...props} />;
};

export const CompareVersionItem = (props: CompareVersionItemProps) => {
    return (
        <CompareProvider>
            <CompareVersionAsync {...props} />
        </CompareProvider>
    );
};
