import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import {settings} from "@helpers";
import App from './App';

if (settings.YM) {
    // @ts-ignore
    window.ym(93876823, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
    });
}

if (settings.ENABLE_TWIN) {
    const div = document.createElement("div");
    div.id = "twin-chat-container";
    const script = document.createElement('script');
    script.innerText = `(function () {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://modern-v3.twin24.ai/manual.umd.min.js';
        document.head.appendChild(script);
        script.onload = () => {
            TwinChat.init('#twin-chat-container', {
                chatId: '445a1c28-44ce-4aa3-ab9a-f8836e41ae3b',
                appearance: {
                    lang: 'ru',
                    start: {
                        mode: 'messengerButtons',
                    },
                },
            
            }).then(client => window.TwinClient = client);
        };
      })();`
    document.body.appendChild(div);
    document.body.appendChild(script);
}


ReactDOM.render(
    <BrowserRouter>
        <CookiesProvider>
            <App/>
        </CookiesProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
