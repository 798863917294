import React from "react";
import { Button, ButtonText, FieldList, Space } from "@components";
import { FilterDropdownProps } from "antd/lib/table/interface";

import './TableFilter.less'
import { useTranslator } from "@helpers";

export interface TableFilterProps extends FilterDropdownProps {}

export const TableFilter: React.FC<TableFilterProps> = (props: TableFilterProps) => {
    const {
        confirm, selectedKeys, setSelectedKeys, clearFilters, filters
    } = props;

    const { getLocalisation } = useTranslator("components")

    const handleChangeFilter = (value: any, setSelectedKeys: any) => {
        setSelectedKeys(value)
    }

    const handleReset = (clearFilters: () => void, confirm: () => void) => {
        clearFilters();
        confirm();
    };

    return (
        <Space direction="vertical" size='middle'>
            <FieldList
                size='small'
                value={selectedKeys}
                onChange={(value) => handleChangeFilter(value, setSelectedKeys)}
                defaultValue={selectedKeys}
                options={filters?.map(({ text, value }) => ({
                    label: text,
                    value: value
                }))}
            />
            <Space size='middle'>
                <Button
                    onClick={() => confirm()}
                    type="primary"
                    size="small"
                >{getLocalisation('confirm')}</Button>
                <ButtonText
                    onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                    type="primary"
                    size="small"
                >{getLocalisation('reset_filter')}</ButtonText>
            </Space>
        </Space>
    )
}