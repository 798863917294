import React, { memo, useEffect, useRef, useState } from 'react';
import {
    Popover, Tag, TypographyText, InputProps, Space,
    ButtonPopconfirm, Loader, Button, Form, FormItem,
    RadioGroup, useForm, Combobox, ComboboxProps, useComboboxController
} from '@components';
import { useBreakpoint, useTranslator } from "@helpers";
import { PlusIcon } from "@icons";

import clsx from 'clsx';
import './TagsInput.less';

export type TagsInputOptionProps = any;

const defaultValue = {
    value: null,
    type: "default"
};

export interface TagsInputProps {
    minValue?: number
    value?: any;
    options?: TagsInputOptionProps;
    placeholder?: string;
    suffix?: React.ReactNode;
    prefix?: React.ReactNode;
    size?: InputProps['size'];
    loading?: boolean;
    defaultSearch?: string;
    type?: 'tags' | 'keywords';
    onInputChange?: ComboboxProps['onInputChange'];
    onVisibleChange?: ComboboxProps['onVisibleChange'];
    minChars?: ComboboxProps['minChars'];
    typeCombobox?: ComboboxProps['type']
    onSearch?: (value: any) => void;
    onCreate?: (value: any, metaData?: any) => void;
    onChange?: (value: any) => void;
    onLoadData?: (search?: any, metaData?: any) => any
}

const prefixCls = 'cmp-tags-input'

export const TagsInput: React.FC<TagsInputProps> = memo((props: TagsInputProps) => {

    const {
        options = [], placeholder, prefix, size = 'large', suffix,
        onSearch, onChange, value, onCreate, loading, onLoadData,
        type = 'keywords', typeCombobox = 'input', minChars = 0,
        onVisibleChange, onInputChange, minValue = 0, defaultSearch
    } = props;

    const { getLocalisation } = useTranslator("components")
    const comboboxController = useComboboxController();
    const { isTablet } = useBreakpoint();

    const [form] = useForm();
    const [spin, setSpin] = useState<boolean>();
    const tmLoading = useRef<any>(null);
    const [search, setSearch] = useState<string>('');
    const [innerValue, setInnerValue] = useState<any[]>(value || []);
    const [resultOptions, setResultOptions] = useState<TagsInputProps["options"]>([]);
    const [visibleForm, setVisibleForm] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);

    const handleChange = async (search: any) => {
        onSearch && onSearch(search);

        const result = onLoadData ? await onLoadData(search) : options.filter(({label, value}: any) => (
            label.toLowerCase().includes(search.toLowerCase()) && innerValue.indexOf(value) === -1
        ));

        setResultOptions(result);
    }

    const handleVisibleChange = (visible: any) => {
        if (!visible) {
            comboboxController.setSearch('');
        }
    }

    const handleClick = (value: any) => {
        comboboxController.setVisible(false);
        comboboxController.setSearch('');
        setInnerValue([...innerValue, value]);
    };

    const handleDelete = (deleteValue: any) => {
        setInnerValue(innerValue.filter((value: any) => value !== deleteValue))
    };

    const handleVisibleChangePopover = (visibleForm: boolean) => {
        setVisibleForm(visibleForm);
    };

    const handleInputChange = (value: any) => {
        setSearch(value);

        onInputChange && onInputChange(value)
    }

    const handleInputKeyDown = (event: any) => {
        const value = event.target.value;

        if (!value.length && event.keyCode === 8 && !!innerValue.length) {
            setInnerValue(innerValue.slice(0, -1))
        }
    }

    const handleFinish = (value: any) => {
        onCreate && onCreate({ ...value, label: search }, { setVisibleForm, setLoadingForm })
        comboboxController.setSearch('');
        comboboxController.setVisible(true);
    }

    const handleClickCreate = (event: any) => {
        event.stopPropagation()
        form.setFieldsValue({ ...defaultValue, label: search })
        comboboxController.setVisible(false);
        setVisibleForm(true);
    }


    const getContent = () => (resultOptions && resultOptions.length && <>
        {resultOptions.slice(0, 10).map(({ value, label }: any) =>
            <span onClick={() => handleClick(value)} key={value}>
                <TypographyText className={clsx(`${prefixCls}-item`)}>{label}</TypographyText>
            </span>
        )}
        {resultOptions.length > 10 && <TypographyText
            disabled
            level='small'
        >{getLocalisation('enter_more_for_keyword')}</TypographyText>}
    </>)

    const handleCreate = ({ setVisible }: any) => {
        onCreate && onCreate(search);
        comboboxController.setSearch('');
        setVisible(false)
    }

    const getOptionsRadio = () => [
        {
            label: <span
                className={clsx(`${prefixCls}-circle`, `${prefixCls}-circle-default`)}
            >{getLocalisation('normal')}</span>,
            value: 'default',
        },
        {
            label: <span
                className={clsx(`${prefixCls}-circle`, `${prefixCls}-circle-warning`)}
            >{getLocalisation('warning')}</span>,
            value: 'warning',
        },
        {
            label: <span
                className={clsx(`${prefixCls}-circle`, `${prefixCls}-circle-success`)}
            >{getLocalisation('informing')}</span>,
            value: 'success',
        }
    ]

    const getForm = () => (
        <Form
            form={form}
            layout='vertical'
            onFinish={handleFinish}
            initialValues={{ ...defaultValue }}
            className={clsx(`${prefixCls}-form`)}
        >
            <FormItem
                name="type"
                label={getLocalisation('create_tag')}
                rules={[{ required: true, message: getLocalisation('please_add_priority_tag') }]}
            >
                <RadioGroup options={getOptionsRadio()} direction={isTablet ? 'vertical' : 'horizontal'} />
            </FormItem>
            <Space>
                <Button
                    size="small"
                    type='primary'
                    htmlType="submit"
                    loading={loadingForm}
                >{getLocalisation('confirm')}</Button>
                <Button
                    size="small"
                    type='secondary'
                    onClick={() => setVisibleForm(false)}
                >{getLocalisation('close')}</Button>
            </Space>

        </Form>
    );

    const getPrefix = () => (
        <>
            {prefix}
            {options && innerValue && !!innerValue.length && innerValue.map((value) => {
                const option = options.find((option: any) => option.value === value);

                return option && <Tag
                    key={option.value}
                    type={option.type}
                    showPopconfirm={false}
                    disabled={innerValue.length <= minValue}
                    onRemove={innerValue.length > minValue ? (() => handleDelete(option.value)) : undefined}
                    size={size === 'small' ? 'small' : 'middle'}
                >{option.label}</Tag>
            })}

        </>
    )

    const getSuffix = () => (
        <Space>
            {suffix}
            {onCreate && type === 'keywords' && <ButtonPopconfirm
                type='dropdown'
                size='small'
                placement="topRight"
                afterIcon={<PlusIcon />}
                title={getLocalisation('want_add')}
                onClick={handleCreate}
                className={clsx(`${prefixCls}-button-create`, {
                    [`${prefixCls}-button-create-show`]: search.length >= 1
                })}
            />}
            {spin && type === 'keywords' && <Loader spinning={spin} className={clsx(`${prefixCls}-loader`)} size='small' />}
            {onCreate && type === 'tags' && <Popover
                placement="bottom"
                visible={visibleForm}
                trigger="click"
                onVisibleChange={handleVisibleChangePopover}
                content={
                    <div onClick={(e: any) => e.stopPropagation()}>
                        {getForm()}
                    </div>
                }
            >
                <Button
                    size='small'
                    type='dropdown'
                    onClick={handleClickCreate}
                    beforeIcon={<PlusIcon />}
                    className={clsx(`${prefixCls}-button-create`, {
                        [`${prefixCls}-button-create-show`]: search.length >= 1
                    })}
                />
            </Popover>}
        </Space>
    )

    useEffect(() => {
        if (
            JSON.stringify((innerValue || []).sort((a: any, b: any) => a - b)) !==
            JSON.stringify((value || []).sort((a: any, b: any) => a - b))
        ) {
            onChange && onChange(innerValue)
        }
    // eslint-disable-next-line
    }, [innerValue])

    useEffect(() => {
        if (
            JSON.stringify((innerValue || []).sort((a: any, b: any) => a - b)) !==
            JSON.stringify((value || []).sort((a: any, b: any) => a - b))
        ) {
            setInnerValue(value)
            comboboxController.setSearch('');
        }
    // eslint-disable-next-line
    }, [value])

    useEffect(() => {

        if (loading) {
            setSpin(loading);
            return
        }

        if (tmLoading.current) {
            clearTimeout(tmLoading.current);
        }

        tmLoading.current = setTimeout(() => setSpin(loading), 700);

        return () => tmLoading.current && clearTimeout(tmLoading.current)

    }, [loading]);

    useEffect(() => {
        handleChange && handleChange(defaultSearch || "");
        comboboxController.setSearch(defaultSearch || "");
    // eslint-disable-next-line
    }, [defaultSearch])

    return (
        <Combobox
            size={size}
            placement="bottom"
            minChars={minChars}
            type={typeCombobox}
            prefix={getPrefix()}
            suffix={getSuffix()}
            content={getContent()}
            onSearch={handleChange}
            placeholder={placeholder}
            controller={comboboxController}
            onVisibleChange={onVisibleChange || handleVisibleChange}
            onInputChange={handleInputChange}
            onInputKeyDown={handleInputKeyDown}
            className={clsx(`${prefixCls}`)}
        />

    );
});