import React, { useContext, useState } from 'react';
import clsx from 'clsx';

import { SessionsApi } from '@api';
import { SessionProps, SessionValuesProps } from '@types';
import { SessionsContext } from '@context';
import { useTranslator } from "@helpers";
import { Form, Input, Button, FormItem, InputPassword, Space, TypographyTitle } from '@components';
import "./SignInLoginForm.less";

const defaultInitialValues = {
    login: "",
    password: ""
}

const prefixCls = "sign-in-login-form";

export interface SignInLoginFormProps {
    onForgotPassword: () => void
}

export const SignInLoginForm: React.FC<SignInLoginFormProps> = (props: SignInLoginFormProps) => {
    const { onForgotPassword } = props;
    const { onChangeSession } = useContext(SessionsContext);
    const { getLocalisation } = useTranslator("sign-in")

    const [loading, setLoading] = useState<boolean>(false);


    const handleFinish = async (values: SessionValuesProps) => {
        setLoading(true);
        const response: SessionProps | null = await SessionsApi.create(values);
        setLoading(false);

        if (!response) {
            return;
        }

        const { session_hash } = response;

        onChangeSession(session_hash);
    }

    return (
        <Space className={clsx(`${prefixCls}`)} direction="vertical" size="large">
            <TypographyTitle>{getLocalisation('welcome')}</TypographyTitle>
            <Form
                layout="vertical"
                autoComplete="off"
                name="page-sign-in"
                initialValues={{ ...defaultInitialValues }}
                className={clsx(`${prefixCls}-form`)}
                onFinish={handleFinish}
            >

                <FormItem
                    label={getLocalisation('fieldLogin')}
                    name="login"
                    rules={[{ required: true, message: getLocalisation('fieldLoginRequired') }]}
                >
                    <Input placeholder={getLocalisation('your_login')} />
                </FormItem>

                <FormItem
                    label={getLocalisation('fieldPassword')}
                    name="password"
                    rules={[{ required: true, message: getLocalisation('fieldPasswordRequired') }]}
                >
                    <InputPassword placeholder={getLocalisation('your_password')} />
                </FormItem>
                <Space>

                    <FormItem>
                        <Button
                            block
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                        >{getLocalisation('button')}</Button>
                    </FormItem>
                    <FormItem>
                        <Button
                            block
                            type='secondary'
                            onClick={onForgotPassword}
                        >{getLocalisation('forgot_password')}</Button>
                    </FormItem>
                </Space>
            </Form>
        </Space>
    )
};
