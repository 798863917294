import React, {useRef} from "react";
import {Popover as AntdPopover, PopoverProps as AntdPopoverProps} from "antd";
import clsx from "clsx";
import "./Popover.less";

export interface PopoverProps {
    onVisibleChange?: AntdPopoverProps["onVisibleChange"];
    className?: AntdPopoverProps["className"];
    placement?: AntdPopoverProps["placement"];
    children?: AntdPopoverProps["children"];
    visible?: AntdPopoverProps["visible"];
    trigger?: AntdPopoverProps["trigger"];
    content?: AntdPopoverProps["content"];
    title?: AntdPopoverProps['title'];
    getPopupContainer?: AntdPopoverProps['getPopupContainer']
    arrow?: boolean;
}

const prefixCls = "cmp-popover";

export const Popover: React.FC<PopoverProps> = (props: PopoverProps) => {
    const {
        children, className, placement, visible, content,
        onVisibleChange, trigger = "click", title, arrow = true,
        getPopupContainer = () => (popupContainer && popupContainer.current) || document.body
    } = props;

    const popupContainer = useRef(null);

    return (
        <div ref={popupContainer} className={clsx(className, `${prefixCls}-cnt`, {
            [`${prefixCls}-has-arrow`]: arrow
        })}>
            <AntdPopover
                destroyTooltipOnHide={true}
                getPopupContainer={getPopupContainer}
                prefixCls={clsx(className, `${prefixCls}`)}
                onVisibleChange={onVisibleChange}
                arrowPointAtCenter={false}
                placement={placement}
                content={content}
                visible={visible}
                trigger={trigger}
                title={title}
            >{children}</AntdPopover>
        </div>
    );
};
