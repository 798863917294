import React, { useCallback } from "react";
import { Table as AntdTable, TableProps as AntdTableProps } from 'antd';
import { Card, EmptyRequest, Space } from "@components";
import { ArrowDownIcon, ArrowUpIcon } from "@assets/icons";
import { TableFilter } from "./TableFilter";

import './Table.less'

export interface TableProps {
    className?: string;
    summary?: any;
    columns: AntdTableProps<any>["columns"];
    dataSource?: AntdTableProps<any>["dataSource"]
    size?: AntdTableProps<any>["size"]
    sticky?: AntdTableProps<any>["sticky"]
    scroll?: AntdTableProps<any>["scroll"]
    onChange?: AntdTableProps<any>["onChange"]
}

const prefixCls = "cmp-table";

export const Table: React.FC<TableProps> = (props: TableProps) => {
    const {
        dataSource, size, sticky = false, summary,
        scroll, columns, className, onChange
    } = props;

    const getColumns = (columns: TableProps["columns"]) => {
        columns = columns?.map((item) => {
            if (item.filters) {
                item['filterDropdown'] = (props) => <TableFilter {...props} />
            }
            if (item.sorter) {
                return {
                    ...item,
                    title: (({ sortColumns }: any) => {
                        const sortedColumn = sortColumns?.find(({ column }: any) => column.label === item.title);
                        return (
                            <Space size='small'>
                                {item.title}
                                {sortedColumn && sortColumns[0].order === "ascend" && <ArrowUpIcon />}
                                {sortedColumn && sortColumns[0].order === "descend" && <ArrowDownIcon />}
                            </Space>
                        )
                    })
                }
            }
            return item
        })
        return columns
    }

    const renderTotal = useCallback(() => {
        const transparentColumns: any = [];

        if (!summary || !columns) {
            return null
        }

        const getKeys = (columns: any) => {
            columns.map(({dataIndex, columns, ...props}: any) => {
                if (columns) {
                    return getKeys(columns);
                }
                transparentColumns.push({dataIndex, columns, ...props})
            })
        }

        getKeys(columns);

        return <AntdTable.Summary fixed="top">
            <AntdTable.Summary.Row>
                {transparentColumns.map(({dataIndex, align, renderTotal}: any, i: number) => <AntdTable.Summary.Cell
                    key={i}
                    index={i}
                    align={align}
                >{renderTotal ? renderTotal(summary[dataIndex], summary) : summary[dataIndex]}</AntdTable.Summary.Cell>)}
            </AntdTable.Summary.Row>
        </AntdTable.Summary>
    }, [dataSource, columns, summary]);

    return (
        <Card className={`${prefixCls}-card`}>
            <AntdTable
                summary={renderTotal}
                onChange={onChange}
                tableLayout="auto"
                prefixCls={prefixCls}
                size={size}
                columns={getColumns(columns)}
                scroll={scroll}
                sticky={sticky}
                pagination={false}
                showSorterTooltip={{ prefixCls: 'cmp-tooltip', overlay: "" }}
                dataSource={dataSource}
                className={className}
                locale={{
                    emptyText() {
                        return <EmptyRequest />
                    }
                }}
            />
        </Card>
    )
}