import axios from 'axios'
import {settings} from "@helpers";
import {ProcedureItemProps} from "@types";

export const transformItem = ({permissions, ...values}: any) => ({
    ...values,
    permissions: (permissions || []).map(({permission_id, permission_name, is_system}: any) => ({
        id: permission_id,
        name: permission_name,
        is_system: !!is_system
    }))
})


export async function update(data: ProcedureItemProps) {
    const {id} = data;

    const res: any = await axios.request({
        url: id ?
            `${settings.API_PREFIX}/system/procedures/${id}` :
            `${settings.API_PREFIX}/system/procedures`,
        method: id ? 'PUT' : 'POST',
        data
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function remove(id: string | number) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/procedures/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.result;

    return result;
}

export async function getList(params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/procedures`,
        method: 'GET',
        params: {params}
    }).catch((e) => {console.error(e)});

    const result: Array<ProcedureItemProps> = res?.data?.result.map((item: any) => transformItem(item));

    return result;
}

export async function getRecord(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/procedures/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: ProcedureItemProps = res?.data?.success && transformItem(res?.data?.result[0]);

    return result;
}


export const ProceduresApi = {getList, getRecord, update, remove}
