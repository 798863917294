import React from "react";
import clsx from "clsx";

import './Result.less'

export interface ResultProps { 
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    extra?: React.ReactNode;
}

const prefixCls = "cmp-result";


export const Result: React.FC<ResultProps> = (props: ResultProps) => {

    const {title, subTitle, extra} = props

    return (
        <div className={clsx(prefixCls)}>
            <div>{title}</div>
            <div>{subTitle}</div>
            <div>{extra}</div>
        </div>
    );
}

