import axios from "axios";
import { settings } from "@helpers";
import { ProjectItemProps } from '@types';

export const transformItem = ({name, id, description, languages, logo}: any) => ({
    logo: logo,
    label: name || "N/A",
    value: id,
    description: description,
    languages: languages ? languages : [],
})

export async function getList(params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/projects`,
        method: 'GET',
        params: {params}
    }).catch((e) => {console.error(e)});

    const result: Array<ProjectItemProps> = res?.data?.success && res?.data?.result.map((item: any) => transformItem(item));

    return result;
}

export async function update(data: ProjectItemProps) {
    const {value} = data;

    const res: any = await axios.request({
        url: value ?
            `${settings.API_PREFIX}/projects/${value}` :
            `${settings.API_PREFIX}/projects`,
        method: value ? 'PUT' : 'POST',
        data: {...data, name: data.label}
    }).catch((e) => {console.error(e)});

    const result: ProjectItemProps = res?.data?.success && res?.data?.result && transformItem(res?.data?.result[0]);

    return result;
}

export async function remove(id: string | number) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/projects/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function getRecord(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/projects/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: ProjectItemProps = res?.data?.success && res?.data?.result && transformItem(res?.data?.result[0]);
    return result;
}

export async function setLogo(data: any) {
    const res: any = await axios.request({
        url: `${settings.FILE_PREFIX}/logos/${data.id}`,
        method: 'POST',
        data
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}


export const ProjectsApi = { update, remove, getRecord, getList, setLogo };
