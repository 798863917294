import React, {useEffect, useState} from "react";
import {
    Modal, Button, Form, Loader, FieldDisplay,
    FormItem, Input, useForm, Textarea,
} from "@components";

import {SectionItemProps} from "@types";
import {useTranslator} from "@helpers";
import {SectionsApi} from "@api";

import "./KnowledgeSectionForm.less";

const defaultValue: SectionItemProps = {
    id: null,
    name: "",
    description: "",
};

export interface KnowledgeSectionFormProps {
    id: any
    visible?: boolean
    sectionName?: string
    setVisible: (visible: any) => void
    onSubmit: (values: any, metaData: any) => void
}

export const KnowledgeSectionForm = (props: KnowledgeSectionFormProps) => {
    const [form] = useForm();
    const { getLocalisation } = useTranslator("knowledge");

    const {visible, setVisible, id, onSubmit, sectionName} = props;
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [record, setRecord] = useState<SectionItemProps>();

    const handleCancel = () => {
        setVisible(false);
    };

    const handleSave = () => {
        form.submit();
    };

    const getTitle = () => id === "none" ? getLocalisation('create_section') : getLocalisation('update_section');

    useEffect(() => {
        const fetchInit = async () => {
            setLoading(true);

            const result = id !== "none" && (await SectionsApi.getRecord(id));
            const record: SectionItemProps = {...defaultValue, name: sectionName, ...(result || {})};

            setRecord(record);

            setLoading(false);
        };

        form.resetFields();

        if (id && visible) {
            fetchInit().catch(console.error);
        }
    }, [id, visible, form, sectionName]);

    useEffect(() => {
        form.setFieldsValue({...record});
    }, [record, form]);

    return (
        <Modal
            title={getTitle()}
            visible={visible}
            onCancel={handleCancel}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleSave}
                    loading={submitting}
                >
                    {getLocalisation('save')}
                </Button>,
                <Button
                    key="cancel"
                    type="secondary"
                    onClick={handleCancel}
                    disabled={submitting}
                >
                    {getLocalisation('close')}
                </Button>,
            ]}
        >
            <Loader spinning={loading} size="middle">
                {!loading && (
                    <Form
                        form={form}
                        layout="vertical"
                        autoComplete="off"
                        onFinish={(values: any) => onSubmit(values, {setSubmitting})}
                        initialValues={{...record}}
                    >
                        <FormItem layout="horizontal" label="ID" name="id">
                            <FieldDisplay/>
                        </FormItem>

                        <FormItem
                            label={getLocalisation('name')}
                            name="name"
                            rules={[
                                { required: true, message: getLocalisation('please_name') },
                                { whitespace: true, message: getLocalisation('please_name') }
                            ]}
                        >
                            <Input/>
                        </FormItem>

                        <FormItem label={getLocalisation('description')} name="description">
                            <Textarea/>
                        </FormItem>
                    </Form>
                )}
            </Loader>
        </Modal>
    );
};
