import React, {useState, useEffect, useRef, useContext} from "react";
import {ButtonText, InputSearch, Tooltip, Space} from "@components";
import {useBreakpoint, useTranslator} from "@helpers";
import {ExpandToolsIcon, CloseIcon} from "@assets/icons";
import {ProjectsContext} from "@context";
import {GlobalSearch} from "@pages";
import {MainFormSearch} from "./MainFormSearch";
import {KnowledgesApi} from "@api";
import clsx from "clsx";

import "./MainSearch.less";

import {KnowledgeItemProps} from "@types";

export interface MainSearchProps {}

const prefixCls = "page-main-search";
const minChars = 3;

const defaultFormValues: any = {
    search: '',
    date: "all",
    languages: 'all',
    sections: [],
    categories: [],
    keywords: [],
    tags: []
}

export const MainSearch: React.FC<MainSearchProps> = () => {

    const {getLocalisation} = useTranslator("main");

    const refTimer = useRef<any>(null);

    const {isTablet} = useBreakpoint();
    const {currentProject} = useContext(ProjectsContext);

    const [visible, setVisible] = useState<boolean>(false);
    const [changeFormFields, setChangeFormFields] = useState<boolean>();
    const [formVisible, setFormVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<any>();

    const [search, setSearch] = useState<string>('');
    const [focused, setFocused] = useState<boolean>(false);
    const [findItems, setFindItems] = useState<KnowledgeItemProps[]>();
    const [values, setValues] = useState<any>({...defaultFormValues});

    const handleCloseForm = () => {
        setFormVisible(false);
    }

    const handleClickReset = () => {
        setSearch("");
    }

    const handleClose = () => {
        setVisible(false);
    }

    const handleResetForm = (form: any) => {
        form.setFieldsValue({...defaultFormValues});
    }

    const handleChange = (event: any) => {
        const { value } = event.target;
        setSearch(value);
    }

    const handleFocus = () => {
        setFocused(true);
    }

    const handleBlur = () => {
        setFocused(false);
    }

    const getKeyObject = (values: any) => {
        const _values = Object.keys(values)
            .sort((a: any, b: any) => a - b)
            .reduce((acc: any, key: any,) => ({...acc, [key]: values[key]}), {});

        return JSON.stringify(_values)
    }

    const handleSubmit = (newValues: any) => {

        const _defaultFormValues = Object.keys(newValues)
            .reduce((acc: any, key: any,) => ({...acc, [key]: defaultFormValues[key]}), {});

        setChangeFormFields(getKeyObject(newValues) !== getKeyObject(_defaultFormValues));

        setValues({...values, ...newValues});
        setFormVisible(false);
    }

    const handlePressEnter = (event: any) => {
        const { value } = event.target;
        setValues({...values, search: value, __trigger: (new Date()).getTime()});
    }

    useEffect(() => {

        const fetchData = async () => {

            const loadingKey = (new Date()).getTime();

            setLoading(loadingKey);

            const {search, project_id, date, languages, sections, categories, keywords, tags} = values;

            const findItems = await KnowledgesApi.search({
                search, project_id, date,
                languages: languages === 'all' ? null : languages,
                sections: sections?.join(","),
                groups: categories?.join(","),
                keywords: keywords?.join(','),
                tags: tags?.join(",")

            });

            setLoading((value: any) => loadingKey === value ? false : value);

            if (findItems) {
                setFindItems((findItems || []).slice(0, 20));
            }
        }

        if (visible) {
            fetchData().catch(console.error);
        }

    }, [values, visible])

    useEffect(() => {

        if (currentProject?.value === values?.project_id) {
            return
        }

        setChangeFormFields(false);

        setValues({
            ...defaultFormValues, search,
            project_id: currentProject?.value
        });

    }, [currentProject, values, search])

    useEffect(() => {

        refTimer.current && clearTimeout(refTimer.current);

        refTimer.current = setTimeout(async () => {

            if (search.length >= minChars && focused) {

                setVisible(true);

                const mergeValue = {...defaultFormValues, ...values, search};

                if (getKeyObject(values) === getKeyObject(mergeValue)) {
                    return
                }

                setValues(mergeValue);
            }
        }, 500);

    }, [focused, search]);

    return (
        <div className={clsx(`${prefixCls}`, {
            [`${prefixCls}-tablet`]: isTablet,
        })}>

            <InputSearch
                loading={!!loading}
                size="large"
                value={search}
                onChange={handleChange}
                onPressEnter={handlePressEnter}
                placeholder={getLocalisation('search_article')}
                onBlur={handleBlur}
                onFocus={handleFocus}
                suffix={<Space>
                    {!!search && <ButtonText
                        size='large'
                        beforeIcon={<CloseIcon />}
                        onClick={handleClickReset}
                    />}
                    {findItems && visible && <Tooltip
                        placement="right"
                        title={getLocalisation('advanced_filter')}
                    >
                        <ButtonText
                            size="large"
                            onClick={() => setFormVisible(true)}
                            className={clsx(`${prefixCls}-button`)}
                            beforeIcon={<>
                                <ExpandToolsIcon />
                                {changeFormFields && <div className={clsx(`${prefixCls}-check`)} />}
                            </>}
                        />
                    </Tooltip>}
                </Space>}
            />

            <MainFormSearch
                onSubmit={handleSubmit}
                initialValues={values}
                visible={formVisible}
                onClose={handleCloseForm}
                onReset={handleResetForm}
            />

            <GlobalSearch
                items={findItems}
                visible={visible}
                onClose={handleClose}
                contextId={values?.project_id}
            />

        </div>
    );
};
