import {MenuItemProps} from '@types';
import {getRoute} from './Utils';

const menuReportItems: Array<MenuItemProps> = [
    {
        path: getRoute('reportArticles'),
        control: "menu.item.reports.articles.view",
        name: "Статьи",
        id: "reportArticles"
    }
];

export {menuReportItems};
