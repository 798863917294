import React  from 'react';
import { useBreakpoint } from "@helpers";
import { LogoImg } from '@assets/images';

import logo from "../../assets/images/SignInImg.png";
import clsx from 'clsx';

import './InitPage.less'

const prefixCls = "cmp-init-page"

export interface InitPageProps {
    children?: JSX.Element | JSX.Element[] | null
    className?: any
}

export const InitPage: React.FC<InitPageProps> = (props: InitPageProps) => {
    const { children, className } = props;
    const { isMobile } = useBreakpoint();

    return (
        <div className={clsx(`${prefixCls}`, className, {
            [`${prefixCls}-mobile`]: isMobile
        })}>
            <div className={clsx(`${prefixCls}-header`)}>
                <span className={clsx(`${prefixCls}-logo`)}>
                    <LogoImg />
                </span>
            </div>
            <div className={clsx(`${prefixCls}-body`)}>
                <img className={clsx(`${prefixCls}-img`)} src={logo} alt="logo"/>
                <div className={clsx(`${prefixCls}-form`)}>{children}</div>
            </div>
        </div>
    );
}