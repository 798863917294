import axios from "axios";
import {settings} from "@helpers";
import {PermissionItemProps} from "@types";

export async function update(data: PermissionItemProps) {
    const {id} = data;

    const res: any = await axios.request({
        url: id ?
            `${settings.API_PREFIX}/system/permissions/${id}` :
            `${settings.API_PREFIX}/system/permissions`,
        method: id ? 'PUT' : 'POST',
        data
    }).catch((e) => {console.error(e)});

    const result: PermissionItemProps = res?.data?.success && res?.data?.result[0];

    return result;
}

export async function remove(id: string | number) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/permissions/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}
export async function getRecord(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/permissions/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: PermissionItemProps = res?.data?.success && res?.data?.result[0];
    return result;
}

export async function getList(params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/permissions`,
        method: 'GET',
        params: {params}
    }).catch((e) => {console.error(e)});

    const result: Array<PermissionItemProps> = res?.data?.result;

    return result;
}

export const PermissionsApi = { update, remove, getRecord, getList };
