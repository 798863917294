import {Anchor as AntdAnchor, AnchorProps as AntdAnchorProps} from 'antd'
import clsx from 'clsx'
import './Anchor.less'

const prefixCls = "cmp-anchor"
const {Link} = AntdAnchor

type AnchorItem = {
    href: string
    title: string
    child?: AnchorItem[]
}

export interface AnchorProps {
    affix?: AntdAnchorProps['affix']
    offsetTop?: AntdAnchorProps['offsetTop']
    targetOffset?: AntdAnchorProps['targetOffset']
    getContainer?: AntdAnchorProps['getContainer']
    items: AnchorItem[]
    className?: AntdAnchorProps['className']
    onClick?: AntdAnchorProps['onClick']
}

export const Anchor: React.FC<AnchorProps> = (props: AnchorProps) => {
    return (
        <div className={clsx(prefixCls, props.className)}>
            <AntdAnchor {...props}>
                {props.items.map(({href, title, child}: AnchorItem) => (<Link
                    key={href}
                    href={href}
                    title={title}
                >
                    {child && child.map((props: AnchorItem) => {
                        return <Link key={props.href} {...props} />
                    })}
                </Link>))}
            </AntdAnchor>
        </div>

    )
}