import axios from "axios";
import { settings } from "@helpers";
import { ResponseItemProps } from "@types";
import { getAuthKey } from "./Systems";


const getStatus = (status_id: any) => {
    switch (status_id) {
            case 0:
                return 'disabled';
            case 1:
                return 'active';
            case 2:
                return 'verification';
            default:
                return null;
        }
}

const getType = (type_id: any) => {
     switch (type_id) {
            case 1:
                return 'chat';
            case 2:
                return 'voice';
            default:
                return null;
        }
}

const transformItem = ({ status_id, type_id, ...values}: any) => ({
    ...values,
    status: getStatus(status_id),
    type: getType(type_id)
})


export async function getList(id?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/response/item/${id}`,
        method: 'GET',
    }).catch((e) => {console.error(e)});

    const result: Array<ResponseItemProps> = res?.data?.success && res?.data?.result.map((item: any) => transformItem(item));
    return result;
}

export async function update(data: ResponseItemProps) {
    const {id, type} = data;
    const typeId = type && type[0] === 'voice' ? 2 : 1;

    const res: any = await axios.request({
        url: id ?
            `${settings.API_PREFIX}/response/${id}` :
            `${settings.API_PREFIX}/response`,
        method: id ? 'PUT' : 'POST',
        data: {...data, type_id: typeId}
    }).catch((e) => {console.error(e)});

    const result: ResponseItemProps = res?.data?.success && transformItem(res?.data?.result);

    return result;
}

export async function remove(id: string | number) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/response/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function getRecordUrl(id: any) {

    const key = await getAuthKey();

    if (!key) {
        return false
    }
    
    // eslint-disable-next-line
    const origin = String(settings.API_PREFIX).indexOf("http") > -1 ? settings.API_PREFIX : `${location.origin}${settings.API_PREFIX}`

    return `${origin}/response/get/record/${id}?auth-key=${key}`;
}

export async function getRecord(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/response/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: ResponseItemProps = res?.data?.success && transformItem(res?.data?.result[0]);
    return result;
}

export async function changeStatus(id: any, status: ResponseItemProps['status']) {
     const res: any = await axios.request({
        url: `${settings.API_PREFIX}/response/${id}/${status}`,
        method: 'PUT'
    }).catch((e) => {console.error(e)});

    const result: ResponseItemProps = res?.data?.success;
    return result;
}


export const ResponseApi = { update, remove, getRecord, getList, changeStatus, getRecordUrl };
