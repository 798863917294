import React from "react";
import {Space, TypographyTitle, Button} from '@components';
import {useTranslator} from "@helpers";
import {SuccessIcon} from "@icons";
import clsx from 'clsx';

import './SignInRestoreSuccess.less'

const prefixCls = "sign-in-restore-success";

export const SignInRestoreSuccess = ({onBack}: any) => {
    const { getLocalisation } = useTranslator("sign-in");

    return (
        <Space className={clsx(`${prefixCls}`)} align="center" direction="vertical" size="large">

            <span className={clsx(`${prefixCls}-icon`)}>
                <SuccessIcon />
            </span>

            <TypographyTitle>{getLocalisation('send_mail_massage')}</TypographyTitle>

            <Button
                key="sign-in"
                type="secondary"
                onClick={onBack}
            >{getLocalisation('back')}</Button>

        </Space>
    );
};