import React from "react";
import {Drawer as AntdDrawer, DrawerProps as AntdDrawerProps} from "antd";
import {CloseIcon} from "@assets/icons";
import clsx from "clsx";

import "./Drawer.less";
import { useBreakpoint } from "@helpers";

export interface DrawerProps {
    children?: AntdDrawerProps["children"];
    destroyOnClose?: AntdDrawerProps["destroyOnClose"];
    visible?: AntdDrawerProps["visible"];
    className?: AntdDrawerProps["className"];
    size: "small" | "middle" | "large";
    placement?: "right" | "left" | "top";
    mask?: AntdDrawerProps["mask"];
    closable?: AntdDrawerProps["closable"];
    prefixCls?: AntdDrawerProps["prefixCls"];
    title?: AntdDrawerProps["title"]
    onClose?: () => void;

}

const prefixCls = "cmp-drawer";

export const Drawer: React.FC<DrawerProps> = (props: DrawerProps) => {
    const {
        children, visible, onClose, className, size,
        placement = "right", mask, closable, title,
        destroyOnClose = true
    } = props;

    const { isMobile, isTablet } = useBreakpoint();

    return (
        <AntdDrawer
            destroyOnClose={destroyOnClose}
            prefixCls={clsx(`${prefixCls}`)}
            className={clsx(`${prefixCls}-${size}`, className, {
                [`${prefixCls}-tablet`]: isTablet,
                [`${prefixCls}-mobile`]: isMobile
            })}
            closeIcon={<CloseIcon/>}
            placement={placement}
            closable={closable}
            visible={visible}
            onClose={onClose}
            mask={mask}
            title={title}
        >{children}</AntdDrawer>
    );
};
