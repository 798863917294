import React, {useContext, useEffect, useMemo, useState} from "react";
import {MenuItemProps} from "@types";
import {menuSystemItems, useTranslator} from "@helpers";
import {ControlsContext, ProjectsContext} from "@context";
import {AppIcon} from "@icons";

import "./MainSystem.less";
import {ButtonMenu} from "@components";
import {useLocation} from "react-router-dom";

export interface MainMenuProps {
    onClick?: (item: MenuItemProps) => void;
}

export const MainSystem: React.FC<MainMenuProps> = (props: MainMenuProps) => {
    const {onClick} = props;
    const location = useLocation();
    const { currentProject } = useContext(ProjectsContext)

    const { getLocalisation } = useTranslator("main")

    const [selected, setSelected] = useState<Array<string>>();
    const {controls} = useContext(ControlsContext);

    const items = useMemo(() =>
        menuSystemItems
            .filter(({ control }: MenuItemProps) => !control || !!controls[control])
            .map(({ path, ...arg }: MenuItemProps) => (
                {
                    path: path?.replace(":project", currentProject?.value),
                    ...arg
                }))
    ,[controls, currentProject?.value]);

    useEffect(() => {
        const pathname = location.pathname;

        if (pathname && items) {
            const menuItem = items.find(
                ({path}: MenuItemProps) => path && pathname.indexOf(path) === 0
            );
            setSelected(menuItem && menuItem.path ? [menuItem.path] : []);
        }
    }, [location?.pathname, items]);


    if (!items || !items.length) {
        return null;
    }

    return (
        <ButtonMenu
            label={getLocalisation('services')}
            path={location?.pathname}
            Icon={AppIcon}
            items={items}
            onClick={onClick}
            selected={selected}
        />
    );
};
