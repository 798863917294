import React, { useState, useCallback, useEffect, useContext } from "react";
import { ButtonNews } from "@components";
import { NewsItemProps } from "@types";
import { MainNewsForm } from "./MainNewsForm";
import { NewsApi } from "@api";
import {ControlsContext, ProjectsContext} from "@context";
import { useNavigate } from "react-router-dom";
import { getRoute } from "@helpers";

import "./MainNews.less";


export interface MainNewsProps {}

export const MainNews: React.FC<MainNewsProps> = () => {

    const {currentProject} = useContext(ProjectsContext)
    const { controls } = useContext(ControlsContext);
    const navigate = useNavigate();

    const [items, setItems] = useState<NewsItemProps[]>();
    const [formItemId, setFormItemId] = useState<any>();
    const [checkNews, setCheckNews] = useState<boolean>(true)
    const [visible, setVisible] = useState<boolean>()
    const [filters, setFilters] = useState<string>("new");
    const [search, setSearch] = useState<string>();

    const fetchItems = useCallback(async (filters: any, search?: any) => {
        const items = filters === 'favorites' ?
            await NewsApi.getFavoritesNews({search}) :
            await NewsApi.getList({ filters, search });
        setItems(items || []);
    }, []);

    const handleLoad = useCallback((filters: any, search: any, { setLoading }: any) => {
        setLoading(true)
        setFilters(filters)
        setSearch(search)
        
        fetchItems(filters, search).catch(console.error);

        setTimeout(() => {
            setLoading(false)
        }, 400)
    }, [fetchItems])


    const handleCreate = () => {
        setFormItemId('none');
    }

    const handleEdit = (id: any) => {
        setFormItemId(id)
    }

    useEffect(() => {
        const fetchCheck = async () => {
            const checkNews = await NewsApi.checkNews();
            
            setCheckNews(checkNews);
        }

        fetchCheck().catch(console.error)
    }, [currentProject])


    const handleVisible = async (visible?: boolean) => {

        setVisible(visible)

        if (!visible) {
            const checkNews = await NewsApi.checkNews();

            setCheckNews(checkNews);
        }
    }

    const handleSubmit = async ({ id, ...values }: any, { setSubmitting }: any) => {
        setSubmitting(true);

        const result = await NewsApi.update({ id, ...values });

        if (result) {
            setFormItemId(undefined);
            await fetchItems(filters, search);
        }
        setSubmitting(false);
    }

    const handleRemove = async (id: any, { setLoading }: any) => {
        setLoading(true)

        const result = await NewsApi.remove(id)

        if (result) {
            fetchItems(filters, search).catch(console.error);
        }
        setLoading(false)
    }

    const handleCheck = async (id: any, { setLoading }: any) => {
        setLoading(true)

        const result = await NewsApi.updateView(id)

        if (result) {
            fetchItems(filters, search).catch(console.error);
        }

        setLoading(false)
    }

    const handleClickFavorites = async (id: any, is_favorite: any, { setLoading }: any) => {

        setLoading(true);

        const result = await NewsApi[is_favorite ? "removeFavorites" : "updateFavorites"](id);

        if (result) {
            fetchItems(filters, search).catch(console.error);
        }

        setLoading(false)
    }

    const handleClickHistory = (id: any) => {
        navigate(
            getRoute('historyItem',
                { item: id, name: 'news', 'project': currentProject?.value }
        ))
        setVisible(false)
    }

    return (
        <>
            <ButtonNews
                visible={visible}
                onVisible={handleVisible}
                items={items}
                checkNews={checkNews}
                onLoad={handleLoad}
                onEdit={controls["button.news.edit.view"] && handleEdit}
                onCreate={controls["button.news.create.view"] && handleCreate}
                onRemove={controls["button.news.remove.view"] && handleRemove}
                onCheck={handleCheck}
                onClickHistory={handleClickHistory}
                onClickFavorites={handleClickFavorites}
            />
            <MainNewsForm
                id={formItemId}
                visible={!!formItemId}
                setVisible={setFormItemId}
                onSubmit={handleSubmit}
            />
        </>
    );
};
