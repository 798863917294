import React from "react";
import { Avatar, Space, TypographyText } from "@components";

import './MemberView.less'
import { userShortName } from "@helpers";

export interface MemberViewProps {
    name?: string;
    avatar?: any
}

const prefixCls = "cmp-member-view";

export const MemberView: React.FC<MemberViewProps> = (props: MemberViewProps) => {

    const { name, avatar } = props;

    const login =  name?.split('(')[1].slice(0, -1)
    const nameSplit =  name?.split('(')[0]

    return (
        <Space>
            <Avatar src={avatar} children={userShortName({ login })} />
            <Space className={`${prefixCls}-space`} direction='vertical'>
                {!!nameSplit && <TypographyText disabled level="small">{nameSplit}</TypographyText>}
                <TypographyText disabled level="small">{login}</TypographyText>
            </Space>
        </Space>
    );
}