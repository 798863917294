import {Upload as AntdUpload, UploadProps as AntdUploadProps} from "antd";
import React, {ReactElement, useEffect, useState} from "react";
import { Space, TypographyText } from "@components";
import { UploadItem } from "./UploadItem";
import { DownloadIcon } from "@icons";
import clsx from "clsx";

import "./Upload.less";
import {useTranslator} from "@helpers";

export interface UploadProps {
    data?: AntdUploadProps["data"];
    action?: AntdUploadProps["action"];
    method?: AntdUploadProps["method"];
    prefixCls?: AntdUploadProps["prefixCls"];
    fileList?: AntdUploadProps["fileList"];
    onChange?: AntdUploadProps["onChange"];
    customRequest?: AntdUploadProps["customRequest"];
}

const prefixCls = "cmp-upload";

export const Upload: React.FC<UploadProps> = (props: UploadProps) => {
    const {onChange, fileList} = props;
    const { getLocalisation } = useTranslator("components")
    const [uploaded, setUploaded] = useState<boolean>(false);

    const handleChange = (value: any) => {
        const {fileList} = value;
        setUploaded(!!fileList.length)
        onChange && onChange(value);
    }

    useEffect(() => {
        setUploaded(!!fileList && !!fileList.length)
    }, [fileList])

    return (
        <div className={clsx(`${prefixCls}-cnt`, {
            [`${prefixCls}-uploaded`]: uploaded
        })}>
            <AntdUpload.Dragger
                fileList={fileList || []}
                onChange={handleChange}
                prefixCls={prefixCls}
                customRequest={({onSuccess}: any) => onSuccess && onSuccess()}
                itemRender={(originNode: ReactElement, file: any, fileList: object[], { remove }) => (
                    <UploadItem
                        onRemove={remove}
                        record={file}
                    />
                )}
            >
                <Space direction="vertical" align="center">
                    <DownloadIcon/>
                    <TypographyText level="small">{getLocalisation("select_or_drop_file")}</TypographyText>
                </Space>
            </AntdUpload.Dragger>
        </div>

    );
};