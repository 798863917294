import React, {useState, useContext, useCallback, useEffect, Suspense} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {Loader, Space} from "@components";
import {useBreakpoint, routeItems, useTranslator, getRoute} from "@helpers";
import {ControlsContext, ProjectsContext, SessionsContext} from "@context";
import {MenuItemProps, RoutesItemProps, SectionItemProps} from "@types";
import {KnowledgeItemForm} from "@pages/Knowledge/KnowledgeItemForm";
import {HelpIcon, SingOutIcon} from "@icons";
import {KnowledgesApi, ProjectsApi, SectionsApi} from "@api";
import {LogoImg} from "@images";
import {KEY_LAST_PAGE} from "@helpers/Consts";

import {MainButtonProjects} from "./MainButtonProjects";
import {MainSearch} from "./MainSearch";
import {MainButton} from "./MainButton";
import {MainAvatar} from "./MainAvatar";
import {MainSystem} from "./MainSystem";
import {MainMenu} from "./MainMenu";
import {MainDrawerProjects, MainDrawerProjectItemProps} from "./MainDrawerProjects";
import {MainNews} from "./MainNews";

import clsx from "clsx";
import "./Main.less";
import {MainMenuMobile} from "./MainMenuMobile";
import {MainAvatarMenu} from "./MainAvatarMenu";

export interface MainProps {
}

const prefixCls = "page-main";

export const Main: React.FC<MainProps> = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isMobile, isTablet } = useBreakpoint();
    const { getLocalisation } = useTranslator("main")

    const {controls} = useContext(ControlsContext);
    const {onRemoveSession} = useContext(SessionsContext);
    const {currentProject, setCurrentProject} = useContext(ProjectsContext);

    const [visible, setVisible] = useState<boolean>();
    const [visibleProjects, setVisibleProjects] = useState<boolean>(false);
    const [visibleAvatarMenu, setVisibleAvatarMenu] = useState(false);
    const [sections, setSections] = useState<SectionItemProps[] | null>();

    const handleSubmitItem = async ({ id, section_id, ...values }: any, { setSubmitting }: any) => {
        setSubmitting(true);
        const result = await KnowledgesApi.update(section_id, {...values});
        setSubmitting(false);
        setVisible(false)
        if (result) {
            if (result.id) {
                navigate(getRoute('knowledgeItem', {
                    'project': currentProject?.value,
                    'section': section_id,
                    'item': result.id
                }))
            }
        }
    }

    const handleProject = (project: MainDrawerProjectItemProps) => {
        setCurrentProject(project);
        setVisibleProjects(false);
    };

    const handleClose = () => {
        setVisibleProjects(false);
    };

    const handleMenuItem = (item: MenuItemProps) => {
        if (item.path) {
            navigate(item.path);
        }
    };

    const handleAddSectionClick = () => {
        setVisible(true);
    };

    const handleSectionClick = (path: MenuItemProps['path'], { id }: any) => {
        if (path) {
            navigate(`${path}/section/${id}`);
        }
    };

    const fetchSections = useCallback(async () => {
        const sections = await SectionsApi.getList();
        setSections(sections);
    }, []);

    const handleVisiblePopoverChange = async (visible: boolean, { setLoading }: any) => {
        if (visible) {
            setLoading(true);
            await fetchSections().catch(console.error);
            setLoading(false);
        }
    }

    const handleClickAvatar = () => {
        if (isMobile) {
            setVisibleAvatarMenu(true)
        } else {
            navigate(getRoute('profile'))
        }
    }

    useEffect(() => {
        const parts = pathname.split('/');
        const indexProject = parts.indexOf('project') + 1;

        if (indexProject) {
            parts.splice(indexProject, 1, currentProject?.value);

            navigate(parts.join('/'));
        } else if (pathname === '/') {
            navigate(getRoute('home'))
        } else {
            navigate((localStorage.getItem(KEY_LAST_PAGE) && String(localStorage.getItem(KEY_LAST_PAGE))) || getRoute('home'))
        }

        //eslint-disable-next-line
    }, [currentProject])

    useEffect(() => {
        const fetchProject = async (projectId: any) => {
            const project = await ProjectsApi.getRecord(projectId);
            setCurrentProject(project);
        }

        const parts = pathname.split('/');
        const indexProject = parts.indexOf('project') + 1;

        if (!indexProject) {
            return
        }

        if (currentProject && parts[indexProject] && String(parts[indexProject]) !== String(currentProject.value)) {
            fetchProject(parts[indexProject]).catch(console.error)
        }
        // eslint-disable-next-line
    }, [pathname])

    useEffect(() => {
        if (pathname) {
            document.querySelector(`#root`)?.scrollTo(0, 0);
        }
    }, [pathname])

    return (
        <div
            className={clsx(`${prefixCls}`, {
                [`${prefixCls}-mobile`]: isMobile
            })}
        >

            {!isTablet &&
                <div className={clsx(`${prefixCls}-sider`)}>
                    <div className={clsx(`${prefixCls}-logo`)}>
                        <LogoImg />
                    </div>

                <MainMenu
                    sections={sections}
                    onClick={handleMenuItem}
                    onAddSectionClick={handleAddSectionClick}
                    onSectionClick={handleSectionClick}
                    onVisiblePopoverChange={handleVisiblePopoverChange}
                />

                    <MainSystem onClick={handleMenuItem} />
                </div>
            }

            <div className={clsx(`${prefixCls}-page`)}>
                <div className={clsx(`${prefixCls}-header`, {
                    [`${prefixCls}-header-mobile`]: isMobile
                })}>
                    {isTablet &&
                        <MainMenuMobile
                            sections={sections}
                            onClick={handleMenuItem}
                            onAddSectionClick={handleAddSectionClick}
                            onSectionClick={handleSectionClick}
                            onExpandChange={handleVisiblePopoverChange}
                        />
                    }

                    <MainButtonProjects
                        currentProject={currentProject}
                        onClick={() => setVisibleProjects(!visibleProjects)}
                    />

                    <MainSearch />

                    <Space
                        size="large"
                        align="center"
                        className={clsx(`${prefixCls}-header-right`)}
                    >
                        <Space size="middle">
                            <MainNews />
                            {!isMobile && <>
                                <MainButton
                                    title={getLocalisation('help')}
                                    onClick={() => navigate(getRoute('help'))}
                                    icon={<HelpIcon />}
                                />
                                <MainButton
                                    title={getLocalisation('exit')}
                                    onClick={onRemoveSession}
                                    icon={<SingOutIcon />}
                                />
                            </>
                            }
                        </Space>
                    
                        <MainAvatar
                            onClick={handleClickAvatar}
                        />
                    </Space>
                    <MainAvatarMenu
                        visible={visibleAvatarMenu}
                        onClose={() => setVisibleAvatarMenu(false)}
                        onRemoveSession={onRemoveSession}
                    />
                </div>

                <div>
                    <div className={clsx(`${prefixCls}-content`)}>
                        <KnowledgeItemForm
                            id="none"
                            visible={visible}
                            setVisible={setVisible}
                            action="create"
                            onSubmit={handleSubmitItem}
                        />

                        <MainDrawerProjects
                            currentProject={currentProject}
                            onClick={handleProject}
                            onClose={handleClose}
                            visible={visibleProjects}
                        />

                        <Routes>
                            {routeItems
                                .filter((item: RoutesItemProps) => !item.control || !!controls[item.control])
                                .map(({path, Component}: RoutesItemProps, index: number) => (
                                    <Route
                                        key={index}
                                        path={path}
                                        element={
                                            <Suspense
                                                fallback={
                                                    <Loader className={`${prefixCls}-suspense-loader`} />
                                                }
                                            >
                                                <Component/>
                                            </Suspense>
                                        }
                                    />
                                ))}
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
};
