import {DataIcon, FolderIcon, HistoryIcon, HomeIcon, UsersIcon} from '@icons';
import {MenuItemProps} from '@types';
import {getRoute} from './Utils';

const menuItems: Array<MenuItemProps> = [
    {
        path: getRoute('home'),
        name: "Домашняя страница",
        Icon: HomeIcon
    },
    {
        path: '/project/:project/knowledge',
        name: "База знаний",
        control: "menu.item.knowledge.view",
        Icon: DataIcon
    },
    {
        path: getRoute('files'),
        name: "Файлы",
        control: "menu.item.files.view",
        Icon: FolderIcon
    },
    {
        path: getRoute('users'),
        name: "Пользователи",
        control: "menu.item.users.view",
        Icon: UsersIcon
    },
    {
        path: getRoute('history'),
        name: "История",
        control: "menu.item.history.view",
        Icon: HistoryIcon
    },
];

export {menuItems};
