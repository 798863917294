import axios from 'axios'
import {settings} from "@helpers";
import {NewsItemProps} from "@types";


export async function getList(params?: any) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/news`,
        method: 'GET',
        params
    }).catch((e) => {console.error(e)});
    
    const result: Array<NewsItemProps> = res?.data?.success && res?.data?.result;

    return result;
}


export async function getRecord(id: any) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/news/${id}`,
        method: 'GET',
    }).catch((e) => {console.error(e)});
    
    const result: NewsItemProps = res?.data?.success && res?.data?.result[0];

    return result;
}

export async function checkNews() {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/news/0/view`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success && res?.data?.result[0].count_new_news === 0;

    return result;
}

export async function updateView(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/news/${id}/view`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function update(data: NewsItemProps) {
    const { id } = data;

    const res: any = await axios.request({
        url: id ?
            `${settings.API_PREFIX}/news/${id}` :
            `${settings.API_PREFIX}/news`,
        method: id ? 'PUT' : 'POST',
        data: {...data, type_id: data.type_id[0]}
    }).catch((e) => {console.error(e)});

    const result: NewsItemProps = res?.data?.success && res?.data?.result[0];

    return result;
}

export async function remove(id: any) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/news/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.result;

    return result;
}

export async function getFavoritesNews(params: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/news/favorites`,
        method: 'GET',
        params
    }).catch((e) => {console.error(e)});

    const result: Array<NewsItemProps> = res?.data?.success && res?.data?.result;
    return result;
}

export async function removeFavorites(id: string | number) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/news/${id}/favorites`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: NewsItemProps = res?.data?.success;

    return result;
}

export async function updateFavorites(id: string | number) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/news/${id}/favorites`,
        method: 'POST'
    }).catch((e) => {console.error(e)});

    const result: NewsItemProps = res?.data?.success;

    return result;
}


export const NewsApi = { getList, getRecord, checkNews, updateView, update, remove, getFavoritesNews, removeFavorites, updateFavorites }