import React from "react";
import {ButtonText, ButtonPopconfirm, Tooltip} from "@components";
import {LanguageItemProps} from "@types";
import {ErrorIcon, CheckIcon} from "@assets/icons";
import clsx from "clsx";

import "./Flag.less";
import {useTranslator} from "@helpers";

const prefixCls = "cmp-flag";

export interface FlagProps {
    checked?: boolean
    item: LanguageItemProps
    value?: Array<any>;
    onChange?: (value: any) => void;
    onRemove?: (value: any, metaData: any) => void;
    className?: string;
    size?: "small" | "default"
}

export const Flag: React.FC<FlagProps> = (props: FlagProps) => {
    const { getLocalisation } = useTranslator("components")

    const {onChange, onRemove, className, size = 'default', item, checked} = props;
    const {id, icon, article_id, iso} = item;

    return (
        <span className={clsx(`${prefixCls}`, {
            [`${prefixCls}-active`]: checked
        })}>
            {onRemove && <ButtonPopconfirm
                size="small"
                type="dropdown"
                onClick={(metaData: any) => onRemove(article_id, metaData)}
                afterIcon={<ErrorIcon/>}
                className={clsx(`${prefixCls}-unlink`)}
                title={getLocalisation("unlink_massage")}
            />}
            <Tooltip
                title={iso}
                placement="top"
                mouseEnterDelay={1}
            >
                <span
                    onClick={() => onChange && onChange(id)}
                >
                    <CheckIcon className={clsx(`${prefixCls}-check`)} />
                    <ButtonText
                        beforeIcon={icon}
                        className={clsx(className, `${prefixCls}-button`, `${prefixCls}-button-${size}`)}
                    />
                </span>
            </Tooltip>
        </span>
    )
}
