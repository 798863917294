
export interface SettingsProps {
    API_PREFIX?: string
    FILE_PREFIX?: string
    YM?: boolean
    ENABLE_TWIN?: boolean
}

export const settings: SettingsProps = {
    API_PREFIX: (process.env.REACT_APP_API_PREFIX || "")
        .replace("[protocol]", window.location.protocol)
        .replace("[hostname]", window.location.hostname),

    FILE_PREFIX: (process.env.REACT_APP_FILE_PREFIX || "")
        .replace("[protocol]", window.location.protocol)
        .replace("[hostname]", window.location.hostname),

    YM: process.env.REACT_APP_YM === "true",
    ENABLE_TWIN: process.env.REACT_APP_ENABLE_TWIN === "true"
};

