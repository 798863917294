import axios from 'axios'
import {settings} from "@helpers";

export async function getInfo() {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/informations`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: any = res?.data?.success && res?.data?.result;

    return result;
}

export async function getAuthKey() {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/auth/key`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    return res?.data?.success && res?.data?.result?.key;
}



export const SystemsApi = { getInfo, getAuthKey }
