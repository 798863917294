import React, {useEffect, useRef} from "react";
import {Tooltip as AntdTooltip, TooltipProps as AntdTooltipProps} from 'antd';
import "./Tooltip.less";

export interface TooltipProps {
    title: AntdTooltipProps["title"]
    placement?: AntdTooltipProps["placement"]
    trigger?: AntdTooltipProps["trigger"]
    children: AntdTooltipProps["children"]
    arrowPointAtCenter?: AntdTooltipProps["arrowPointAtCenter"]
    className?: AntdTooltipProps['className']
    visible?: AntdTooltipProps['visible']
    mouseEnterDelay?: AntdTooltipProps['mouseEnterDelay']
}

export const prefixCls = "cmp-tooltip";

export const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {

    const {title, children, placement = "bottom", trigger = "hover",
        arrowPointAtCenter, className, visible, mouseEnterDelay
    } = props;

    const cntEl = useRef<any>(null);

    useEffect(() => {
        const cls = `${prefixCls}-main-cnt`;

        if (!document.body.querySelector(`.${cls}`)) {
            const el = document.createElement("DIV");
            el.className = `${cls}`;
            document.body.appendChild(el);
        }

        cntEl.current = document.body.querySelector(`.${cls}`);

    }, [])

    return (<AntdTooltip
        getTooltipContainer={() => cntEl.current || document.body}
        className={className}
        mouseEnterDelay={mouseEnterDelay}
        destroyTooltipOnHide={true}
        title={title}
        trigger={trigger}
        placement={placement}
        prefixCls={prefixCls}
        visible={visible}
        arrowPointAtCenter={arrowPointAtCenter}
    >{children}</AntdTooltip>);
}