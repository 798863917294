import {
    createContext, ReactNode, useContext,
    useEffect, useMemo, useRef, useState,
} from 'react';

type ReactAvatarType = typeof import('react-avatar-editor');

interface UploadLogoContextPayload {
    ReactAvatar?: ReactAvatarType;
    isLoaded?: boolean;
}

const UploadLogoContext = createContext<UploadLogoContextPayload>({});

const getAsyncUploadLogoModules = async () => import('react-avatar-editor')

export const useUploadLogoLibs = () => {
    return useContext(UploadLogoContext) as Required<UploadLogoContextPayload>;
};

export const UploadLogoProvider = ({ children }: { children: ReactNode }) => {
    const ReactAvatarRef = useRef<ReactAvatarType>();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        getAsyncUploadLogoModules().then((ReactAvatar) => {
            ReactAvatarRef.current = ReactAvatar;
            setIsLoaded(true);
        });
    }, []);

    const value = useMemo(() => ({
        ReactAvatar: ReactAvatarRef.current,
        isLoaded,
    }), [isLoaded]);

    return (
        <UploadLogoContext.Provider
            value={value}
        >
            {children}
        </UploadLogoContext.Provider>
    );
};
