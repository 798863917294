import React, {useState} from "react";
import {Input} from '../Input/Input';
import {Popover, PopoverProps} from '../Popover/Popover';
import {CheckboxGroupProps} from '../CheckboxGroup/CheckboxGroup';
import {useTranslator} from "@helpers";
import { FieldList } from "@components";
import {ArrowDownIcon} from "@icons";
import clsx from "clsx";

import "./MultiSelect.less";

export interface MultiSelectProps {
    value?: CheckboxGroupProps["value"]
    onChange?: CheckboxGroupProps["onChange"]
    defaultValue?: CheckboxGroupProps["defaultValue"]
    className?: PopoverProps["className"]
    options?: CheckboxGroupProps["options"]
}

const prefixCls = "cmp-multi-select";

export const MultiSelect : React.FC<MultiSelectProps> = (props: MultiSelectProps) => {
    const {
        options, className, value, defaultValue, onChange
    } = props;
    const { getLocalisation } = useTranslator("components")
    const [visible, setVisible] = useState<any>(false)

    const getContent = (() => (
        <FieldList
            size="large"
            options={options}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
        />
    ))

    const handleClick = () => {
        setVisible(!visible)
    }

    return (
        <Input
            disabled={true}
            placeholder={value && value.length ? `${getLocalisation('selected')} (${value.length})` : `${getLocalisation('choose')}` }
            className={clsx(`${prefixCls}-input`)}
            onClick={handleClick}
            suffix={<Popover
                className={clsx(`${prefixCls}-popover`, className)}
                content={getContent()}
                placement="bottomRight"
                visible={visible}
                onVisibleChange={setVisible}
            >
                <span
                    onClick={handleClick}
                    className={clsx(`${prefixCls}-input-icon`)}
                >{<ArrowDownIcon/>}</span>
            </Popover>}
        />
    );
}