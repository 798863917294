import {createContext} from 'react';

export type UsersContextProps = {
    currentUser?: any,
    setCurrentUser?: any,
}

const defaultValue: UsersContextProps = {
    currentUser: null,
    setCurrentUser: null,
}

export const UsersContext = createContext(defaultValue);