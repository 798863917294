import {
    createContext, ReactNode, useContext,
    useEffect, useMemo, useRef, useState,
} from 'react';

type ReactDiffType = typeof import("react-diff-viewer-continued");

interface CompareContextPayload {
    ReactDiff?: ReactDiffType;
    JsBeautify?: any;
    isLoaded?: boolean;
}

const CompareContext = createContext<CompareContextPayload>({});

const getAsyncCompareModules = async () => {
    return Promise.all([
        import("react-diff-viewer-continued"),
        import("js-beautify"),
    ]);
};

export const useCompareLibs = () => {
    return useContext(CompareContext) as Required<CompareContextPayload>;
};

export const CompareProvider = ({ children }: { children: ReactNode }) => {
    const ReactDiffRef = useRef<ReactDiffType>();
    const JsBeautifyRef = useRef<any>();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        getAsyncCompareModules().then(([ReactDiff, JsBeautify]) => {
            ReactDiffRef.current = ReactDiff;
            JsBeautifyRef.current = JsBeautify;
            setIsLoaded(true);
        });
    }, []);

    const value = useMemo(() => ({
        ReactDiff: ReactDiffRef.current,
        JsBeautify: JsBeautifyRef.current,
        isLoaded,
    }), [isLoaded]);

    return (
        <CompareContext.Provider
            value={value}
        >
            {children}
        </CompareContext.Provider>
    );
};
