import React, { useState } from "react";
import { CheckboxGroup, CheckboxGroupProps, InputSearch } from '@components';
import { useTranslator } from "@helpers";
import { OptionProps } from "@types";
import clsx from "clsx";
import "./FieldList.less";

export interface FieldListProps {
    value?: CheckboxGroupProps["value"]
    onChange?: CheckboxGroupProps["onChange"]
    defaultValue?: CheckboxGroupProps["defaultValue"]
    options?: CheckboxGroupProps["options"]
    className?: CheckboxGroupProps["className"]
    size?: CheckboxGroupProps["size"]
}

const prefixCls = "cmp-field-list";

export const FieldList: React.FC<FieldListProps> = (props: FieldListProps) => {
    const {
        options, value, defaultValue, onChange, className, size = "middle"
    } = props;

    const { getLocalisation } = useTranslator("components")
    const [search, setSearch] = useState<string>();

    // [TODO] O.Kuzmin Временное решение
    const hasSearched = (text: any, value: any) => {
        const getText = (item: any) => {
            let res = '';

            const innerFn = (child: any) => {
                if (typeof child === 'string' || typeof child === 'number') {
                    res += child + " ";
                } else if (Array.isArray(child)) {
                    child.forEach(c => {
                        return innerFn(c);
                    });
                } else if (child && child.props) {
                    let children = child.props.children;
                    if (Array.isArray(children)) {
                        children.forEach(c => {
                            return innerFn(c);
                        });
                    } else {
                        innerFn(children);
                    }
                }
            };

            innerFn(item);

            return res;
        }
        return (!search || (text && getText(text).toLowerCase().indexOf(search.toLowerCase()) > -1) ||
            (value && getText(value).toLowerCase().indexOf(search.toLowerCase()) > -1));
    }

    return (
        <div className={clsx(`${prefixCls}-body`, className)}>
            <InputSearch
                size="small"
                placeholder={getLocalisation('search')}
                onChange={(e: any) => setSearch(e.target.value)}
            />
            <CheckboxGroup
                size={size}
                value={value}
                onChange={onChange}
                defaultValue={defaultValue}
                options={options && options.map(({ label, style, description, ...props }: OptionProps) => ({
                    label, description, ...props,
                    style: { display: !hasSearched(description, label) ? "none" : "", ...style }
                }))}
            />
        </div>
    );
}