import axios from 'axios'
import {settings} from "@helpers";
import {UserProps} from "@types";
import {transformItem as transformProjectItem} from "./Projects";
import {transformItem as transformRoleItem} from "./Roles";

export const transformItem = ({projects, roles, ...user}: any) => ({
    ...user,
    projects: (projects || []).map((item: any) => transformProjectItem(item)),
    roles: (roles || []).map((item: any) => transformRoleItem(item))
})

export async function getCurrent() {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/info`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: UserProps = res?.data?.success && res?.data?.result[0] && transformItem(res?.data?.result[0]);

    return result;
}

export async function update(data: UserProps) {
    const {id} = data;

    const res: any = await axios.request({
        url: id ?
            `${settings.API_PREFIX}/users/${id}` :
            `${settings.API_PREFIX}/users`,
        method: id ? 'PUT' : 'POST',
        headers: {
            // eslint-disable-next-line
            ["x-service-host"]: String(settings.API_PREFIX).indexOf("http") > -1 ? String(settings.API_PREFIX) : `${location.origin}${String(settings.API_PREFIX)}`
        },
        data
    }).catch((e) => {console.error(e)});

    const result: UserProps = res?.data?.success && res?.data?.result[0] && transformItem(res?.data?.result[0]);

    return result;
}

export async function setAvatar(data: any) {
    const res: any = await axios.request({
        url: `${settings.FILE_PREFIX}/avatars`,
        method: 'POST',
        data
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function block(id: any) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/block/${id}`,
        method: 'PUT'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function getRecord(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: UserProps = res?.data?.success && res?.data?.result[0] && transformItem(res?.data?.result[0]);

    return result;
}

export async function setSystem(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/${id}/revoke`,
        method: 'PUT'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function getList(params: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users`,
        method: 'GET',
        params: {params}
    }).catch((e) => {console.error(e)});

    const result: Array<UserProps> = res?.data?.success && res?.data?.result.map((item: any) => transformItem(item));

    return result;
}

export async function checkEmail(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/approve/${id}`,
        method: 'GET',
        headers: {
            // eslint-disable-next-line
            ["x-service-host"]: String(settings.API_PREFIX).indexOf("http") > -1 ? String(settings.API_PREFIX) : `${location.origin}${String(settings.API_PREFIX)}`
        }
    }).catch((e) => {console.error(e)});

    const result: UserProps = res?.data?.success;

    return result;
}


export async function sendRestore(email: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/restore/${email}`,
        method: 'GET',
        headers: {
            // eslint-disable-next-line
            ["x-service-host"]: String(settings.API_PREFIX).indexOf("http") > -1 ? String(settings.API_PREFIX) : `${location.origin}${String(settings.API_PREFIX)}`
        }
    }).catch((e) => {console.error(e)});

    const result: UserProps = res?.data?.success;

    return result;
}

export async function passwordRestore(hash: any, data: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/password/restore/${hash}`,
        method: 'POST',
        data
    }).catch((e) => {console.error(e)});

    const result: UserProps = res?.data?.success;

    return result;
}

export async function activeUser(hash: any, data: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/active/${hash}`,
        method: 'POST',
        data
    }).catch((e) => {console.error(e)});

    const result: UserProps = res?.data?.success;

    return result;
}

export async function passwordChange(data: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/password/change`,
        method: 'POST',
        data
    }).catch((e) => {console.error(e)});

    const result: UserProps = res?.data?.success;

    return result;
}

export async function restore(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/block/${id}/restore`,
        method: 'PUT'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}


export const UsersApi = {
    setAvatar, getCurrent, getList, getRecord, passwordRestore,
    update, block, setSystem, passwordChange, restore, sendRestore,
    checkEmail, activeUser
}
