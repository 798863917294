import React, {memo, useEffect, useState} from 'react';
import {
    TypographyText, InputProps, Combobox,
    ComboboxProps, useComboboxController,
    Button, ButtonPopconfirm, Tag, Space,
} from '@components';
import {useTranslator} from "@helpers";

import clsx from 'clsx';
import {PlusIcon, TrashIcon} from '@assets/icons';
import {OptionProps} from '@types';

import './AutoComplete.less';

export type AutoCompleteOptionProps = any;


export interface AutoCompleteProps {
    value?: any;
    options: AutoCompleteOptionProps;
    size?: InputProps['size'];
    placeholder?: string;
    type?: "button" | "buttonPopconfirm";
    minChars?: ComboboxProps['minChars'];
    typeCombobox?: ComboboxProps['type'];
    disabled?: ComboboxProps['disabled'];
    loading?: ComboboxProps['loading']

    onChange?: (value: any) => void;
    onCreate?: (value: any) => void;
    onRemove?: (value: any) => void;
    onSearch?: (value: any) => void;
}

const prefixCls = 'cmp-auto-complete'

export const AutoComplete: React.FC<AutoCompleteProps> = memo((props: AutoCompleteProps) => {

    const {
        options = [], placeholder, size = 'middle', type,
        onSearch, onChange, typeCombobox = 'input',
        onRemove, onCreate, minChars = 0, value, disabled,
        loading
    } = props;

    const {getLocalisation} = useTranslator("components")
    const comboboxController = useComboboxController();

    const [resultOptions, setResultOptions] = useState<any[]>([]);
    const [text, setText] = useState<string>('');

    const isCreate = () => (
        onCreate && text?.length && !value && !options?.filter((item: OptionProps) => item?.label?.toString() === text)?.length
    )

    const handleClick = (id: any) => {
        comboboxController.setVisible(false);
        onChange?.(id)
    };

    const handleCreate = ({setVisible}: any) => {
        onCreate && onCreate(text)
        setVisible?.(false)
    }

    const handleChange = (search: any) => {
        onSearch && onSearch(search);
        setText(search)

        const item = options.filter(({value: id}: any) => value === id)[0]?.label
        search !== item && onChange?.(undefined)


        const result = options.filter(({label}: any) => (
            label.toLowerCase().includes(search.toLowerCase())
        ));
        setResultOptions(result);
    }

    const handleRemove = (value: any, {setVisible}: any) => {
        setVisible(false);
        onRemove && onRemove(value);
    }


    const getContent = () => (resultOptions && resultOptions.length && <>
        {resultOptions.map(({value, label, description}: any) => <div
                key={value}
                onClick={() => handleClick(value)}
                className={clsx(`${prefixCls}-item`)}
            >
                <Space
                    justify='space-between'
                    align='center'
                >
                    <Space size="small">
                        <Tag type="warning" size='small'>{value}</Tag>
                        <TypographyText
                            level='small'
                            className={clsx(`${prefixCls}-label`)}
                        >{label}</TypographyText>
                    </Space>
                    {onRemove && <ButtonPopconfirm
                        className={clsx(`${prefixCls}-remove-btn`)}
                        size="small"
                        title={getLocalisation('want_delete')}
                        buttonType='text'
                        placement="bottom"
                        disabled={loading}
                        onClick={({setVisible}) => handleRemove(value, {setVisible})}
                        afterIcon={<TrashIcon/>}
                    />}
                </Space>
                {!!description && <div
                    className={clsx(`${prefixCls}-item-description`)}
                >{description}</div>}
            </div>
        )}
    </>)


    const getSuffix = () => (
        type === 'button' ?
            <Button
                size="small"
                type="dropdown"
                className={clsx(`${prefixCls}-button`, {
                    [`${prefixCls}-button-show`]: isCreate()
                })}
                afterIcon={<PlusIcon/>}
                onClick={handleCreate}
            /> :
            <ButtonPopconfirm
                size="small"
                type="dropdown"
                placement="top"
                title={getLocalisation('want_add')}
                onClick={handleCreate}
                afterIcon={<PlusIcon/>}
                className={clsx(`${prefixCls}-button`, {
                    [`${prefixCls}-button-show`]: isCreate()
                })}
            />
    )

    useEffect(() => {
        if (!value) {
            return
        }

        const result = options.filter(({value: id}: any) => parseInt(id) === parseInt(value))[0]?.label;

        if (result) {
            comboboxController.setSearch(result);
            setText(result)
        }

        // eslint-disable-next-line
    }, [value])

    return (
        <Combobox
            loading={loading}
            disabled={disabled}
            size={size}
            placement="bottom"
            minChars={minChars}
            type={typeCombobox}
            suffix={getSuffix()}
            content={getContent()}
            onSearch={handleChange}
            placeholder={placeholder}
            controller={comboboxController}
            className={clsx(`${prefixCls}`)}
        />
    );
});