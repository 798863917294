import React, { ReactNode } from 'react';
import { Switch as AntdSwitch, SwitchProps as AntdSwitchProps } from 'antd';
import './Switch.less'


export interface SwitchProps {
    loading?: AntdSwitchProps["loading"]
    disabled?: AntdSwitchProps["disabled"]
    onChange?: AntdSwitchProps['onChange']
    value?: AntdSwitchProps["checked"]
    size?: AntdSwitchProps['size']
    className?: AntdSwitchProps['className']
    suffix?: ReactNode
}

export const Switch: React.FC<SwitchProps> = (props: SwitchProps) => {
    const {
        disabled, className, onChange, value,
        size, loading
    } = props;

    return (
        <AntdSwitch
            loading={loading}
            disabled={disabled}
            className={className}
            onChange={onChange}
            checked={value}
            size={size}
        />
    )
}