import React, {useRef, useState} from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from 'tinymce';
import clsx from "clsx";

import "./TextEditor.less";

export interface TextEditorProps { 
    style?: React.CSSProperties;
    value?: string;
    placeholder?: string;
    onChange?: any
}

const prefixCls = "cmp-text-editor";

export const TextEditor: React.FC<TextEditorProps> = (props: TextEditorProps) => {

    const {value, onChange, placeholder} = props;
    const editorRef = useRef<TinyMCEEditor>();
    const [bigEditor] = useState<boolean>(false);

    const uploadHandler = (cb: any) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = () => {
            if(input.files) {
                const file = input.files[0]
                const reader = new FileReader();
                reader.onload = () => {
                    const id = 'blobid' + (new Date()).getTime();
                    if(editorRef.current && reader.result) {
                        const blobCache =  editorRef.current.editorUpload.blobCache;
                        const base64 = reader.result.toString().split(',')[1];
                        const blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        cb(blobInfo.blobUri(), { title: file.name });
                    }
                };
                reader.readAsDataURL(file);
            }
        }

        input.click();
    }

    return (
        <div className={clsx(`${prefixCls}`, {
            [`${prefixCls}-full-screen`]: bigEditor
        })}>
            <Editor
                apiKey='nwals1o344iw7rnes7gxhsv4972b6kmxwfy1uyidadcd3pkv'
                init={{
                    convert_urls: false,
                    language: 'ru',
                    menubar: false,
                    toolbar: 'blocks | removeformat | bold italic bullist numlist | forecolor backcolor | link image table | fullscreen',
                    plugins: 'image lists link anchor table fullscreen',
                    placeholder: placeholder || '',
                    statusbar: false,
                    skin_url: '/skins/ui/tm',
                    toolbar_mode: "wrap",
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    file_picker_callback: uploadHandler
                }}
                value={value}
                onInit={(evt, editor) => editorRef.current = editor}
                onEditorChange={(newValue) => onChange(newValue)}
            />
        </div>
    );
};
