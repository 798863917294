import React, {useState} from "react";
import { useTranslator } from "@helpers";
import { RuleObject } from "antd/lib/form";
import {
    Button, Form, FormItem, InitPage, InputPassword,
    Space, TypographyTitle, useForm, ButtonText, ProgressPassword
} from '@components';
import { useNavigate, useParams } from "react-router-dom";
import { PageRestoreSuccess } from "./PageRestoreSuccess";
import {UsersApi} from "@api";
import clsx from 'clsx';

import './PageRestore.less'


const prefixCls = "page-restore";

export const PageRestore = (props: any) => {
    const {type} = props;
    const [form] = useForm();
    const navigate = useNavigate();
    const { getLocalisation } = useTranslator("page-restore");

    const [submitting, setSubmitting] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [score, setScore] = useState<number>(0)
    const { hash }: any = useParams();

    const handleBack = () => {
        navigate(`/sign-in`);
    }

    const handlePassword = (e: any) => {
        setPassword(e.target.value)
    }

    const handleFinish = async (value: any) => {
        setSubmitting(true)
        delete value['check_password']

        const result = await UsersApi[type === "active" ? "activeUser" : "passwordRestore"](hash, value)

        if (result) {
            form.resetFields();
            setSuccess(true);
        }

        setSubmitting(false)
    }

    const validateCheckPassword = (_: RuleObject, value: any) => {
        if (!value) {
            return Promise.reject(new Error(getLocalisation('please_set_field')))
        }
        if (value && score <= 1) {
            return Promise.reject(new Error(''))
        }
        return Promise.resolve()
    }

    const validateNewPassword = (_: RuleObject, value: any) => {
        if (!value) {
            return Promise.reject(new Error(getLocalisation('please_set_field')))
        }
        if (value && value !== password) {
            return Promise.reject(new Error(getLocalisation('no_match_password')))
        }
        return Promise.resolve()
    }

    return (
        <InitPage className={clsx(`${prefixCls}`)}>
            <>
                {!success && <Space className={clsx(`${prefixCls}`)} direction="vertical" size="large">
                    <TypographyTitle>{getLocalisation('change_password')}</TypographyTitle>
                    <Form
                        layout="vertical"
                        autoComplete="off"
                        name="page-sign-in"
                        className={clsx(`${prefixCls}-form`)}
                        onFinish={handleFinish}
                    >
                        <Space direction="vertical">
                            <FormItem
                                rules={[{validator: validateCheckPassword}]}
                                label={getLocalisation('news_password')}
                                name="check_pswd"
                            >
                                <InputPassword
                                    onChange={handlePassword}
                                    placeholder={getLocalisation('enter_password')}
                                />
                            </FormItem>
                            {password && <ProgressPassword
                                password={password}
                                onChangeScore={setScore}
                            />}
                        </Space>
                        <FormItem
                            label={getLocalisation('repeat_password')}
                            name="password_new"
                            rules={[{validator: validateNewPassword}]}
                        >
                            <InputPassword
                                placeholder={getLocalisation('enter_password_again')}
                            />
                        </FormItem>

                        <Space size="large">
                            <Button
                                key="submit"
                                type="primary"
                                htmlType="submit"
                                loading={submitting}
                            >{getLocalisation('change')}</Button>

                            <ButtonText
                                key="sign-in"
                                size="small"
                                type="primary"
                                onClick={handleBack}
                            >{getLocalisation('back_to_auth')}</ButtonText>
                        </Space>

                    </Form>
                </Space>}
                {success && <PageRestoreSuccess type={type} onBack={handleBack}/>}
            </>
        </InitPage>
    );
};