import React, { ReactNode } from "react";
import { Progress as AntdProgress, ProgressProps as AntdProgressProps } from "antd";
import clsx from "clsx";
import "./Progress.less";
import { Space } from "@components/Space/Space";
import { TypographyText, TypographyTitle } from "@components/Typography/Typography";

export interface ProgressProps {
    className?: AntdProgressProps["className"];
    percent?: AntdProgressProps["percent"];
    suffix?: ReactNode;
    prefix?: ReactNode;
    title?: ReactNode;
    subtitle?: ReactNode;
    status?: AntdProgressProps["status"] | 'warning'
    steps?: AntdProgressProps['steps']
}

const prefixCls = "cmp-progress";

export const Progress: React.FC<ProgressProps> = (props) => {
    const { className, percent, suffix, prefix, title, subtitle, status, steps } = props;

    return (
        <Space direction='vertical'>
            {title && <TypographyTitle>{title}</TypographyTitle>}
            {subtitle && <TypographyText>{subtitle}</TypographyText>}
            <div className={clsx(`${prefixCls}-body`, className)}>
                {suffix}
                <AntdProgress
                    steps={steps ? steps : undefined}
                    className={clsx({ [`${prefixCls}-sts-${status}`]: status })}
                    showInfo={false}
                    prefixCls={prefixCls}
                    percent={percent}
                />
                {prefix}
            </div>
        </Space>
    );
};
