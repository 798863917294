import React, { useMemo } from "react";
import { Breadcrumb, ButtonText, Card, MemberView, Space, TypographyText } from "@components";

import { TrashIcon } from "@assets/icons";
import clsx from "clsx";
import {toDateTime} from "@helpers";

import "./FavoriteList.less";
import { KnowledgeItemProps } from "@types";

const prefixCls = "cmp-favorite-list";

export interface FavoriteListProps {
    className?: string;
    items?: KnowledgeItemProps[];
    onClick?: (id: any, e: any) => void;
    onRemove?: (id: any) => void;
}

const getType = (tags: any) => {
    const mapType: any = {};
    tags && tags.forEach(({ type }: any) => mapType[type] = type);
    return mapType["warning"] || mapType["success"] || mapType["default"]
}

export const FavoriteList: React.FC<FavoriteListProps> = (props) => {

    const { items, className, onClick, onRemove } = props;

    const sortFavoriteItems = useMemo(() => (
        items?.map((item: KnowledgeItemProps) => (
            { ...item, type: getType(item.tags) }
        ))
            .sort((a: any): any => a.type === 'default' && -1)
            .sort((a: any): any => a.type === "success" && -1)
            .sort((a: any): any => a.type === 'warning' && -1)
    ), [items])

    return (
        <div className={clsx(`${prefixCls}-favorite-list`, className)}>
            {(sortFavoriteItems || []).map(({ id, languageIcon, section_name, category_name, title, member_name, modify_date, type }: any) => (
                <Card
                    className={clsx(`${prefixCls}-favorite-item`)}
                    type={type || "none"}
                    size="small"
                    key={id}
                >
                    <Space direction="vertical" size="middle">
                        <Breadcrumb
                            size="small"
                            className={clsx(`${prefixCls}-favorites-title`)}
                            items={[
                                {
                                    icon: languageIcon,
                                    title: section_name
                                },
                                {
                                    title: category_name,
                                },
                                {
                                    title: <span
                                        className={clsx(`${prefixCls}-favorites-button`)}
                                        onClick={(e) => onClick && onClick(id, e)}
                                    >{title}</span>
                                }
                            ]}
                        />
                        <div>
                            <MemberView name={member_name} />
                            <Space className={clsx(`${prefixCls}-space-remove`)}>
                                <TypographyText disabled level="small">{toDateTime(modify_date)}</TypographyText>
                                <ButtonText
                                    onClick={() => onRemove && onRemove(id)}
                                    className={clsx(`${prefixCls}-remove-favorites`)}
                                    afterIcon={<TrashIcon />}
                                />
                            </Space>
                        </div>
                    </Space>
                </Card>
            ))}
        </div>
    )
};
