import React from "react";
import { BackTop as AntdBackTop, BackTopProps as AntdBackTopProps } from 'antd';

import './BackTop.less'
import { ArrowUpIcon } from "@assets/icons";
import { Button } from "@components";

export interface BackTopProps {
    className?: AntdBackTopProps['className']
    duration?: AntdBackTopProps['duration']
    visibilityHeight?: AntdBackTopProps['visibilityHeight']
    target?: AntdBackTopProps['target']
}

const prefixCls = 'cmp-back-top';

export const BackTop: React.FC<BackTopProps> = (props: BackTopProps) => {

    const {
        className,
        visibilityHeight = 300,
        duration = 300,
        target = () => document.querySelector('#root') as HTMLElement
    } = props;

    return (
        <AntdBackTop
            prefixCls={prefixCls}
            duration={duration}
            visibilityHeight={visibilityHeight}
            className={className}
            target={target}
        >
            <Button
                type='primary'
                beforeIcon={<ArrowUpIcon />}
            />
        </AntdBackTop>
    );
}