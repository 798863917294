import axios from 'axios'
import {settings, getLanguageIcon} from "@helpers";
import {KnowledgeItemProps} from "@types";
import {transformItem as transformFileItem} from "./Files";

let controllerSearch: any = null;

const transformItem = ({ tags, keywords, files, articles_languages, language_id, language_iso639_3, projects, ...values}: any) => ({
    ...values, language_id,
    languageIcon: language_iso639_3 && getLanguageIcon(language_iso639_3),
    projects: (projects || []).map(({project_id, project_name}: any) => ({
        label: project_name,
        value: project_id
    })),
    files: files && files.map((file: any) => transformFileItem(file)),
    tags: tags && tags.map(({tag_id, tag_name, type_name}: any) => ({
        label: tag_name,
        value: tag_id,
        type: type_name
    })), 
    keywords: keywords && keywords.map(({ keyword_id, keyword_name }: any) => ({
        label: keyword_name,
        value: keyword_id
    })),
    languages: (articles_languages || []).map(({article_id, language_id, language_name, iso639_3}: any) => ({
        article_id,
        id: language_id,
        name: language_name,
        icon: getLanguageIcon(iso639_3),
        iso: iso639_3
    }))
})


export async function getList(sectionId: any, params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/sections/${sectionId}/knowledges`,
        method: 'GET',
        params
    }).catch((e) => {console.error(e)});

    const result: Array<KnowledgeItemProps> = res?.data?.success &&
        res?.data?.result.map((item: any) => ({...transformItem(item)}));
    return result;
}

export async function getListStatus(params: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledges`,
        method: 'GET',
        params
    }).catch((e) => {console.error(e)});

    const result: Array<KnowledgeItemProps> = res?.data?.success &&
        res?.data?.result.map((item: any) => transformItem(item));

    return result;
}

export async function getContent(contentId: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/content/${contentId}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: KnowledgeItemProps = res?.data?.success && res?.data?.result[0] && transformItem(res?.data?.result[0]);

    return result;
}

export async function update(sectionId: any, data: KnowledgeItemProps) {

    const {id} = data;

    const res: any = await axios.request({
        url: id ?
            `${settings.API_PREFIX}/sections/${sectionId}/knowledges/${id}` :
            `${settings.API_PREFIX}/sections/${sectionId}/knowledges`,
        method: id ? 'PUT' : 'POST',
        data
    }).catch((e) => {console.error(e)});

    const result: KnowledgeItemProps = res?.data?.success && transformItem(res?.data?.result[0]);

    return result;
}

export async function search(params?: any) {

    controllerSearch && controllerSearch.abort();

    controllerSearch = new AbortController();

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/searching`,
        signal: controllerSearch.signal,
        method: 'GET',
        params
    }).catch((e) => {console.error(e)});

    const result: Array<KnowledgeItemProps> = res?.data?.success &&
        res?.data?.result.map((item: any) => ({...transformItem(item) }));

    return result;
}

export async function getRecord(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledges/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: KnowledgeItemProps = res?.data?.success && res?.data?.result[0] && transformItem(res?.data?.result[0]);

    return result;
}

export async function getPublishRecord(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledges/publish/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: KnowledgeItemProps = res?.data?.success && res?.data?.result[0] && transformItem(res?.data?.result[0]);

    return result;
}

export async function getNewArticles(params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/widgets/articles/new`,
        method: 'GET',
        params
    }).catch((e) => {console.error(e)});

    const result: Array<KnowledgeItemProps> = res?.data?.success &&
        res?.data?.result.map((item: any) => (transformItem(item)));

    return result;
}

export async function getVerificationArticles(params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/widgets/articles/verification`,
        method: 'GET', params
    }).catch((e) => {console.error(e)});

    const result: Array<KnowledgeItemProps> = res?.data?.success &&
        res?.data?.result.map((item: any) => (transformItem(item)));

    return result;
}

export async function getFavoritesArticles(params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/favorites`,
        method: 'GET',
        params: {params}
    }).catch((e) => {console.error(e)});

    const result: Array<KnowledgeItemProps> = res?.data?.success &&
        res?.data?.result.map((item: any) => (transformItem(item)));

    return result;
}

export async function removeFavorites(id: string | number) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/favorites/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: KnowledgeItemProps = res?.data?.success;

    return result;
}

export async function updateFavorites(id: string | number) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/favorites/${id}`,
        method: 'POST'
    }).catch((e) => {console.error(e)});

    const result: KnowledgeItemProps = res?.data?.success;

    return result;
}

export async function getTypes() {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/types`,
        method: 'GET'
    }).catch((e) => { console.error(e) });
    
    const result: Array<KnowledgeItemProps> = res?.data?.success && res?.data?.result;

    return result;
}

export async function getArchiveList(sectionId: any, params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/sections/${sectionId}/archived/knowledges`,
        method: 'GET',
        params
    }).catch((e) => {console.error(e)});

    const result: Array<KnowledgeItemProps> = res?.data?.success &&
        res?.data?.result.map((item: any) => (transformItem(item)));

    return result;
}

export async function archiveKnowledge(contentId: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/archived/${contentId}`,
        method: 'PUT',
        data: {status_id: 0}
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function restoreArchiveKnowledge(contentId: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/archived/${contentId}`,
        method: 'PUT',
        data: {status_id: 1}
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function updateProjects(data: KnowledgeItemProps) {

    const {id, projects} = data;

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledges/${id}/projects`,
        method: 'PUT',
        data: {projects}
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function linkKnowledge(id: any, linkId: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledges/${id}/link/${linkId}`,
        method: 'POST',
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function unlinkKnowledge(id: any, linkId: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledges/${id}/link/${linkId}`,
        method: 'DELETE',
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export const KnowledgesApi = {
    getList, getRecord, getContent, search, update, getNewArticles,
    getFavoritesArticles, removeFavorites, updateFavorites,
    getPublishRecord, getListStatus, getTypes, getArchiveList, getVerificationArticles,
    archiveKnowledge, restoreArchiveKnowledge, linkKnowledge, unlinkKnowledge, updateProjects
}
