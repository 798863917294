import axios from "axios";
import { settings } from "@helpers";
import  {HistoryItemProps} from "@types";

    
const transformType = (type: 'u' | 'd' | 'i'): HistoryItemProps['type'] => {
    switch (type) {
        case 'u' :
            return 'update';
        case 'd':
            return 'delete';
        case 'i': 
            return 'create'
    }
}

export const transformItem = ({ type, create_user, projects_id, category_id, tags_id, ...values}: any): HistoryItemProps => ({
    ...values,
    type: transformType(type),
})


export async function getList(params?: any) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/history`,
        method: 'GET',
        params: params
    }).catch((e) => {console.error(e)});
    
    const result: Array<HistoryItemProps> = res?.data?.success && res?.data?.result.map((item: any) => transformItem(item));
    return result;
}

export async function getItem(id: any, name: HistoryItemProps['doc_type']) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/history/${id}/${name}`,
        method: 'GET',
    }).catch((e) => {console.error(e)});
    
    const result: Array<any> = res?.data?.success && res?.data?.result.map((item: any) => transformItem(item));

    return result
}

export const HistoryApi = {getList, getItem}