import React from 'react';
import { Affix as AntdAffix, AffixProps as AntdAffixProps } from 'antd';
import clsx from 'clsx';
import './Affix.less'


export interface AffixProps {
    offsetTop: AntdAffixProps['offsetTop']
    target: AntdAffixProps['target']
    children?: AntdAffixProps['children']
    className?: AntdAffixProps['className']
}

const prefixCls = "cmp-affix"

export const Affix: React.FC<AffixProps> = (props: AffixProps) => {
    const { offsetTop, target, className, children} = props;

    return (
        <AntdAffix
            className={clsx(prefixCls, className)}
            offsetTop={offsetTop}
            target={target}
        >
            {children}
        </AntdAffix>
    )
}