import React, { useState } from 'react';
import { Form, Input, Button, FormItem, Space, TypographyTitle } from '@components';
import { SignInRestoreSuccess } from "./SignInRestoreSuccess";
import { useTranslator } from "@helpers";
import {UsersApi} from "@api";
import clsx from 'clsx';

import "./SignInRestoreForm.less";

const defaultInitialValues = {
    email: ""
}

const prefixCls = "sign-in-restore-form";

export interface SignInRestoreFormProps {
    onForgotPassword: () => void
}

export const SignInRestoreForm: React.FC<SignInRestoreFormProps> = (props: SignInRestoreFormProps) => {
    const { onForgotPassword } = props;

    const { getLocalisation } = useTranslator("sign-in")
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);


    const handleFinish = async ({email}: any) => {
        setLoading(true);
        const result = await UsersApi.sendRestore(email);

        if (result) {
            setSuccess(true)
        }

        setLoading(false);
    }

    return (
        <>
            {!success && <Space className={clsx(`${prefixCls}`)} direction="vertical" size="large">
                <TypographyTitle>{getLocalisation('forgot_password')}</TypographyTitle>
                <Form
                    layout="vertical"
                    autoComplete="off"
                    name="page-sign-in"
                    initialValues={{ ...defaultInitialValues }}
                    className={clsx(`${prefixCls}-form`)}
                    onFinish={handleFinish}
                >

                    <FormItem
                        label={getLocalisation('email')}
                        name="email"
                        rules={[
                            { required: true, message: getLocalisation('please_set_email') },
                            { type: 'email', message: getLocalisation('enter_correct_email') }
                        ]}
                    >
                        <Input placeholder={getLocalisation('your_email')} />
                    </FormItem>

                    <Space>

                        <FormItem>
                            <Button
                                block
                                loading={loading}
                                type="primary"
                                htmlType="submit"
                            >{getLocalisation('send')}</Button>
                        </FormItem>

                        <FormItem>
                            <Button
                                block
                                type='secondary'
                                onClick={onForgotPassword}
                            >{getLocalisation('return')}</Button>
                        </FormItem>

                    </Space>

                </Form>
            </Space>}

            {success && <SignInRestoreSuccess onBack={onForgotPassword}/>}
        </>

    )
};
