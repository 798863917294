import axios from "axios";
import {settings} from "@helpers";
import {ControlsProps, ControlItemProps} from "@types";

export const transformItem = ({permissions, ...values}: any) => ({
    ...values,
    permissions: (permissions || []).map(({permission_id, permission_name, is_system}: any) => ({
        id: permission_id,
        name: permission_name,
        is_system: !!is_system
    }))
})

export async function update(data: ControlItemProps) {
    const {id} = data;

    const res: any = await axios.request({
        url: id ?
            `${settings.API_PREFIX}/system/controls/${id}` :
            `${settings.API_PREFIX}/system/controls`,
        method: id ? 'PUT' : 'POST',
        data
    }).catch((e) => {console.error(e)});

    const result: ControlItemProps = res?.data?.result[0];

    return result;
}

export async function remove(id: string | number) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/controls/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}
export async function getRecord(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/controls/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: ControlItemProps = res?.data?.success && transformItem(res?.data?.result[0]);

    return result;
}

export async function getList(params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/controls`,
        method: 'GET',
        params: {params}
    }).catch((e) => {console.error(e)});

    const result: Array<ControlItemProps> = res?.data?.result.map((item: any) => transformItem(item));

    return result;
}

export async function get() {

    const result: any = await axios.request({
        url: `${settings.API_PREFIX}/users/controls`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const data: ControlsProps = {};

    result?.data?.result[0].controls?.forEach((value: string) => {
        data[value] = true;
    });

    return data;
}

export const ControlsApi = { get, update, remove, getRecord, getList };
