import axios from 'axios'
import {settings} from "@helpers";
import {SectionItemProps} from "@types";

const transformItem = ({descr, projects, ...values}: any) => ({
    ...values,
    description: descr,
    projects: (projects || []).map(({project_id, project_name}: any) => ({
        label: project_name,
        value: project_id
    }))
})

export async function getAllList() {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/sections`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const map: any = {};

    const result: Array<SectionItemProps> = res?.data?.success && res?.data?.result
        .filter(({id }: any) => {
            if (map[id]) {
                return false
            }

            return  map[id] = true

        })
        .map((item: any) => transformItem(item));

    return result;
}

export async function getList(params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/sections`,
        method: 'GET',
        params: {params}
    }).catch((e) => {console.error(e)});

    const result: Array<SectionItemProps> = res?.data?.success && res?.data?.result
        .map((item: any) => transformItem(item));

    return result;
}


export async function update(data: SectionItemProps) {
    const {id} = data;

    const res: any = await axios.request({
        url: id ?
            `${settings.API_PREFIX}/knowledge/sections/${id}` :
            `${settings.API_PREFIX}/knowledge/sections`,
        method: id ? 'PUT' : 'POST',
        data
    }).catch((e) => {console.error(e)});

    const result: SectionItemProps = res?.data?.success && transformItem(res?.data?.result[0]);

    return result;
}

export async function remove(id: string | number) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/sections/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function getRecord(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/sections/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: SectionItemProps = res?.data?.success && res?.data?.result[0] && transformItem(res?.data?.result[0]);

    return result;
}


export const SectionsApi = { getAllList, getList, update, remove, getRecord }
