import React from "react";
import { ButtonText, Drawer, DrawerProps, Space } from "@components";
import { getRoute, useTranslator } from "@helpers";
import { HelpIcon, SingOutIcon, UserIcon } from "@assets/icons";
import { useNavigate } from "react-router-dom";

import "./MainAvatarMenu.less";

export interface MainAvatarMenuProps {
    onClose?: DrawerProps['onClose'];
    visible?: DrawerProps['visible'];
    onRemoveSession?: () => void
}

const prefixCls = "page-main-avatar-menu";

export const MainAvatarMenu: React.FC<MainAvatarMenuProps> = (props: MainAvatarMenuProps) => {
    const { onClose, visible, onRemoveSession } = props
    const navigate = useNavigate();
    const { getLocalisation } = useTranslator("main");

    const handleClickProfile = () => {
        navigate(getRoute('profile'))
        onClose?.()
    }

    const handleClickHelp = () => {
        navigate(getRoute('help'))
        onClose?.()
    }

    return (
        <Drawer
            placement="right"
            onClose={onClose}
            visible={visible}
            size="small"
        >
            <Space className={prefixCls} size='large' direction="vertical">
                <ButtonText onClick={handleClickProfile} size="large" beforeIcon={<UserIcon />}>{getLocalisation("profile") }</ButtonText>
                <ButtonText onClick={handleClickHelp} size="large" beforeIcon={<HelpIcon />}>{getLocalisation("help")}</ButtonText>
                <ButtonText onClick={onRemoveSession} size="large" beforeIcon={<SingOutIcon />}>{getLocalisation("exit")}</ButtonText>
            </Space>
        </Drawer>

    );
};
