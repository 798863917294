import { FC } from 'react';
import {Button, Result, TypographyText} from '@components';
import {useNavigate} from "react-router-dom";
import {getRoute, useTranslator} from "@helpers";
import {Plug404Icon} from '@assets/icons';

import './Page404.less'
import clsx from 'clsx';


const prefixCls = "page-404"

interface Page404Props {
    onClick: () => void
}


export const Page404: FC<Page404Props> = ({ onClick }: Page404Props) => {
    const navigate = useNavigate();

    const { getLocalisation } = useTranslator("page404")

    const handleClick = () => {
        onClick ? onClick() : navigate(getRoute('home'))
    }

    return (
        <Result
            title={<span className={clsx(`${prefixCls}-title`)}><Plug404Icon /></span>}
            subTitle={
                <TypographyText type='secondary'>
                    {getLocalisation('subTitle')}
                </TypographyText>
            }
            extra={<Button type="primary" onClick={handleClick}>{getLocalisation('backHome')}</Button>}
        />
    );
};