import React, { useContext, useState, useEffect, useRef } from "react";
import {Drawer, List, Loader, Space, Avatar, InputSearch} from '@components';
import { ProjectItemProps } from '@types';
import { ProjectsContext } from '@context';
import { projectShortName, useTranslator } from '@helpers';
import {ProjectsApi} from "@api";

import './MainDrawerProjects.less';
import clsx from "clsx";

export type MainDrawerProjectItemProps = ProjectItemProps & {}

export interface MainDrawerProjectsProps {
    visible?: boolean
    currentProject?: MainDrawerProjectItemProps
    onClick?: (project: MainDrawerProjectItemProps) => void
    onClose?: () => void
}

const prefixCls = "page-main-drawer-projects"

export const MainDrawerProjects: React.FC<MainDrawerProjectsProps> = (props: MainDrawerProjectsProps) => {
    
    const timerRef = useRef<ReturnType<typeof setTimeout>>();
    const { visible, onClick, onClose } = props;
    const { currentProject } = useContext(ProjectsContext);
    const { getLocalisation } = useTranslator("main")
    
    const [projects, setProjects] = useState<ProjectItemProps[]>();
    const [loading, setLoading] = useState<boolean>();
    const [search, setSearch] = useState<string>();

    const handleChange = (key: any) => {
        const project: any = projects?.find(({value}: any) => value === key);
        onClick && onClick(project);
    }

    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }

        timerRef.current = setTimeout(() => {
            setSearch(e.target.value)
        }, 300)
    }

    useEffect(() => {

        const fetchInit = async () => {
            setLoading(true);
            const projects = await ProjectsApi.getList({search});

            setProjects(projects);
            setLoading(false);
        }

        if (visible) {
            fetchInit().catch(console.error)
        }

        if (!visible) {
            setSearch(undefined)
        }

    }, [visible, search])

    return (
        <Drawer
            onClose={onClose}
            className={clsx(prefixCls)}
            placement="left"
            size={'small'}
            visible={visible}
        >
            <Space direction="vertical">
                <InputSearch
                    onChange={handleChangeSearch}
                    placeholder={getLocalisation("search")}
                    size="small"
                />
                <Loader
                    size="middle"
                    spinning={loading}
                >
                    {!loading && <List
                        items={projects?.map(({ value, logo, label, description }: ProjectItemProps) => ({
                            value, description,
                            label: <Space>
                                <Avatar
                                    size={32}
                                    src={logo}
                                >{projectShortName({ value, label, description })}</Avatar>
                                {label || "N/A"}
                            </Space>
                        }))}
                        selectKey={currentProject?.value}
                        onChange={handleChange}
                    />}
                </Loader>
            </Space>
        </Drawer>
    );
}
