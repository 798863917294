import React, {useRef, useState} from "react";
import {useBreakpoint, useInfiniteScroll} from "@helpers";
import {EmptyRequest, LoaderProps, SelectProps, AffixLoader, Toolbar, ToolbarGroupByType} from "@components";
import {PageListCard} from "./PageListCard";
import clsx from "clsx";
import {PageListTable, PageListTableProps} from "./PageListTable";

import "./PageList.less";

export type PageListDataItemProps = any;

export interface PageListFieldsItemProps {
    label?: React.ReactNode;
    render?: (value: any, record: PageListDataItemProps) => React.ReactNode;
    dataIndex: string;
    colspan?: number;
}

export interface PageListProps {
    keyField?: string;
    data: Array<PageListDataItemProps>;
    loading?: LoaderProps["spinning"];
    fields: Array<PageListFieldsItemProps>;
    sortOptions?: SelectProps["options"];
    defaultSort?: SelectProps["defaultValue"];
    getTitleDelete?: (record: any) => React.ReactNode;
    getPrefix?: (record: PageListDataItemProps) => React.ReactNode;
    getIconPrefix?: (record: PageListDataItemProps) => React.ReactNode;
    getIconSuffix?: (record: PageListDataItemProps) => React.ReactNode;
    onChangeSearch?: (value: string) => void;
    onChangeSort?: (value: string) => void;
    onRemove?: (record: PageListDataItemProps) => void;
    onRestore?: (record: any) => void;
    onBlock?: (record: any) => void;
    onTableChange?: PageListTableProps["onTableChange"];
    tableSummary?: PageListTableProps["tableSummary"];
    onEdit?: (record: PageListDataItemProps) => void;
    onCheckEdit?: (record: PageListDataItemProps) => boolean;
    onCheckRemove?: (record: PageListDataItemProps) => boolean;
    onCheckRestore?: (record: PageListDataItemProps) => boolean;
    onCheckBlock?: (record: PageListDataItemProps) => boolean;
    defaultGroupByType?: ToolbarGroupByType
}

const prefixCls = "cmp-page-list";
const initPageSize = 6;

export const PageList = (props: PageListProps) => {

    const {
        data, fields, getPrefix, onEdit, onChangeSearch, sortOptions,
        onChangeSort, defaultSort, loading, onRemove, keyField = "id",
        onCheckEdit, onCheckRemove, getIconPrefix, getIconSuffix,
        getTitleDelete, onRestore, onBlock, onCheckRestore, onCheckBlock,
        defaultGroupByType = "card", onTableChange, tableSummary
    } = props;

    const triggerRef = useRef<any>();
    const { isMobile } = useBreakpoint();
    const [pageSize, setPageSize] = useState<number>(initPageSize);
    const [groupBy, setGroupBy] = useState<ToolbarGroupByType>(defaultGroupByType);

    const handleScroll = () => {
        setPageSize(prev => prev + initPageSize)
    }

    const handleClickGroupBy = () => {
        setGroupBy(groupBy === 'card' ? 'table' : 'card')
    }

    useInfiniteScroll({
        triggerRef,
        callback: pageSize <= data.length ? handleScroll : undefined
    });

    return (
        <div className={clsx(`${prefixCls}`)}>
            <Toolbar
                sortOptions={sortOptions}
                defaultSort={defaultSort}
                groupBy={groupBy}
                onChangeSearch={onChangeSearch}
                onChangeSort={onChangeSort}
                onChangeGroupBy={handleClickGroupBy}
            />

            <AffixLoader
                spinning={loading}
                offsetTop={200}
                target={() =>
                    document.querySelector(`.${prefixCls}-body`) as HTMLElement}
            />
            <div className={clsx(`${prefixCls}-body`)}>
                {(groupBy === 'card' || isMobile) && <div className={clsx(`${prefixCls}-wrapper-card`)}>
                    {data && !!data.length && data.slice(0, pageSize).map((record: any) => (
                        <PageListCard
                            key={record[keyField]}
                            getTitleDelete={getTitleDelete}
                            onEdit={onEdit}
                            onBlock={onBlock}
                            onRemove={onRemove}
                            onRestore={onRestore}
                            fields={fields}
                            record={record}
                            getPrefix={getPrefix}
                            getIconPrefix={getIconPrefix}
                            getIconSuffix={getIconSuffix}
                            onCheckEdit={onCheckEdit}
                            onCheckRemove={onCheckRemove}
                            onCheckRestore={onCheckRestore}
                            onCheckBlock={onCheckBlock}
                        />
                    ))}
                </div>}
                {groupBy === 'table' && !isMobile && data && !!data.length &&
                    <PageListTable
                        tableSummary={tableSummary}
                        onTableChange={onTableChange}
                        keyField={keyField}
                        fields={fields}
                        data={data.slice(0, pageSize)}
                        getTitleDelete={getTitleDelete}
                        onEdit={onEdit}
                        onBlock={onBlock}
                        onRemove={onRemove}
                        onRestore={onRestore}
                        getPrefix={getPrefix}
                        getIconPrefix={getIconPrefix}
                        getIconSuffix={getIconSuffix}
                        onCheckEdit={onCheckEdit}
                        onCheckRemove={onCheckRemove}
                        onCheckRestore={onCheckRestore}
                        onCheckBlock={onCheckBlock}
                    />
                }
            </div>
            <div ref={triggerRef} />
            {(!data || !data.length) && !loading && <EmptyRequest/>}
        </div>
    );
};
