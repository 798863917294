import './index.less';
import { lazy } from 'react';

export const Help = lazy(() => import('./Help/Help'));
export const Files = lazy(() => import('./Files/Files'));
export const Home = lazy(() => import('./Home/Home'));
export const Knowledge = lazy(() => import('./Knowledge/Knowledge'));
export const KnowledgeSections = lazy(() => import('./KnowledgeSections/KnowledgeSections'));
export const Users = lazy(() => import('./Users/Users'));
export const Projects = lazy(() => import('./system/Projects/Projects'));
export const Controls = lazy(() => import('./system/Controls/Controls'));
export const Procedures = lazy(() => import('./system/Procedures/Procedures'));
export const RoleTemplates = lazy(() => import('./system/RoleTemplates/RoleTemplates'));
export const ReportArticles = lazy(() => import('./report/Articles/Articles'));
export const Permissions = lazy(() => import('./system/Permissions/Permissions'));
export const Roles = lazy(() => import('./system/Roles/Roles'));
export const Profile = lazy(() => import('./Profile/Profile'));
export const History = lazy(() => import('./History/History'));

export {SignIn} from './SignIn/SignIn';

export {Main} from './Main/Main';
export type {MainProps} from './Main/Main';

export {Page404} from './Page404/Page404';
export {PageDenied} from './PageDenied/PageDenied';

export {PageRestore} from './PageRestore/PageRestore';
export {PageActiveMail} from './PageActiveMail/PageActiveMail';
export {KnowledgeItemVersions} from './Knowledge/KnowledgeItemVersions'
export {KnowledgeSectionForm} from './Knowledge/KnowledgeSectionForm';
export {KnowledgeView} from './KnowledgeView/KnowledgeView';
export {KnowledgeViewItem} from './KnowledgeView/KnowledgeViewItem';
export {KnowledgeViewDrawer} from './KnowledgeView/KnowledgeViewDrawer'
export {Search} from './Search/Search'
export {GlobalSearch} from './GlobalSearch/GlobalSearch'

