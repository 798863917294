import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    Modal, Button, Form, Loader, FormItem,
    Input, useForm, TextEditor,
    TagsList, Space, MultiSelect
} from "@components";
import { useBreakpoint, useTranslator } from "@helpers";
import { ProjectsContext } from "@context";

import clsx from "clsx";

import "./MainNewsForm.less";
import { NewsItemProps, ProjectItemProps, TypeItemProps } from "@types";
import { KnowledgesApi, NewsApi, ProjectsApi } from "@api";

const defaultValue: NewsItemProps = {
    id: null,
    content: "",
    title: "",
    type_id: [],
    projects: []
};

const prefixCls = "page-main-news-form";


export interface MainNewsFormProps {
    id?: any
    visible?: boolean
    setVisible?: (visible: any) => void
    onSubmit?: (values: any, metaData: any) => void
}

export const MainNewsForm = (props: MainNewsFormProps) => {
    const { id, visible, setVisible, onSubmit } = props;

    const [form] = useForm();
    const { isMobile } = useBreakpoint();
    const { currentProject } = useContext(ProjectsContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [record, setRecord] = useState<NewsItemProps>();
    const [types, setTypes] = useState<TypeItemProps[]>([]);
    const [projects, setProjects] = useState<ProjectItemProps[]>([]);
    const { isTablet } = useBreakpoint();

    const { getLocalisation } = useTranslator("main")

    const transformLabelType = useCallback((id: any) => {
        switch (id) {
            case 1: return getLocalisation('information')
            case 2: return getLocalisation('warning')
            case 3: return getLocalisation('promotion')
            case 4: return getLocalisation('important_message')
        }
    }, [getLocalisation])

    const transformType = useCallback(({ id, name }: any) => ({
        type: name,
        value: id,
        label: transformLabelType(id)
    }), [transformLabelType])

    const handleSubmit = () => {
        form.submit();
    }

    const handleFinish = (values: any) => {
        onSubmit && onSubmit({
            ...values,
            id: id !== "none" && id
        }, { setSubmitting });
    }

    const handleCancel = () => {
        setVisible && setVisible(false);
    }

    useEffect(() => {
        const fetchInit = async () => {

            setLoading(true);

            const types = await KnowledgesApi.getTypes();
            const modifyTypes = types ? types.map(item => transformType(item)) : [];

            const result = id !== "none" && (await NewsApi.getRecord(id))
            const record: NewsItemProps = { ...defaultValue, ...(result || {}) };

            const projects = await ProjectsApi.getList()

            setTypes(modifyTypes || []);
            setRecord(record);
            setProjects(projects || []);

            setLoading(false);
        };

        form.resetFields();

        if (id && visible) {
            fetchInit().catch(console.error);
        }
    }, [id, visible, form, transformType]);

    useEffect(() => {
        if (id !== 'none') {
            form.setFieldsValue({
                ...record, type_id: [record?.type_id], projects: record?.projects?.map((item: any) => item.id)
            })
        }
        else { form.setFieldsValue({ ...defaultValue, projects: [currentProject?.value], type_id: [1] }); }
    }, [id, record, form, currentProject]);

    return (
        <>
            <Modal
                className={clsx(prefixCls)}
                title={id !== 'none' ? getLocalisation('edit_news') : getLocalisation('creating_news')}
                visible={visible}
                width={isTablet ? "80vw" : '950px'}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleSubmit}
                        loading={submitting}
                    >{id !== 'none' ? getLocalisation('edit') : getLocalisation('build')}</Button>,
                    <Button
                        key="cancel"
                        type="secondary"
                        onClick={handleCancel}
                        disabled={submitting}
                    >{getLocalisation('close')}</Button>
                ]}
            >
                <Loader spinning={loading} size="middle">
                    {!loading && (
                        <Form
                            form={form}
                            layout="vertical"
                            autoComplete="off"
                            onFinish={handleFinish}
                            initialValues={{ ...defaultValue }}
                        >
                            <Space
                                className={clsx(`${prefixCls}-row`, {
                                    [`${prefixCls}-row-mobile`]: isMobile
                                })}
                                size="large"
                                align="start"
                                direction={isMobile ? "vertical" : "horizontal"}
                            >
                                <Space direction="vertical" size="middle">
                                    <FormItem
                                        label={getLocalisation('header')}
                                        name="title"
                                        rules={[{ required: true, message: getLocalisation('please_enter_title') }]}
                                    >
                                        <Input placeholder={getLocalisation('write_header')} />
                                    </FormItem>

                                    <FormItem
                                        label={getLocalisation('choose_news_type')}
                                        name="type_id"
                                        rules={[{ required: true, message: getLocalisation('pleace_choose_news_type') }]}
                                    >
                                        <TagsList
                                            inputType="radio"
                                            options={types}
                                        />
                                    </FormItem>
                                    <FormItem
                                        label={getLocalisation('choose_projects_news')}
                                        name="projects"
                                        rules={[{ required: true, message: getLocalisation('pleace_choose_projects_news') }]}
                                    >
                                        <MultiSelect
                                            options={
                                                projects?.map(({ value, label }: ProjectItemProps) =>
                                                    ({ value: value, label: label }))
                                            }
                                        />
                                    </FormItem>
                                </Space>
                                <FormItem
                                    label={getLocalisation('content')}
                                    name="content"
                                    rules={[{ required: true, message: getLocalisation('pleace_enter_content') }]}
                                >
                                    <TextEditor />
                                </FormItem>
                            </Space>
                        </Form>
                    )}
                </Loader>
            </Modal>
        </>
    );
};