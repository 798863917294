import axios from 'axios'
import { settings } from "@helpers";
import {KeywordsProps} from "@types";

const transformKeywords = ({ txt, id, ...values }: any): KeywordsProps => ({
    value: id,
    label: txt,
    type: 'default',
    ...values
})


export async function getAllKeywords(params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/keywords`,
        method: 'GET',
        params
    }).catch((e) => {console.error(e)});

    const result: any = res?.data?.success &&
        res?.data?.result.map((item: any) => transformKeywords(item));

    return result;
}

export async function getKeywords(id: KeywordsProps["article_id"]) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/keywords/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: any = res?.data?.success &&
        res?.data?.result.map((item: any) => transformKeywords(item));

    return result;
}

export async function removeKeywords(id: KeywordsProps["value"]) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/keywords/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function createKeywords(id: KeywordsProps["article_id"], data: {label: KeywordsProps["label"]}) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/keywords/${id}`,
        method: 'POST',
        data: data
    }).catch((e) => {console.error(e)});


    const result: boolean = res?.data?.success;

    return result;
}

export async function update(id: KeywordsProps["article_id"], data: KeywordsProps["value"][]) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/keywords/${id}`,
        method: 'PUT',
        data: {
            article_id: id, keywords_id: data
        }
    }).catch((e) => {console.error(e)});


    const result: boolean = res?.data?.success;
    return result;
}

export const KeywordsApi = { getAllKeywords, getKeywords, removeKeywords, createKeywords, update }