import { notification as notificationAntd } from 'antd';
import { IconType } from "antd/lib/notification";
import { CloseIcon } from '@assets/icons';
import clsx from "clsx";

import './Notification.less'

const prefixCls = "cmp-notification";

const notification = (type: IconType, message?: string, description?: string) => {
    notificationAntd.open({
        className: clsx(`${prefixCls}-${type}`),
        prefixCls: clsx(`${prefixCls}`),
        closeIcon: <CloseIcon />,
        message, description
    });
}

export const notificationSuccess = ({ message, description }: Record<string, string>) => notification('success', message, description);
export const notificationInfo = ({ message, description }: Record<string, string>) => notification('info', message, description);
export const notificationWarning = ({ message, description }: Record<string, string>) => notification('warning', message, description);
export const notificationError = ({ message, description }: Record<string, string>) => notification('error', message, description);
