import React, { useState, useRef } from "react";
import { Popover as AntdPopover } from "antd";
import { MenuItemProps, SectionItemProps } from "@types";
import { useTranslator } from "@helpers";
import { Tooltip, ButtonText } from "@components";
import clsx from 'clsx';

import "./ButtonMenu.less";

export interface ButtonMenuProps {
    items?: MenuItemProps[] | SectionItemProps[];
    path?: MenuItemProps['path'],
    label?: MenuItemProps['name'],
    Icon?: MenuItemProps['Icon']
    selected?: Array<string>;
    onCreate?: () => void;
    onClick?: (item?: any, metaData?: any) => void;
    onVisibleChange?: (visible: boolean, metaData: any) => void;
}


const prefixCls = "cmp-button-menu";

export const ButtonMenu: React.FC<ButtonMenuProps> = (props: ButtonMenuProps) => {

    const { items, path, label, Icon, onCreate, onClick, selected, onVisibleChange } = props;
    const { getLocalisation } = useTranslator("components");

    const elItem = useRef<any>(null);

    const [visible, setVisible] = useState<boolean>();
    const [visibleTooltip, setVisibleTooltip] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>();

    const handleVisibleChange = (visible: boolean) => {
        setVisible(visible);
        onVisibleChange && onVisibleChange(visible, {setLoading})
    };

    const handleClickItem = (item: any) => {
        setVisible(false);
        setVisibleTooltip(false)
        onClick && onClick(item, {setLoading})
    }

    const handleClickAdd = () => {
        setVisible(false);
        setVisibleTooltip(false)
        onCreate && onCreate();
    }

    const handleClickBody = (e: React.MouseEvent) => {
        e.stopPropagation();
        setVisible(false);
    }

    const getList = () => {
        return (
            <div
                className={clsx(`${prefixCls}-popover-body`)}
                onClick={handleClickBody}
            >
                {onCreate &&
                    <ul className={clsx(`${prefixCls}-popover-list ${prefixCls}-popover-list-add`)}>
                        <li
                            className={clsx(`${prefixCls}-popover-item-add`)}
                            onClick={handleClickAdd}
                        >
                            {getLocalisation('add')}
                        </li>
                    </ul>
                }
                <ul className={clsx(`${prefixCls}-popover-list`)}>
                    {items &&
                        items.map((item: any) => (
                            <li
                                key={item.id}
                                className={clsx(`${prefixCls}-popover-item`)}
                                onClick={() => handleClickItem(item)}
                            >
                                {item.name}
                            </li>
                        ))}
                </ul>
            </div>
        );
    };

    return (
        <>
            {!!items && <AntdPopover
                trigger="click"
                visible={loading ? false : visible}
                placement="rightTop"
                prefixCls={clsx(`${prefixCls}-popover`)}
                content={getList()}
                onVisibleChange={handleVisibleChange}
            >
                <div>
                    <li
                        ref={elItem}
                        className={clsx(`${prefixCls}-item`, {
                            [`${prefixCls}-item-selected`]: path && selected && selected.indexOf(path) > -1,
                        })}
                    >
                        <Tooltip visible={visible || !visibleTooltip ? false : undefined} title={label} placement="right">
                            <div
                                className={`${prefixCls}-inner-tooltip`}
                                onMouseEnter={() => !visible && setVisibleTooltip(true)}
                                onMouseLeave={() => setVisibleTooltip(false)}
                            >
                                <ButtonText
                                    loading={loading}
                                    afterIcon={<Icon />}
                                    className={clsx(`${prefixCls}-button`)}
                                />
                            </div>
                        </Tooltip>
                    </li>
                </div>
            </AntdPopover>}
            {!items && <Tooltip
                title={label}
                placement="right"
            >
                <li
                    onClick={onClick!}
                    className={clsx(`${prefixCls}-item`, {
                        [`${prefixCls}-item-selected`]:
                        path && selected && selected.indexOf(path) > -1,
                    })}
                >
                    <ButtonText
                        loading={loading}
                        afterIcon={<Icon />}
                        className={clsx(`${prefixCls}-button`)}
                    />
                </li>
            </Tooltip>}
        </>
    )
}