import React from "react";
import clsx from "clsx"
import "./Icon.less"

const prefixCls = "icon";

export interface IconProps {
    IconSvg?: any
    onClick?: any
    className?: any
}

export const Icon: React.FC<IconProps> = ({IconSvg, onClick, className, ...props}: IconProps) => {

    return <i
        {...props}
        className={clsx(`${prefixCls}`, className)}
        onClick={onClick}
    >
        <IconSvg width="1em" height="1em" fill="currentColor"/>
    </i>
}