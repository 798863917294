import React from "react";
import {Avatar, ButtonText, Space, TypographyText} from '@components';
import {MainDrawerProjectItemProps} from './MainDrawerProjects';
import {projectShortName, useBreakpoint, useTranslator} from "@helpers";
import clsx from "clsx";
import './MainButtonProjects.less';

export interface MainButtonProjectsProps {
    currentProject?: MainDrawerProjectItemProps
    onClick?: () => void
}

const prefixCls = "page-main-button-projects"

export const MainButtonProjects : React.FC<MainButtonProjectsProps> = ({onClick, currentProject}: MainButtonProjectsProps) => {
    const { getLocalisation } = useTranslator("main")
    const { isMobile } = useBreakpoint();

    return (
        <Space className={clsx(prefixCls, {
            [`${prefixCls}-mobile`]: isMobile
        })}>
            <ButtonText
                type="primary"
                onClick={onClick}
                size="large"
            >
                {currentProject ? <Space align="center">
                    <Avatar
                        size={44}
                        src={currentProject.logo}
                    >{projectShortName(currentProject)}</Avatar>
                    {!isMobile && (currentProject.label || "N/A")}
                </Space> : <TypographyText type="secondary">{getLocalisation('selected')}</TypographyText>}
            </ButtonText>
        </Space>
    );
}
