import axios from 'axios'
import { settings } from "@helpers";
import {KnowledgeVersionChangedProps, KnowledgeVersionProps} from "@types";
import moment from "moment";

const transformItem = ({
           tags, version, date_from, date_to, descr, ...value
}: any) => ({
    content: descr,
    name: version,
    start: date_from && moment(date_from, "YYYY-MM-DD"),
    end: date_to && moment(date_to, "YYYY-MM-DD"),
    tags: tags && tags.map(({tag_id, tag_name, type_name}: any) => ({
        label: tag_name,
        value: tag_id,
        type: type_name
    })),
    ...value,
})

const transformChanged = ({ descr, ...value}: any) => ({
    content: descr,
    ...value
})

export async function getList(sectionId: any, knowledgeId: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/sections/${sectionId}/knowledges/${knowledgeId}/versions`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: Array<KnowledgeVersionProps> = res?.data?.success &&
        res?.data?.result.map((item: any) => transformItem(item));

    return result;
}


export async function getRecord(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/versions/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: KnowledgeVersionProps = res?.data?.result;

    return result;
}


export async function create(values: KnowledgeVersionProps) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/versions`,
        method: 'POST',
        data: values
    }).catch((e) => {console.error(e)});

    if (!res) return null;

    const result: KnowledgeVersionProps = res.data.result;

    return result;
}


export async function sendVersion(contentId: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/ready/${contentId}`,
        method: 'PUT'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function rejectVersion(contentId: any, values?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/reject/${contentId}`,
        method: 'PUT',
        data: values
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function publishVersion(contentId: any, values?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/publish/${contentId}`,
        method: 'PUT',
        data: values
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function getChanged(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/changed/${id}`,
        method: 'GET'
    }).catch((e) => { console.error(e) });
    
    const result: KnowledgeVersionChangedProps = transformChanged(res?.data?.result[0] || {});

    return result;
}

export const VersionsApi = {
    create, getList, getRecord, sendVersion,
    rejectVersion, publishVersion, getChanged
}