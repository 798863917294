import axios from 'axios'
import {settings} from "@helpers";
import {SessionProps, SessionValuesProps} from "@types";

export async function create(values: SessionValuesProps) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/sessions`,
        method: 'POST',
        data: values
    }).catch((e) => {console.error(e)});

    if (!res) return null;

    const result: SessionProps = res.data.result;

    return result;
}

export async function update(sessionHash?: string) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/sessions`,
        method: 'PUT',
        data: {
            session_hash: sessionHash
        }
    }).catch((e) => {console.error(e)});

    const result: SessionProps = res?.data?.success && res?.data?.result[0];

    return result;
}

export async function remove() {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/users/sessions`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    if (!res) return null;

    return res.data.result;
}


export const SessionsApi = {create, update, remove}
