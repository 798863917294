import React, {ReactNode, useState} from "react";
import {CloseIcon, SliderFormatIcon, TxtFormatIcon, ZipFormatIcon} from "@icons";
import {Button, Loader, Tooltip, Tag} from "@components";
import {RcFile} from "antd/lib/upload";
import {useTranslator} from "@helpers";
import { SizeProp } from "@types";
import clsx from "clsx";

import "./UploadItem.less";

const prefixCls = "cmp-upload-item";

const FileType: Record<string, ReactNode> = {
    'application/x-zip-compressed': <ZipFormatIcon/>,
    'image/svg+xml': <SliderFormatIcon/>,
    'image/jpeg': <SliderFormatIcon/>,
    'image/png': <SliderFormatIcon/>,
    'image/gif': <SliderFormatIcon/>,
    'text/plain': <TxtFormatIcon/>,
    'default': <SliderFormatIcon/>
}

export interface UploadItemProps {
    record?: any;
    onRemove?: (id: any) => void;
    size?: SizeProp;
    className?: string
}

const ImagePreview = ({file}: any) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [imgSrc, setImgSrc] = useState<string>();

    const getBase64 = (file: RcFile): Promise<string> => (
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImgSrc(reader.result as string)
                setLoaded(true)
                resolve(reader.result as string)
            };
            reader.onerror = (error) => reject(error);
        })
    )

    getBase64(file);

    return loaded ? <img src={imgSrc} alt={file.name}/> : <Loader/>
}

export const UploadItem: React.FC<UploadItemProps> = (props: UploadItemProps) => {
    const {record, onRemove, size = "large", className} = props;
    const {getLocalisation} = useTranslator("components");

    if (!record) {
        return null
    }

    const {type, name, url, originFileObj, id, tooltip, archived} = record;

    const getHref = () => {
        if (originFileObj) {
            URL.revokeObjectURL(originFileObj);

            return URL.createObjectURL(originFileObj);
        }

        return url
    }

    const getContent = () => {

        if (!type.includes('image')) {
            return FileType[type] || FileType['default'];
        }

        if (originFileObj) {
            return <ImagePreview file={originFileObj}/>;
        }

        return <img src={getHref()} alt={name}/>
    }

    return (
        <div
            className={clsx(`${prefixCls}`, `${prefixCls}-${size}`, className)}
        >
            <Tooltip title={tooltip}>

                {archived && <Tag
                    size="small"
                    type="error"
                    className={`${prefixCls}-tag`}
                    showPopconfirm={false}
                >{getLocalisation("archived")}</Tag>}

                <a
                    href={getHref()}
                    target='_blank'
                    rel="noreferrer"
                    onClick={(e: any) => e.stopPropagation()}
                    className={`${prefixCls}-info`}
                >
                    <div className={`${prefixCls}-icon`}>{getContent()}</div>
                    {tooltip &&
                        !type.includes('image') &&
                        <span
                            className={clsx(`${prefixCls}-name`, {
                                [`${prefixCls}-name-hide`]: size === 'small'
                            })}
                        >
                            {tooltip}
                        </span>
                    }
                </a>
            </Tooltip>

            {onRemove && <Button
                className={`${prefixCls}-button-remove`}
                beforeIcon={<CloseIcon/>}
                onClick={() => onRemove(id)}
                type="dropdown"
                size="small"
            />}
        </div>
    )
};
