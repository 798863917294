import React, {useContext, useEffect, useMemo, useState} from "react";
import {MenuItemProps} from "@types";
import {menuReportItems, useTranslator} from "@helpers";
import {ControlsContext, ProjectsContext} from "@context";
import {ButtonMenu} from "@components";
import {useLocation} from "react-router-dom";
import {SquareCheckIcon} from "@icons";

import "./MainReport.less";


export interface MainReportProps {
    onClick?: (item: MenuItemProps) => void;
}

export const MainReport: React.FC<MainReportProps> = (props: MainReportProps) => {
    const {onClick} = props;
    const location = useLocation();
    const { currentProject } = useContext(ProjectsContext)

    const { getLocalisation } = useTranslator("main")

    const [selected, setSelected] = useState<Array<string>>();
    const {controls} = useContext(ControlsContext);

    const items = useMemo(() =>
        menuReportItems
            .filter(({ control }: MenuItemProps) => !control || !!controls[control])
            .map(({ path, ...arg }: MenuItemProps) => (
                {
                    path: path?.replace(":project", currentProject?.value),
                    ...arg
                }))
    ,[controls, currentProject?.value]);

    useEffect(() => {
        const pathname = location.pathname;

        if (pathname && items) {
            const menuItem = items.find(
                ({path}: MenuItemProps) => path && pathname.indexOf(path) === 0
            );
            setSelected(menuItem && menuItem.path ? [menuItem.path] : []);
        }
    }, [location?.pathname, items]);


    if (!items || !items.length) {
        return null;
    }

    return (
        <ButtonMenu
            label={getLocalisation('reports')}
            path={location?.pathname}
            Icon={SquareCheckIcon}
            items={items}
            onClick={onClick}
            selected={selected}
        />
    );
};
