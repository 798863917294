import axios from 'axios'
import {settings} from "@helpers";
import {RoleItemProps} from "@types";

export async function getList(params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/roles/templates`,
        method: 'GET',
        params: {params}
    }).catch((e) => {console.error(e)});

    const result: Array<RoleItemProps> = res?.data?.result;

    return result;
}


export async function update(data: RoleItemProps) {
    const {id} = data;

    const res: any = await axios.request({
        url: id ?
            `${settings.API_PREFIX}/system/roles/templates/${id}` :
            `${settings.API_PREFIX}/system/roles/templates`,
        method: id ? 'PUT' : 'POST',
        data
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function remove(id: string | number) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/roles/templates/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.result;

    return result;
}

export async function getRecord(id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/system/roles/templates/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: RoleItemProps = res?.data?.result[0];

    return result;
}


export const RoleTemplatesApi = { getList, update, remove, getRecord }
