import React, {useEffect, useState} from 'react';
import {
    Modal, Button, Form, Loader, FormItem, FieldDisplay,
    Input, useForm, Space, Upload, UploadItem, Textarea, TagsList, TagsListOptionProps
} from '@components';
import {useTranslator, fileSize, useBreakpoint} from "@helpers";
import {FileItemProps} from "@types";
import {FilesApi, TagsApi} from "@api";
import clsx from "clsx";

import './FilesModal.less'

const defaultValue: FileItemProps = {
    id: null,
    name: "",
    description: "",
    extension: "",
    size: "",
    tags: []
}

const prefixCls = "page-files-modal";

export const FilesModal = (props: any) => {
    const [form] = useForm();

    const { getLocalisation } = useTranslator("files")
    const { isMobile } = useBreakpoint();

    const { visible, setVisible, id, onSubmit } = props;

    const [optionsTags, setOptionsTags] = useState<TagsListOptionProps[]>([]);
    const [selectFile, setSelectFile] = useState<any>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [record, setRecord] = useState<FileItemProps>();

    const getFileName = (name: string) => {
        let fileNameArr = name.split('.');
        return fileNameArr.slice(0, fileNameArr.length - 1).join('.');
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const handleSave = () => {
        form.submit();
    }

    const handleValuesChange = ({file}: any) => {

        setSelectFile(file);

        if (file && id === "none") {
            form.setFieldsValue({
                name: getFileName(file.name),
            });
        }

        form.setFieldsValue({
            extension: (file && file.name.split('.').pop()) || "",
            size: (file && fileSize(file.size)) || ""
        });
    }

    const handleRemoveTag = async ({value}: any) => {
        console.log(value)
        const result = await TagsApi.removeFileTag(value);

        if (result) {
            const tags = await TagsApi.getFileTags();

            setOptionsTags(tags);
        }

    }

    const handleSubmitTag = async (values: any, { setVisible, setLoading }: any) => {
        setLoading(true);
        const result = await TagsApi.createFileTag({ ...values});

        if (!!result) {
            const tags = await TagsApi.getFileTags();

            setOptionsTags(tags);

            setVisible(() => {
                setLoading(false);
                return false
            });
        }
        else {
            setLoading(false);
        }
    }

    const handleRemoveFile = () => {
        setSelectFile(undefined)
    }

    useEffect(() => {

        const fetchInit = async () => {
            setLoading(true);

            const result = id !== "none" && await FilesApi.getRecord(id);
            const record: FileItemProps = {...defaultValue, ...(result || {})};
            const tags = await TagsApi.getFileTags();

            setOptionsTags(tags);
            setLoading(false);
            setRecord({...record, ...{
                tags: record?.tags?.map(({value}: any) => value)
            }});

            if (id !== "none") {
                setSelectFile({...record, name: record.fileName});
            }
        }

        setSelectFile(undefined);
        setRecord(undefined);

        if (id && visible) {
            fetchInit().catch(console.error)
        }

    }, [id, visible, form])

    useEffect(() => {
        form.setFieldsValue({...record});
    }, [record, form]);

    return <Modal
        width="640px"
        title={id !== "none" ? getLocalisation('edit_file') : getLocalisation('create_file')}
        visible={visible}
        className={prefixCls}
        onCancel={handleCancel}
        footer={[
            <Button
                key="submit"
                type="primary"
                onClick={handleSave}
                loading={submitting}
            >{getLocalisation('save')}</Button>,
            <Button
                key="cancel"
                type="secondary"
                onClick={handleCancel}
                disabled={submitting}
            >{getLocalisation('close')}</Button>
        ]}
    >
        <Loader
            spinning={loading}
            size="middle"
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                className={clsx(`${prefixCls}-form`)}
                onFinish={(values: any) => onSubmit({ ...record, ...values, file: selectFile?.originFileObj}, { setSubmitting })}
            >
                <Space direction={isMobile ? 'vertical' : 'horizontal'} size="large">

                    {!selectFile && <Upload
                        onChange={handleValuesChange}
                    />}

                    {selectFile && <UploadItem
                        className={`${prefixCls}-select-file`}
                        record={selectFile}
                        onRemove={handleRemoveFile}
                    />}

                    {selectFile && <Space direction="vertical">

                        <FormItem
                            layout="horizontal"
                            label={getLocalisation('size')}
                            name="size"
                        ><FieldDisplay /></FormItem>

                        <FormItem
                            layout="horizontal"
                            label={getLocalisation('extension')}
                            name="extension"
                        ><FieldDisplay /></FormItem>

                        <FormItem
                            label={getLocalisation('name')}
                            name="name"
                            rules={[
                                { required: true, message: getLocalisation('please_set_name') },
                                { whitespace: true, message: getLocalisation('please_set_name') }
                            ]}
                        ><Input /></FormItem>

                        <FormItem
                            label={getLocalisation('description')}
                            name="description"
                        ><Textarea/></FormItem>

                        <FormItem
                            label={getLocalisation('select_tag')}
                            name="tags"
                        >
                            <TagsList
                                onRemove={handleRemoveTag}
                                onCreate={handleSubmitTag}
                                options={optionsTags}
                            />
                        </FormItem>

                    </Space>}

                </Space>
            </Form>
        </Loader>
    </Modal>
}