import React, {useState} from "react";
import { ArrowDownIcon, ArrowUpIcon, ConfigIcon } from "@icons";
import { ButtonText } from "../Button/Button";
import { Card, List, Space } from "@components";
import clsx from "clsx";

import "./TreeList.less";

export type TreeListItemValueType = any

export type TreeListItemProps = any

export interface TreeListProps {
    onClickConfig?: (value: TreeListItemValueType) => void
    activeKey?: TreeListItemValueType
    items: Array<TreeListItemProps>
    onClick?: (key: any) => void
    typeExpand?: 'select' | 'radio'
}

const prefixCls = "cmp-tree-list";

export const TreeList : React.FC<TreeListProps> = (props: TreeListProps) => {
    const { items, activeKey, onClick, onClickConfig, typeExpand = 'select' } = props;

    const [expandKeys, setExpandKeys] = useState<Array<TreeListItemValueType> | TreeListItemValueType>([]);

    const handleExpand = (key: string) => {
        if (typeExpand === 'select') {       
            if (expandKeys.indexOf(key) > -1) {
                expandKeys.splice(expandKeys.indexOf(key), 1);
            }
            else {
                expandKeys.push(key);
            }
            setExpandKeys([...expandKeys]);

        } else if (typeExpand === 'radio') {
            if (key === expandKeys) setExpandKeys(undefined)
            else setExpandKeys(key);
        }
    }

    const handleActive = (key: any) => {
        onClick && onClick(key);
    }

    const getItemCard = ({ label, value, type = "none", items }: TreeListItemProps) => (
        <Card
            key={value}
            title={label}
            type={type}
            beforeAction={onClickConfig &&
                <ButtonText
                    size="small"
                    onClick={() => onClickConfig(value)}
                    className={clsx(`${prefixCls}-config`)}
                    afterIcon={<ConfigIcon />}
                />}
            afterAction={<ButtonText
                    size="large"
                    onClick={() => handleExpand(value)}
                    afterIcon={expandKeys && expandKeys.indexOf(value) > -1
                        ? <ArrowUpIcon />
                        : <ArrowDownIcon />
                    }
            />}
        >
            {items && <div
                className={clsx(`${prefixCls}-body`, {
                    "expand": typeExpand === 'radio'
                        ? value === expandKeys
                        : expandKeys.indexOf(value) > -1
                })}
            >
                <List
                    onChange={handleActive}
                    selectKey={activeKey}
                    items={items}
                />
            </div>}
        </Card>
    )

    return (<Space direction='vertical' className={clsx(prefixCls)} size="middle">
        {items?.map((item: TreeListItemProps) => getItemCard(item))}
    </Space>);
}

