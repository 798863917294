import { SizeProp } from "@types"
import moment from "moment"

export const fileSize = (size: string) => {

    const fileSize = parseInt(size)
    const kb = fileSize / Math.pow(1024, 1)
    const mb = fileSize / Math.pow(1024, 2)
    const gb = fileSize / Math.pow(1024, 3)

    return mb >= 1024 ?
        `${gb.toFixed(2)} GB`:
        kb >= 1024 ? `${mb.toFixed(2)} MB`:
            `${Math.round(kb)} KB`
}

export const userShortName = (user: any) => {
    const {name, surname, login} = user

    if (name && surname) {
        return `${name[0].toUpperCase()}${surname[0].toUpperCase()}`
    }

    if (login) {
        return `${login[0].toUpperCase()}${login[1].toUpperCase()}`
    }

}

export const projectShortName = (project: any) => {
    const label = project.label || "NA";

    if (label.length === 1) {
        return `${label[0].toUpperCase()}`
    } else if (label.length >= 2) {
        return `${label[0].toUpperCase()}${String(label[1]).toUpperCase()}`
    }
}


export const getShortContent = (html: any, size: SizeProp | number = 'small') => {

    const getLength = (size: SizeProp) => {
        switch (size) {
            case "small":
                return 180;
            case "middle":
                return 300;
            case "large":
                return 450;
        }
    }

    const maxLength = typeof(size) === 'number' ? size : getLength(size);

    const text: string = html
        .replace(/<[^>]*>/g, " ")
        .replace(/&raquo;/g, "»")
        .replace(/&laquo;/g, "«")
        .replace(/&apos;/g, "\'")
        .replace(/&quot;/g, "\"")
        .replace(/&gt;/g, ">")
        .replace(/&lt;/g, "<")
        .replace(/&nbsp;/g, " ")
        .replace(/\n/g, ' ') || ""

    return text.length > maxLength ? `${text.substr(0, maxLength).trim()}...` : text
}

export const userFullName = (user: any) => {
    const { name, surname, login } = user;
    const parts = [name, surname]
        .filter((value: any) => !!value)
        .map((value: any, i: number) => i === 0 ? `${value} ` : `${value[0].toUpperCase()}.`)

    return parts.join("") || login
}

export const toDate = (date?: any, format?: string) => (
    moment.utc(date, format).local().format('L')
)

export const toDateTime = (date?: any, format?: string) => (
    moment.utc(date, format).local().format('LLL')
)

export const toFullDateTime = (date?: any, format?: string) => (
    moment.utc(date, format).local().format('llll')
)

export const toApiDateTime = (date?: any) => (
    moment(date).toISOString()
)

export const toApiDate = (date?: any) => {
    if (date) {
       return moment(date).format('L')
    } else {
        return null
    }
}