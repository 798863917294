import React, {useEffect, useMemo, useState} from "react";
import {
    Button, Form, FormItem, Input, InputProps, RadioGroup,
    Tag, Space, Popover, useForm, TagProps, ButtonText
} from "@components";
import { useBreakpoint, useTranslator } from "@helpers";
import {TagItemProps} from "@types";
import {PlusIcon} from "@icons";
import clsx from "clsx";

import "./TagsList.less";


const prefixCls = "cmp-tags-list";

export type TagsListOptionProps = any;

export interface TagsListProps {
    value?: Array<string>;
    options: Array<TagsListOptionProps>;
    onChange?: (value: any) => void;
    onCreate?: (value: any, metaData: any) => void;
    onRemove?: (value: TagsListOptionProps) => void;
    className?: string;
    simple?: boolean;
    placeholder?: InputProps["placeholder"];
    inputType?: 'select' | 'radio';
    showPopconfirm?: TagProps['showPopconfirm']
    size?: TagProps['size']
    maxCount?: number
    active?: boolean
}

const defaultValue: TagItemProps = {
    value: null,
    label: "",
    type: "default"
};

export const TagsList: React.FC<TagsListProps> = (props: TagsListProps) => {
    let {
        value = [], options = [], onChange, className, onCreate, onRemove, simple,
        placeholder, inputType = 'select', showPopconfirm, size, maxCount = 15, active
    } = props;

    const innerMaxCount = value.length > maxCount ? value.length : maxCount;

    const [form] = useForm();
    const { getLocalisation } = useTranslator("components");
    const { isTablet } = useBreakpoint();

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [shortList, setShortList] = useState<boolean>();

    const sortOptions = useMemo(() => (
        options
            .sort((a: TagsListOptionProps, b: TagsListOptionProps): any => (
                a.label.toLowerCase() < b.label.toLowerCase() ? 1 : -1
            ))
            .sort((a: TagsListOptionProps): any => a.type === 'default' && -1)
            .sort((a: TagsListOptionProps): any => a.type === "success" && -1)
            .sort((a: TagsListOptionProps): any => a.type === 'warning' && -1)
            .sort((a: TagsListOptionProps): any => a.type === 'error' && -1)
    // eslint-disable-next-line
    ), [options])

    const handleVisibleChange = (visible: boolean) => {
        setVisible(visible);
    };

    const handleClick = (newValue: any) => {
        if (inputType === 'select') {
            if (value.find((item: string) => item === newValue)) {
                value = value.filter((item: string) => newValue !== item);
            } else {
                value.push(newValue);
            }
            onChange && onChange([...value]);
        }
        if (inputType === 'radio') {
            onChange && onChange([newValue]);
        }
    };

    const handleFinish = (value: any) => {
        onCreate && onCreate(value, {setVisible, setLoading})
    }

    const handleClickCreate = () => {
        form.setFieldsValue(defaultValue)
        setVisible(true);
    }

    const handleShowAll = () => {
        setShortList(false)
    }

    const getOptionsRadio = () => [
        {
            label: <span
                className={clsx(`${prefixCls}-circle`, `${prefixCls}-circle-default`)}
            >{getLocalisation('normal')}</span>,
            value: 'default',
        },
        {
            label: <span
                className={clsx(`${prefixCls}-circle`, `${prefixCls}-circle-warning`)}
            >{ getLocalisation('warning') }</span>,
            value: 'warning',
        },
        {
            label: <span
                className={clsx(`${prefixCls}-circle`, `${prefixCls}-circle-success`)}
            >{getLocalisation('informing')}</span>,
            value: 'success',
        }
    ]

    const getContent = () => (
        <Form
            form={form}
            layout='vertical'
            onFinish={handleFinish}
            initialValues={{...defaultValue}}
            className={clsx(`${prefixCls}-form`)}
        >
            <FormItem
                name="label"
                rules={[{ required: true, message: getLocalisation('please_write_title')}]}
            >
                <Input placeholder={placeholder ? placeholder : getLocalisation('write_title') } />
            </FormItem>
            {
                !simple &&
                <FormItem
                    name="type"
                    rules={[{ required: true, message: getLocalisation('please_add_priority_tag') }]}
                >
                    <RadioGroup options={getOptionsRadio()} direction={isTablet ? 'vertical' : 'horizontal'} />
                </FormItem>
            }
            <Space>
                <Button
                    size="small"
                    type='primary'
                    htmlType="submit"
                    loading={loading}
                >{ getLocalisation('add') }</Button>
                <Button
                    size="small"
                    type='secondary'
                    onClick={() => setVisible(false)}
                >{ getLocalisation('close') }</Button>
            </Space>

        </Form>
    );

    useEffect(() => {
        if (options.length > innerMaxCount && shortList !== false) {
            setShortList(true)
        }
    }, [options, innerMaxCount, shortList])

    return (
        <div className={clsx(`${prefixCls}`)}>
            {sortOptions
                .slice(0, shortList ? innerMaxCount : sortOptions.length)
                .map(({ label, type, disabled, ...props }: TagItemProps) => (
                    <Tag
                        disabled={disabled}
                        size={size}
                        showPopconfirm={showPopconfirm}
                        onRemove={onRemove && (() => onRemove && onRemove({label, type, disabled, ...props}))}
                        type={type}
                        key={props.value}
                        onClick={() => !disabled && handleClick(props.value)}
                        className={clsx(`${prefixCls}-item`, className, {
                            [`${prefixCls}-active`]: value.indexOf(props.value) !== -1 || active,
                            [`${prefixCls}-default`]: active
                        })}
                    >{label}</Tag>
                ))
            }

            {shortList && <ButtonText
                type="primary"
                size="small"
                onClick={handleShowAll}
            >{getLocalisation('show_all')}</ButtonText>}

            {onCreate && <Popover
                content={getContent()}
                placement="bottom"
                visible={visible}
                trigger="click"
                onVisibleChange={handleVisibleChange}
            >
                <Button
                    size="small"
                    type="dropdown"
                    onClick={handleClickCreate}
                    beforeIcon={<PlusIcon/>}
                />
            </Popover>}
        </div>
    );
};
