import React from "react";
import { Breadcrumb, BreadcrumbProps } from "../Breadcrumb/Breadcrumb";
import { BackTop } from "@components";
import clsx from "clsx";
import "./BlankPage.less";
import { useBreakpoint } from "@helpers";

const prefixCls = "cmp-blank-page";

export interface BlankPageProps {
  breadcrumb?: BreadcrumbProps["items"];
  children?: JSX.Element | JSX.Element[] | null;
  actions?: JSX.Element | JSX.Element[] | null;
  description?: JSX.Element | string;
  className?: string;
}

export const BlankPage: React.FC<BlankPageProps> = (props: BlankPageProps) => {
  const { className, children, breadcrumb, actions, description } = props;

  const { isMobile } = useBreakpoint();

  const renderBreadcrumb = (breadcrumb: BlankPageProps["breadcrumb"]) => {
    return (
      breadcrumb && (
        <Breadcrumb
          className={clsx(`${prefixCls}-breadcrumb`)}
          items={breadcrumb}
        />
      )
    );
  };

  return (
    <div
      className={clsx(`${prefixCls}`, className, {
        [`${prefixCls}-mobile`]: isMobile
    })}>
      {breadcrumb && (
        <div className={clsx(`${prefixCls}-title`)}>
          {renderBreadcrumb(breadcrumb)}
          {actions}
        </div>
      )}
      {description && <div className={clsx(`${prefixCls}-description`)}>{description}</div>}
      <div className={clsx(`${prefixCls}-body`)}>{children}</div>
      <BackTop />
    </div>
  );
};
