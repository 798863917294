import React from "react";
import { Drawer } from '@components';

import clsx from "clsx";
import { KnowledgeViewItem } from "@pages/KnowledgeView/KnowledgeViewItem";
import './KnowledgeViewDrawer.less';
import { useBreakpoint } from "@helpers";


export interface KnowledgeViewDrawerProps {
    visible?: boolean
    onClose?: () => void,
    itemId?: any
    contextId?: any
}

const prefixCls = "page-knowledge-view-drawer"

export const KnowledgeViewDrawer: React.FC<KnowledgeViewDrawerProps> = (props: KnowledgeViewDrawerProps) => {
    const { visible, onClose, itemId, contextId } = props;

    const { isMobile } = useBreakpoint();

    return (
        <Drawer
            onClose={onClose}
            className={clsx(prefixCls)}
            closable={true}
            visible={visible}
            mask={true}
            size={isMobile ? 'small' : 'middle'}
        >
            <KnowledgeViewItem
                itemId={itemId}
                contextId={contextId}
            />
        </Drawer>
    );
}
