import React, {ReactNode, useState} from "react";
import { Popconfirm } from "@components";
import { useTranslator } from "@helpers";
import {Tag as AntdTag, TagProps as AntdTagProps} from "antd";
import {TagTypeProp, SizeProp} from "@types";
import {CloseIcon, LockIcon} from "@icons";
import clsx from "clsx";
import "./Tag.less";

export interface TagProps {
    disabled?: boolean
    type?: TagTypeProp;
    children?: AntdTagProps["children"];
    style?: AntdTagProps["style"];
    onClick?: (event: any) => void;
    onMouseEnter?: AntdTagProps["onMouseEnter"];
    onMouseLeave?: AntdTagProps["onMouseLeave"];
    className?: AntdTagProps["className"];
    onRemove?: () => void;
    size?: SizeProp;
    showPopconfirm?: boolean
    suffix?: ReactNode
}

const prefixCls = "cmp-tag";

export const Tag: React.FC<TagProps> = (props: TagProps) => {
    const {
        children, type = "default", size = "middle", className,
        onClick, onRemove, suffix, disabled,
        onMouseEnter, onMouseLeave, showPopconfirm = true
    } = props;

    const [visible, setVisible] = useState(false);
    const { getLocalisation } = useTranslator("components")

    const handlePopconfirmClick = () => {
        onRemove && onRemove();
        setVisible(false);
    }

    const handleClick = () => {
        setVisible(true);

        !showPopconfirm && onRemove?.();
    }

    const handleClose = () => {
        setVisible(false)
    }

    const handleVisibleRemoveChange = (visible: boolean) => {
        if (!visible) {
            setVisible(visible);
        }
    };

    const mods = {
        [`${prefixCls}-disabled`]: disabled,
        [`${prefixCls}-active`]: !!onClick,
    }

    const getTag = (
        <AntdTag
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            icon={<div className={`${prefixCls}-icon`}>
                {disabled && <LockIcon/>} {suffix}
            </div>}
            onClose={handleClick}
            closable={!!onRemove}
            closeIcon={<CloseIcon />}
            onClick={onClick}
            prefixCls={prefixCls}
            className={clsx(className, mods, `${prefixCls}-${type}`, `${prefixCls}-${size}`)}
        ><span>{children}</span></AntdTag>
    )

    return (
        <>
            {showPopconfirm && <Popconfirm
                onVisibleChange={handleVisibleRemoveChange}
                title={getLocalisation('want_delete')}
                trigger="contextMenu"
                onClose={handleClose}
                onClick={handlePopconfirmClick}
                visible={visible}
            >{getTag}</Popconfirm>}

            {!showPopconfirm && getTag}
        </>
    );
};
