import React, {useContext, useEffect, useState} from "react";
import {ButtonMenu, ButtonMenuProps} from "@components";
import {useLocation} from "react-router-dom";
import {ControlsContext, ProjectsContext} from "@context";
import {MenuItemProps, SectionItemProps} from "@types";
import {menuItems} from "@helpers";
import clsx from "clsx";
import "./MainMenu.less";
import {MainReport} from "@pages/Main/MainReport";

export interface MainMenuProps {
    onClick?: (item: MenuItemProps) => void;
    sections?: any;
    onSectionClick?: (item: MenuItemProps["path"], section?: SectionItemProps) => void;
    onAddSectionClick?: () => void;
    onVisiblePopoverChange?: ButtonMenuProps["onVisibleChange"]
}

const prefixCls = "page-main-menu";

export const MainMenu: React.FC<MainMenuProps> = (props: MainMenuProps) => {
    const {onClick, onSectionClick, onAddSectionClick, sections, onVisiblePopoverChange} = props;

    const location = useLocation();
    const {controls} = useContext(ControlsContext);
    const {currentProject} = useContext(ProjectsContext)

    const [selectedKeys, setSelectedMenuKeys] = useState<Array<string>>();

    const handleSectionClick = (section: SectionItemProps, path: MenuItemProps["path"]) => {
        onSectionClick?.(path, section)
    }

    const renderMenuItem = ({path, name, children, Icon, ...props}: MenuItemProps) => {

        const hasChildren = children && children.length;

        const pathWithProject = path?.replace(":project", currentProject?.value)

        if (!hasChildren && path === "/project/:project/knowledge") {
            return (
                <ButtonMenu
                    key={path}
                    path={path}
                    label={name}
                    Icon={Icon}
                    selected={selectedKeys}
                    items={sections || []}
                    onCreate={controls["section.button.create.view"] && onAddSectionClick}
                    onVisibleChange={onVisiblePopoverChange}
                    onClick={(section: SectionItemProps) => handleSectionClick(section, pathWithProject)}
                />
            );
        } else if (!hasChildren) {
            return (
                <ButtonMenu
                    key={path}
                    path={path}
                    label={name}
                    Icon={Icon}
                    selected={selectedKeys}
                    onClick={() => onClick!({path: pathWithProject, children, name, Icon, ...props})}
                />
            );
        }

        return (
            <ul key={`group-${path}`} className={clsx(`${prefixCls}-group`)}>
                {children?.map(renderMenuItem)}
            </ul>
        );
    };

    useEffect(() => {
        const pathname = location?.pathname;

        if (pathname) {
            const menuItem = menuItems.find(
                ({path}: MenuItemProps) => path && pathname.replace(`project/${currentProject?.value}`, 'project/:project').indexOf(path) === 0
            );
            setSelectedMenuKeys(menuItem && menuItem.path ? [menuItem.path] : []);
        }
    }, [location?.pathname, currentProject?.value]);

    return (
        <ul className={clsx(`${prefixCls}`)}>
            {menuItems
                .filter(
                    (item: MenuItemProps) => !item.control || !!controls[item.control]
                )
                .map(renderMenuItem)}
            <MainReport onClick={onClick} />
        </ul>
    );
};
