import { LOCALES } from "./Locales";

import RuMain from './ru/Main.json'
import RuHome from './ru/Home.json'
import RuFiles from './ru/Files.json'
import RuRoles from './ru/Roles.json'
import RuUsers from './ru/Users.json'
import RuSearch from './ru/Search.json'
import RuSignIn from './ru/SignIn.json'
import RuHistory from './ru/History.json'
import RuPage404 from './ru/Page404.json'
import RuProfile from './ru/Profile.json'
import RuControls from './ru/Controls.json'
import RuProjects from './ru/Projects.json'
import RuKnowledge from './ru/Knowledge.json'
import RuKnowledges from './ru/Knowledges.json'
import RuProcedures from './ru/Procedures.json'
import RuComponents from './ru/Components.json'
import RuPageDenied from './ru/PageDenied.json'
import RuPermissions from './ru/Permissions.json'
import RuRoleTemplates from './ru/RoleTemplates.json'
import RuReports from './ru/Reports.json'
import RuHelp from './ru/Help.json'
import RuPageRestore from './ru/PageRestore.json'
import RuPageActiveMail from './ru/PageActiveMail.json'

import EnMain from './en/Main.json'
import EnHome from './en/Home.json'
import EnFiles from './en/Files.json'
import EnRoles from './en/Roles.json'
import EnUsers from './en/Users.json'
import EnSearch from './en/Search.json'
import EnSignIn from './en/SignIn.json'
import EnHistory from './ru/History.json'
import EnPage404 from './en/Page404.json'
import EnProfile from './en/Profile.json'
import EnProjects from './en/Projects.json'
import EnControls from './en/Controls.json'
import EnKnowledge from './en/Knowledge.json'
import EnKnowledges from './en/Knowledges.json'
import EnProcedures from './en/Procedures.json'
import EnComponents from './en/Components.json'
import EnPageDenied from './en/PageDenied.json'
import EnPermissions from './en/Permissions.json'
import EnRoleTemplates from './en/RoleTemplates.json'
import EnHelp from './en/Help.json'
import EnPageRestore from './en/PageRestore.json'
import EnPageActiveMail from './en/PageActiveMail.json'
import EnReports from './en/Reports.json'

export { LOCALES } from "./Locales";

export const translations = {
    [LOCALES.ENGLISH]: {
        "main": EnMain,
        "files": EnFiles,
        "home": EnHome,
        "roles": EnRoles,
        "users": EnUsers,
        "search": EnSearch,
        "sign-in": EnSignIn,
        "history": EnHistory,
        "page404": EnPage404,
        "profile": EnProfile,
        "controls": EnControls,
        "projects": EnProjects,
        "knowledge": EnKnowledge,
        "knowledges": EnKnowledges,
        "components": EnComponents,
        "procedures": EnProcedures,
        "page-denied": EnPageDenied,
        'permissions': EnPermissions,
        "role-templates": EnRoleTemplates,
        "help": EnHelp,
        "page-restore": EnPageRestore,
        "page-active-mail": EnPageActiveMail,
        "reports": EnReports,
    },
    [LOCALES.RUSSIAN]: {
        "main": RuMain,
        "files": RuFiles,
        "home": RuHome,
        "roles": RuRoles,
        "users": RuUsers,
        "search": RuSearch,
        "sign-in": RuSignIn,
        "history": RuHistory,
        "page404": RuPage404,
        "profile": RuProfile,
        "projects": RuProjects,
        "controls": RuControls,
        "knowledge": RuKnowledge,
        "knowledges": RuKnowledges,
        "components": RuComponents,
        "procedures": RuProcedures,
        "page-denied": RuPageDenied,
        'permissions': RuPermissions,
        "role-templates": RuRoleTemplates,
        "help": RuHelp,
        "page-restore": RuPageRestore,
        "page-active-mail": RuPageActiveMail,
        "reports": RuReports,
    }
};