import React, { useState } from 'react';
import { PopoverProps as AntdPopoverProps } from 'antd';
import { Button, Popconfirm, ButtonProps, ButtonText, ButtonTextProps } from '@components';

import "./ButtonPopconfirm.less"

export interface ButtonPopconfirmProps extends ButtonProps {
    placement?: AntdPopoverProps["placement"];
    title?: AntdPopoverProps["title"];
    getPopupContainer?: AntdPopoverProps["getPopupContainer"]
    onClick?: (metaData: any) => any;
    buttonType?: "default" | "text";
    type?: any | ButtonProps["type"] | ButtonTextProps["type"]
}

export const ButtonPopconfirm: React.FC<ButtonPopconfirmProps> = (props: ButtonPopconfirmProps) => {

    const { placement, title, onClick, getPopupContainer, buttonType = "default", ...values } = props;

    const [visible, setVisible] = useState<boolean>(false);

    const handleFinish = () => {
        onClick && onClick({ setVisible })
    }

    const handleVisible = (event: any) => {
        event.stopPropagation()
        setVisible(prev => !prev)
    }

    const handleClose = () => {
        setVisible(false)
    }

    const handleVisibleChange = (visible: boolean) => {
        if (!visible) {
            setVisible(visible);
        }
    };

    return (
        <Popconfirm
            visible={visible}
            placement={placement}
            title={title}
            getPopupContainer={getPopupContainer}
            onClose={handleClose}
            onClick={handleFinish}
            onVisibleChange={handleVisibleChange}
        >
            {buttonType === "default" &&
                <Button
                    {...values}
                    onClick={handleVisible}
                />
            }
            {buttonType === "text" &&
                <ButtonText
                    { ...values}
                    onClick={handleVisible}
                />
            }
        </Popconfirm>
    )
};