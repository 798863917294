import React from "react";
import { PageListDataItemProps, PageListProps } from './PageList';
import { PencilIcon, RestoreIcon, TrashIcon, LockIcon } from '@icons';
import { useTranslator } from "@helpers";
import { ButtonPopconfirm, Space, Tooltip } from '@components';
import clsx from "clsx";

import "./PageListTitle.less";


export interface PageListTitleProps {
    record: any
    title: string
    classNames?: string
    onEdit?: PageListProps["onEdit"]
    onRemove?: (record: PageListDataItemProps) => void
    onBlock?: (record: PageListDataItemProps) => void
    onCheckEdit?: PageListProps["onCheckEdit"]
    onCheckRemove?: PageListProps["onCheckRemove"]
    onCheckRestore?: PageListProps["onCheckRestore"]
    onCheckBlock?: PageListProps["onCheckBlock"]
    onRestore?: PageListProps["onRestore"]
    getIconPrefix?: PageListProps["getIconPrefix"]
    getIconSuffix?: PageListProps["getIconSuffix"]
    getTitleDelete?: (record: any) => React.ReactNode;
}

const prefixCls = "cmp-page-list-title";

export const PageListTitle: React.FC<PageListTitleProps> = (props: PageListTitleProps) => {
    const { getLocalisation } = useTranslator("components")

    const {
        record, onCheckEdit, onCheckRemove, onCheckRestore, onCheckBlock, title, classNames,
        onRestore, onEdit, onRemove, onBlock, getIconPrefix, getIconSuffix, getTitleDelete }
    = props;

    const handleRemove = ({ setVisible }: any) => {
        setVisible(true);
        onRemove && onRemove(record);
        setVisible(false)
    }

    const isEdit = (!onCheckEdit || onCheckEdit(record)) && onEdit;
    const isRemove = (!onCheckRemove || onCheckRemove(record)) && !!onRemove;
    const isRestore = (!onCheckRestore || onCheckRestore(record)) && !!onRestore;
    const isBlock = (!onCheckBlock || onCheckBlock(record)) && !!onBlock;

    return (
        <div
            key="title"
            className={clsx(`${prefixCls}`, classNames)}
        >
            <Space align="center" justify="space-between">
                <div
                    className={clsx(`${prefixCls}-value`, {
                        [`${prefixCls}-value-edit`]: isEdit
                    })}
                >
                    {getIconPrefix && getIconPrefix(record)}
                    <div
                        onClick={() => isEdit && onEdit && onEdit(record)}
                        className={clsx(`${prefixCls}-el`)}
                    >
                        {title}
                        {isEdit && <PencilIcon />}
                    </div>
                    {getIconSuffix && getIconSuffix(record)}
                </div>

                {isRemove && <ButtonPopconfirm
                    getPopupContainer={() => document.body}
                    placement="topRight"
                    title={getTitleDelete ? getTitleDelete(record) : getLocalisation('question')}
                    onClick={handleRemove}
                    buttonType="text"
                    afterIcon={<TrashIcon />}
                />}

                {isRestore && <Tooltip placement="left" title={getLocalisation('restore')}>
                    <ButtonPopconfirm
                        getPopupContainer={() => document.body}
                        buttonType="text"
                        placement="topRight"
                        onClick={() => onRestore && onRestore(record)}
                        afterIcon={<RestoreIcon />}
                        title={getLocalisation('want_restore')}
                    />
                </Tooltip>}

                {isBlock && <Tooltip placement="left" title={getLocalisation('block')}>
                    <ButtonPopconfirm
                        getPopupContainer={() => document.body}
                        buttonType="text"
                        placement="topRight"
                        onClick={() => onBlock && onBlock(record)}
                        afterIcon={<LockIcon />}
                        title={getLocalisation('want_block')}
                    />
                </Tooltip>}
            </Space>
        </div>
    )
}