import React from 'react';
import {PopoverProps as AntdPopoverProps} from 'antd';
import {Button, Space, Popover} from '@components';
import { useTranslator } from "@helpers";

import "./Popconfirm.less"

export interface PopconfirmProps {
    placement?: AntdPopoverProps["placement"];
    children: AntdPopoverProps["children"];
    title: AntdPopoverProps["title"];
    visible: AntdPopoverProps["visible"];
    trigger?: AntdPopoverProps["trigger"];
    getPopupContainer?: AntdPopoverProps["getPopupContainer"]
    onClick?: () => void;
    onClose?: () => void;
    onVisibleChange?: (visible: boolean) => void;
}

const prefixCls = "cmp-popconfirm";


export const Popconfirm: React.FC<PopconfirmProps> = (props: PopconfirmProps) => {

    const {children, placement, title, visible,
        trigger, onClick, onClose, onVisibleChange, getPopupContainer
    } = props;

    const { getLocalisation } = useTranslator("components")

    const handleFinish = (event: any) => {
        event.stopPropagation()
        onClick!()
    }

    const handleClose = (event: any) => {
        event.stopPropagation()
        onClose!()
    }

    const handleVisibleChange = (visible: boolean) => {
        onVisibleChange!(visible)
    }

    const getContent = () => (
        <Space>
            <Button
                size="small"
                type='primary'
                onClick={handleFinish}
            >{getLocalisation('yes')}</Button>
            <Button
                size="small"
                type='secondary'
                onClick={handleClose}
            >{getLocalisation('no')}</Button>
        </Space>
    )

    return (
        <Popover
            getPopupContainer={getPopupContainer}
            visible={visible}
            placement={placement}
            title={title}
            className={prefixCls}
            content={getContent()}
            onVisibleChange={handleVisibleChange}
            trigger={trigger}
        >{children}</Popover>
    )
};
