import React, {useState, useEffect, useContext} from "react";
import {
    Space, Drawer, Form, useForm, FormItem, Select,
    TagsInput, TagsListOptionProps, RadioGroupProps, Loader, Button
} from "@components";
import {CategoriesApi, KeywordsApi, LanguagesApi, SectionsApi, TagsApi} from "@api";
import {CategoryItemProps, KeywordsProps, LanguageItemProps, SectionItemProps} from "@types";
import {useTranslator} from "@helpers";
import {ProjectsContext} from "@context";
import {useLocation} from "react-router-dom";
import clsx from "clsx";

import "./MainFormSearch.less";

export interface MainFormSearchProps {
    initialValues?: any
    visible?: boolean
    onClose?: () => void
    onSubmit?: (values: any, metaData?: any) => void
    onReset?: (form?: any) => void
}

const prefixCls = "page-main-form-search";

export const MainFormSearch = (props: MainFormSearchProps) => {
    const { visible, onClose, initialValues, onSubmit, onReset } = props;

    const {getLocalisation} = useTranslator("main");

    const [form] = useForm();

    const location = useLocation();
    const {currentProject} = useContext(ProjectsContext);

    const [loading, setLoadings] = useState<boolean>();
    const [submitting, setSubmitting] = useState<boolean>();

    const [sections, setSections] = useState<SectionItemProps[]>();
    const [categories, setCategories] = useState<CategoryItemProps[]>()
    const [languages, setLanguages] = useState<LanguageItemProps[]>();
    const [keywords, setKeywords] = useState<KeywordsProps[]>([]);
    const [tags, setTags] = useState<TagsListOptionProps[]>();

    const periodOptions: RadioGroupProps["options"] = [
        {
            value: "all",
            label: getLocalisation('all_date'),
        },
        {
            value: "last_day",
            label: getLocalisation('last_day'),
        },
        {
            value: "last_week",
            label: getLocalisation('last_week'),
        },
        {
            value: "last_month",
            label: getLocalisation('last_month'),
        },
        {
            value: "last_year",
            label: getLocalisation('last_year'),
        },
    ];

    const handleSave = () => {
        form.submit();
    }

    const handleFinish = (values: any) => {
        onSubmit?.(values, {setSubmitting})
    }

    const fetchData = async (projectId: any) => {
        const params = { project_id: projectId }

        setLoadings(true);

        const [tags, sections, languages, keywords, categories] = await Promise.all([
            TagsApi.getArticleTags(),
            SectionsApi.getList(params),
            LanguagesApi.getProjectList(params),
            KeywordsApi.getAllKeywords(),
            CategoriesApi.getList(undefined, params)
        ]);

        setTags(tags || []);
        setLanguages(languages || []);
        setCategories(categories || []);
        setKeywords(keywords || []);
        setSections((sections || []).map(({ name, id, ...item }: any) => ({
            label: name,
            value: id,
            ...item
        })));

        setLoadings(false);
    }

    useEffect(() => {
        if (visible && initialValues) {
            form.setFieldsValue({...initialValues});
        }
    }, [visible, initialValues, currentProject])

    useEffect(() => {
        if (visible) {
            fetchData(currentProject?.value).catch(console.error);
        }
    }, [visible, currentProject])

    useEffect(() => {
        onClose?.()
    }, [location])

    return (
        <Drawer
            placement="right"
            visible={visible}
            onClose={onClose}
            size='small'
            className={clsx(`${prefixCls}-drawer`)}
        >
            <Loader
                size="middle"
                spinning={loading}
                className={clsx(`${prefixCls}-loader`)}
            >
                <Form
                    form={form}
                    autoComplete="off"
                    className={clsx(`${prefixCls}`)}
                    layout="vertical"
                    onFinish={handleFinish}
                >
                    <Space
                        size="large"
                        direction="vertical"
                    >
                        <Space
                            size="middle"
                            direction="vertical"
                        >

                            <FormItem
                                label={getLocalisation('period')}
                                layout="vertical"
                                name="date"
                            >
                                <Select
                                    options={periodOptions}
                                />
                            </FormItem>

                            {languages && languages.length > 1 && <FormItem
                                label={getLocalisation('language')}
                                layout="vertical"
                                name="languages"
                            >
                                <Select
                                    options={[{ value: 'all', label: getLocalisation('all') }, ...languages.map((item) => ({
                                        value: item.id,
                                        label: <span className={clsx(`${prefixCls}-sort`)}>
                                        {item.name}
                                            <span className={clsx(`${prefixCls}-sort-icon`)}>
                                            {item.icon}
                                        </span>
                                    </span>
                                    }))]}
                                />
                            </FormItem>}

                            {sections && <FormItem
                                name="sections"
                                layout="vertical"
                                label={getLocalisation('sections')}
                            >
                                <TagsInput
                                    size="small"
                                    placeholder={getLocalisation('search')}
                                    options={sections}
                                    minChars={0}
                                />
                            </FormItem>}

                            {categories && <FormItem
                                name="categories"
                                layout="vertical"
                                label={getLocalisation('groups')}
                            >
                                <TagsInput
                                    size="small"
                                    placeholder={getLocalisation('search')}
                                    options={categories}
                                    minChars={0}
                                />
                            </FormItem>}

                            {keywords && <FormItem
                                name="keywords"
                                layout="vertical"
                                label={getLocalisation('keywords')}
                            >
                                <TagsInput
                                    size="small"
                                    placeholder={getLocalisation('search')}
                                    options={keywords}
                                    minChars={0}
                                />
                            </FormItem>}

                            {tags && <FormItem
                                name="tags"
                                layout="vertical"
                                label={getLocalisation('tags')}
                            >
                                <TagsInput
                                    size="small"
                                    placeholder={getLocalisation('search')}
                                    options={tags}
                                    minChars={0}
                                />
                            </FormItem>}

                        </Space>

                        <Space
                            size="middle"
                            direction="vertical"
                        >
                            <FormItem>
                                <Button
                                    block
                                    key="submit"
                                    type="primary"
                                    onClick={handleSave}
                                    loading={submitting}
                                >{getLocalisation('apply')}</Button>
                            </FormItem>

                            <FormItem>
                                <Button
                                    block
                                    key="cancel"
                                    type="secondary"
                                    onClick={() => onReset?.(form)}
                                    disabled={submitting}
                                >{getLocalisation('reset')}</Button>
                            </FormItem>
                        </Space>
                    </Space>

                </Form>
            </Loader>


        </Drawer>
    );
};
