import axios from 'axios'
import {settings} from "@helpers";
import {ReportItemProps} from "@types";

const transformItem = ({...values}: any) => ({
    ...values,
    id: `${values.article_id}-${values.project_id}-${values.section_id}-${values.category_id}`
})

export async function getArticlesList(params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/reports/articles`,
        method: 'GET',
        params: { params }
    }).catch((e) => {console.error(e)});

    const result: Array<ReportItemProps> = res?.data?.success &&
        res?.data?.result.map((item: any) => transformItem(item));

    return result;
}


export const ReportApi = { getArticlesList }
