import React, {useState, useEffect, useRef} from "react";
import {PageListFieldsItemProps, PageListDataItemProps, PageListProps} from './PageList';
import {PageListTitle} from "./PageListTitle";
import {useBreakpoint, useTranslator} from "@helpers";
import clsx from "clsx";

import "./PageListCard.less";

export type PageListCardFieldProps = PageListFieldsItemProps;
export type PageListCardFieldsProps = Array<PageListCardFieldProps>;

export interface PageListCardProps {
    onEdit?: PageListProps["onEdit"]
    onRemove?: (record: PageListDataItemProps) => void
    onBlock?: (record: PageListDataItemProps) => void
    getPrefix?: PageListProps["getPrefix"]
    getIconPrefix?: PageListProps["getIconPrefix"]
    getIconSuffix?: PageListProps["getIconSuffix"]
    getTitleDelete?: PageListProps["getTitleDelete"]
    onCheckEdit?: PageListProps["onCheckEdit"]
    onCheckRemove?: PageListProps["onCheckRemove"]
    onCheckRestore?: PageListProps["onCheckRestore"]
    onCheckBlock?: PageListProps["onCheckBlock"]
    onRestore?: PageListProps["onRestore"]
    fields?: PageListCardFieldsProps
    isTotal?: boolean
    record: any
}

const prefixCls = "cmp-page-list-card";

const maxCount = 2;

export const PageListCard: React.FC<PageListCardProps> = (props: PageListCardProps) => {
    const { getLocalisation } = useTranslator("components")

    const {
        fields, record, isTotal, onEdit, onRemove, getPrefix,
        onCheckEdit, onCheckRemove, getIconPrefix, onCheckRestore, onCheckBlock,
        getIconSuffix, getTitleDelete, onRestore, onBlock
    } = props;

    const elItem = useRef<any>(null);
    const [titleField, setTitleField] = useState<PageListCardFieldProps>();
    const { isMobile } = useBreakpoint();

    const getValue = (record: any, {render, dataIndex, renderTotal}: any) => {
        const _render = isTotal ? renderTotal : render;

        return _render ?
            _render(record[dataIndex], record) : record[dataIndex]
    }

    const getFields = (fields: any) => (
        fields?.map(({dataIndex, label, render, ...props}: PageListCardFieldProps, i: number) => {

            const value = getValue(record, {dataIndex, label, render, ...props});

            return (
                <div
                    key={i}
                    className={clsx(`${prefixCls}-field`)}
                >
                    <div className={clsx(`${prefixCls}-label`)}>{label}</div>
                    <div className={clsx(`${prefixCls}-value`, {
                        [`${prefixCls}-value-total`]: isTotal
                    })}>{value || getLocalisation("n/a")}</div>
                </div>
            )

        })
    )

    const getRows = (fields: any[]) => {
        const rows = [];

        while (fields.length) {
            let count = 0;
            const rowData = [];

            while (count < maxCount) {

                if (!fields[0] || (count + fields[0].colspan) > maxCount) {
                    break
                }

                const fieldData = fields.shift();

                count = count + (fieldData.colspan || 1);

                rowData.push(fieldData)
            }

            if (rowData.length) {
                rows.push(rowData)
            }
        }

        return rows.map((rowData: any, i: number) => (
            <div
                key={i}
                className={clsx(`${prefixCls}-data`)}
            >{getFields(rowData)}</div>
        ));
    }

    useEffect(() => {

        const titleField = fields?.filter((_: any, i: number) => i === 0)[0] || (fields && fields[0]);

        setTitleField(titleField);

    }, [fields])

    return (
        <div
            ref={elItem}
            className={clsx(prefixCls, {
                [`${prefixCls}-total`]: isTotal,
                [`${prefixCls}-mobile`]: isMobile
            })}
        >
            {!isTotal && getPrefix && <div className={clsx(`${prefixCls}-prefix`)}>{getPrefix(record)}</div>}
            <div className={clsx(`${prefixCls}-wrap`)}>

                {titleField && <PageListTitle
                    title={getValue(record, titleField)}
                    getTitleDelete={getTitleDelete}
                    onEdit={onEdit}
                    onBlock={onBlock}
                    onRemove={onRemove}
                    onRestore={onRestore}
                    record={record}
                    getIconPrefix={getIconPrefix}
                    getIconSuffix={getIconSuffix}
                    onCheckEdit={onCheckEdit}
                    onCheckRemove={onCheckRemove}
                    onCheckRestore={onCheckRestore}
                    onCheckBlock={onCheckBlock}
                />}

                {fields && getRows(fields.slice(1))}

            </div>
        </div>
    );
}