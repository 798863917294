import React from "react";
import clsx from "clsx";

import {TypographyText} from "@components/Typography/Typography";
import { Plug404Icon } from "@assets/icons";
import './EmptyRequest.less'
import {SizeProp} from "@types";

export interface EmptyRequestProps {
    size?: SizeProp
    massage?: React.ReactNode;
}

const prefixCls = "cmp-empty-request";

export const EmptyRequest = (props: EmptyRequestProps) => {

    const { massage, size = "middle" } = props;

    return (
        <div className={clsx(`${prefixCls}`, `${prefixCls}-${size}`)}>
            <span className={clsx(`${prefixCls}-attention-icon`)}>
                <Plug404Icon />
            </span>
            <TypographyText level="small" type="secondary">{massage}</TypographyText>
        </div>
    );
}