import React from "react";
import { useTranslator } from "@helpers";
import clsx from 'clsx';

import "./ButtonNews.less";
import { ButtonText, Tooltip } from "@components";
import { NotificationIcon } from "@assets/icons";
import { ButtonNewsList, ButtonNewsListProps } from "./ButtonNewsList";

export interface ButtonNewsProps {
    checkNews?: boolean;
    items?: ButtonNewsListProps["news"];
    visible?: ButtonNewsListProps["visible"];
    onVisible?: ButtonNewsListProps["onClose"];
    onCreate?: ButtonNewsListProps["onCreate"];
    onEdit?: ButtonNewsListProps["onEdit"];
    onLoad?: ButtonNewsListProps["onLoad"];
    onRemove?: ButtonNewsListProps["onRemove"];
    onCheck?: ButtonNewsListProps["onCheck"];
    onClickFavorites?: ButtonNewsListProps["onClickFavorites"];
    onClickHistory?: ButtonNewsListProps['onClickHistory'];
}

const prefixCls = "cmp-button-news";

export const ButtonNews: React.FC<ButtonNewsProps> = (props: ButtonNewsProps) => {

    const {
        items, checkNews, onCreate, onEdit,
        onRemove, onLoad, onCheck, visible, onVisible,
        onClickFavorites, onClickHistory
    } = props;

    const { getLocalisation } = useTranslator("components")


    const handleClick = () => {
        onVisible && onVisible(!visible)
    }

    return (
        <>
            <ButtonNewsList
                news={items}
                onEdit={onEdit}
                onLoad={onLoad}
                visible={visible}
                onClose={onVisible}
                onCheck={onCheck}
                onCreate={onCreate}
                onRemove={onRemove}
                onClickFavorites={onClickFavorites}
                onClickHistory={onClickHistory}
                defaultFilter={checkNews ? "all" : "new"}
            />
            <Tooltip title={getLocalisation('news')}>
                <ButtonText
                    size="large"
                    onClick={handleClick}
                    afterIcon={
                        <>
                            <NotificationIcon/>
                            {!checkNews && <div className={clsx(`${prefixCls}-check`)} />}
                        </>
                    }
                    className={clsx(`${prefixCls}`)}
                />
            </Tooltip>
        </>
    )
}