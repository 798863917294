import React from "react";
import {Space, TypographyTitle, Button, InitPage} from '@components';
import {useTranslator} from "@helpers";
import {SuccessIcon} from "@icons";
import clsx from 'clsx';

import './PageActiveMail.less'
import {useNavigate} from "react-router-dom";


const prefixCls = "page-active-mail";

export const PageActiveMail = () => {
    const navigate = useNavigate();
    const { getLocalisation } = useTranslator("page-active-mail");

    const handleBack = () => {
        navigate(`/sign-in`);
    }

    return (
        <InitPage className={clsx(`${prefixCls}`)}>
            <Space className={clsx(`${prefixCls}`)} align="center" direction="vertical" size="large">

            <span className={clsx(`${prefixCls}-icon`)}>
                <SuccessIcon />
            </span>

                <TypographyTitle>{getLocalisation('change_password_title')}</TypographyTitle>

                <Button
                    key="sign-in"
                    type="secondary"
                    onClick={handleBack}
                >{getLocalisation('back_home')}</Button>
            </Space>
        </InitPage>

    );
};