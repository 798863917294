import React from "react";
import { CardTypeProp, SizeProp } from "@types";
import clsx from "clsx";
import "./Card.less"

export interface CardProps {
    children: React.ReactNode;
    title?: React.ReactNode | React.ReactNodeArray;
    type?: CardTypeProp | "none";
    size?: SizeProp;
    afterAction?: React.ReactNode;
    beforeAction?: React.ReactNode;
    onClick?: () => void;
    className?: any
}

const prefixCls = "cmp-card";

export const Card: React.FC<CardProps> = (props: CardProps) => {
    const {
        children, title, type, afterAction,
        beforeAction, onClick, className, size = "middle"
    } = props;

    return (
        <div
            onClick={onClick}
            className={clsx(`${prefixCls}`, `${prefixCls}-${size}`, className)}
        >
            {type && <div className={clsx(`${prefixCls}-indicator`, `${prefixCls}-indicator-${type}`)} />}
            <div className={clsx(`${prefixCls}-cnt`)}>
                {title && <div className={clsx(`${prefixCls}-header`)}>
                    {beforeAction}
                    <div className={clsx(`${prefixCls}-header-title`)}
                    >{title}</div>
                    {afterAction}
                </div>}
                <div
                    className={clsx(`${prefixCls}-body`)}
                >{children}</div>
            </div>
        </div>)
}

