import axios from 'axios'
import {settings} from "@helpers";
import {CategoryItemProps} from "@types";

const transformItem = ({id, name, project_ids, ...values}: any) => ({
    ...values,
    label: name || id,
    value: id,
    projects: project_ids
})

export async function getList(sectionId?: any, params?: any) {

    const res: any = await axios.request({
        url: sectionId ?
            `${settings.API_PREFIX}/knowledge/sections/${sectionId}/categories` :
            `${settings.API_PREFIX}/knowledge/categories`,
        method: 'GET',
        params
    }).catch((e) => {console.error(e)});

    const result: Array<CategoryItemProps> = res?.data?.success &&
        res?.data?.result.map((item: any) => transformItem(item));

    return result;
}

export async function update(sectionId: any, data: CategoryItemProps) {
    const {value} = data;

    const res: any = await axios.request({
        url: value ?
            `${settings.API_PREFIX}/knowledge/sections/${sectionId}/categories/${value}` :
            `${settings.API_PREFIX}/knowledge/sections/${sectionId}/categories`,
        method: value ? 'PUT' : 'POST',
        data
    }).catch((e) => {console.error(e)});

    const result: CategoryItemProps = res?.data?.success && transformItem(res?.data?.result[0]);

    return result;
}

export async function remove(sectionId: any, id: string | number) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/sections/${sectionId}/categories/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function getRecord(sectionId: any, id: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/knowledge/sections/${sectionId}/categories/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: CategoryItemProps = res?.data?.success && transformItem(res?.data?.result[0]);

    return result;
}


export const CategoriesApi = { getList, update, remove, getRecord }
