import { useState } from 'react';
import { SignInLoginForm } from './SignInLoginForm';
import { SignInRestoreForm } from './SignInRestoreForm';
import { InitPage } from '@components';
import clsx from 'clsx';

import './SignIn.less'

const prefixCls = "page-sign-in"


export const SignIn = () => {
    const [forgotPassword, setForgotPassword] = useState<boolean>(false);


    const handleForgotPassword = () => {
        setForgotPassword(!forgotPassword)
    }

    return (
        <InitPage className={clsx(`${prefixCls}`)}>{!forgotPassword
            ? <SignInLoginForm onForgotPassword={handleForgotPassword} />
            : <SignInRestoreForm onForgotPassword={handleForgotPassword} />
        }</InitPage>
    );
}