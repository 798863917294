import axios from "axios";
import {settings, fileSize} from "@helpers";
import {FileItemProps} from '@types';

const getFileName = (name: string) => name.split('.').slice(0, name.split('.').length - 1).join('.')

export const transformItem = ({projects, create_date, modify_date, file_name, name, id, descr,
    file_path, file_length, content_type, member_name, tags, is_favorite, is_published, archived, modify_user}: any) =>
({
    id,
    is_favorite,
    is_published,
    archived: !!archived,
    createDate: create_date,
    modifyDate: modify_date,
    description: descr,
    fileName: `${getFileName(name || "")}.${(file_name || "").split('.').pop()}`,
    name: getFileName(name || ""),
    extension: (file_name || "").split('.').pop(),
    url: `${settings.FILE_PREFIX}/${file_path}`,
    size: fileSize(file_length),
    file_length: file_length,
    type: content_type,
    member_name: member_name,
    modify_user: modify_user,
    tags: tags && tags.map(({tag_id, tag_name, type_name}: any) => ({
        label: tag_name,
        value: tag_id,
        type: type_name
    })),
    projects: projects && projects.map(({name, id, description}: any) => ({
        label: name,
        value: id,
        description: description
    }))
})

export async function getList(params?: any) {

    const res: any = await axios.request({
        url: `${settings.FILE_PREFIX}/contents`,
        method: 'GET',
        params: {params}
    }).catch((e) => {console.error(e)});

    const result: Array<FileItemProps> = res?.data?.success && res?.data?.result.map((item: any) => transformItem(item));
    return result;
}

export async function getRecord(id: any) {

    const res: any = await axios.request({
        url: `${settings.FILE_PREFIX}/contents/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: FileItemProps = res?.data?.success && res?.data?.result && transformItem(res?.data?.result[0]);
    return result;
}

export async function update(data: FileItemProps) {

    const {id} = data;
    const bodyData = new FormData();

    Object.keys(data).forEach((key: any) => {
        const value = data[key as keyof FileItemProps];

        if (key === "file" && value) {
            bodyData.append(`upload`, value)
            return
        }
        else if (value) {
            bodyData.append(key, value);
        }
    })

    const res: any = await axios.request({
        url: id ?
            `${settings.FILE_PREFIX}/contents/${id}` :
            `${settings.FILE_PREFIX}/contents`,
        method: id ? 'PUT' : 'POST',
        headers: {"Content-Type": 'multipart/form-data'},
        data: bodyData
    }).catch((e) => {console.error(e)});

    const result: FileItemProps = res?.data?.success && transformItem(res?.data?.result);

    return result;
}

export async function remove(id: string | number) {
    const res: any = await axios.request({
        url: `${settings.FILE_PREFIX}/contents/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function removeFavorites(id: string | number) {
    const res: any = await axios.request({
        url: `${settings.FILE_PREFIX}/favorites/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: FileItemProps = res?.data?.success;

    return result;
}

export async function updateFavorites(id: string | number) {

    const res: any = await axios.request({
        url: `${settings.FILE_PREFIX}/favorites/${id}`,
        method: 'POST'
    }).catch((e) => {console.error(e)});

    const result: FileItemProps = res?.data?.success;

    return result;
}


export async function archiveFile(id: any) {

    const res: any = await axios.request({
        url: `${settings.FILE_PREFIX}/archived/${id}`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export async function restoreFile(id: any) {

    const res: any = await axios.request({
        url: `${settings.FILE_PREFIX}/archived/${id}/restory`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export const FilesApi = {
    getRecord, getList, update, remove, removeFavorites,
    updateFavorites, archiveFile, restoreFile
};
