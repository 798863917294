import React from "react";
import { EmptyRequest } from "@components";
import { useTranslator } from "@helpers";

import "./KnowledgeViewEmpty.less";

export interface KnowledgeViewEmptyProps {
    itemId?: any,
}

export const KnowledgeViewEmpty = (props: KnowledgeViewEmptyProps) => {
    const { itemId } = props;

    const { getLocalisation } = useTranslator("knowledge");

    return (
        <EmptyRequest massage={getLocalisation("not_published", {
            ["{id}"]: itemId
        })} />
    );
};
