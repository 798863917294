import React, {useState} from "react";
import {ButtonText, ButtonTextProps} from "@components";
import { StarBorderIcon, StarIcon } from "@assets/icons";

import clsx from "clsx";
import "./FavoriteButton.less";

const prefixCls = "cmp-favorite-button";

type OmitButtonTextProps =  Omit<ButtonTextProps, 'beforeIcon' | 'onClick' | 'loading'>
 
export interface FavoriteButtonProps extends OmitButtonTextProps {
    value: boolean
    onClick?: (value: FavoriteButtonProps["value"], metaData: any) => void
}


export const FavoriteButton: React.FC<FavoriteButtonProps> = (props: FavoriteButtonProps) => {

    const { className, onClick, value } = props;

    const [loading, setLoading] = useState<boolean>(false);

    return (
        <ButtonText
            className={clsx(`${prefixCls}-favorite`, className)}
            loading={loading}
            onClick={() => onClick && onClick(value, {setLoading})}
            beforeIcon={value ? <StarIcon /> : <StarBorderIcon />}
        />
    )
};
