import React, {useContext} from "react";
import {Avatar, ButtonText, Space, TypographyText} from '@components';
import {useBreakpoint, userShortName} from '@helpers';
import {UsersContext} from '@context';

import clsx from "clsx";

import './MainAvatar.less';

export interface MainAvatarProps {
    onClick?: () => void;
}

const prefixCls = "page-main-avatar"

export const MainAvatar: React.FC<MainAvatarProps> = ({onClick}: MainAvatarProps) => {
    const {currentUser} = useContext(UsersContext);
    const {isMobile} = useBreakpoint();

    if (!currentUser) {
        return null
    }

    return (
        <ButtonText
            type="primary"
            onClick={onClick}
            size="large"
            className={clsx(prefixCls, {
                [`${prefixCls}-mobile`]: isMobile
            })}
        >
            <Space size="small">
                {!isMobile &&    
                <TypographyText
                    className={clsx(`${prefixCls}-label`)}
                    type="secondary"
                    level="large"
                >{currentUser.member_name}</TypographyText>}
                <Avatar
                    size={44}
                    src={currentUser.avatar}
                >{userShortName(currentUser)}</Avatar>
            </Space>
        </ButtonText>
    );
}
