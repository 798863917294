import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, ButtonText, Drawer, Space } from "@components";
import { ControlsContext, ProjectsContext } from "@context";
import { MenuItemProps, SectionItemProps } from "@types";
import { AppIcon, ArrowUpIcon, BurgerIcon } from "@assets/icons";
import { menuItems, menuSystemItems, useBreakpoint, useTranslator } from "@helpers";
import clsx from "clsx";

import "./MainMenuMobile.less";
import { useLocation } from "react-router-dom";

export interface MainMenuMobileProps {
    onClick?: (item: MenuItemProps) => void;
    sections?: any;
    onSectionClick?: (item: MenuItemProps["path"], section: SectionItemProps) => void;
    onAddSectionClick?: () => void;
    onExpandChange?: ((visible: boolean, metaData: any) => void) | undefined
}

const prefixCls = "page-main-menu-mobile";

type MainMenuExpand = 'knowledge' | 'system'

export const MainMenuMobile: React.FC<MainMenuMobileProps> = (props: MainMenuMobileProps) => {
    const { onClick, onSectionClick, onAddSectionClick, sections, onExpandChange } = props;

    const location = useLocation();
    const { controls } = useContext(ControlsContext);
    const { currentProject } = useContext(ProjectsContext)
    const { isMobile } = useBreakpoint();

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false)
    const [expandKey, setExpandKey] = useState<MainMenuExpand>();
    const [selectedKeys, setSelectedMenuKeys] = useState<Array<string>>([]);

    const { getLocalisation } = useTranslator("main")

    const handleSectionClick = (section: SectionItemProps, path: MenuItemProps["path"]) => {
        onSectionClick?.(path, section)
        setVisible(false)
        setExpandKey(undefined)
    }

    const handleClick = (item: MenuItemProps) => {
        onClick?.(item)
        setVisible(false)
        setExpandKey(undefined)
    }

    const handleClickExpend = (key: MainMenuExpand) => {
        if (key !== expandKey || !expandKey) {
            setExpandKey(key)
        } else if (key === expandKey) {
            setExpandKey(undefined)
        }
    }

    const handleAddSectionClick = () => {
        onAddSectionClick?.()
        setVisible(false)
        setExpandKey(undefined)
    }

    const handleClickKnowledge = () => {
        if (expandKey !== 'knowledge') {
            onExpandChange?.(true, { setLoading })
        }
        handleClickExpend('knowledge')
    }

    const itemsSystem = useMemo(() =>
        menuSystemItems
            .filter(({ control }: MenuItemProps) => !control || !!controls[control])
            .map(({ path, ...arg }: MenuItemProps) => (
                {
                    path: path?.replace(":project", currentProject?.value),
                    ...arg
                }))
        , [controls, currentProject?.value]);


    const renderMenuItem = ({ path, name, children, Icon, ...props }: MenuItemProps) => {

        const hasChildren = children && children.length;

        const pathWithProject = path?.replace(":project", currentProject?.value)

        return (
            <div key={path}>
                {!hasChildren && path === "/project/:project/knowledge" &&
                    <Space direction="vertical">
                        <ButtonText
                            className={clsx({
                                [`${prefixCls}-icon-left`]: expandKey === 'knowledge',
                                [`${prefixCls}-icon-rigth`]: expandKey !== 'knowledge'
                            })}
                            type={selectedKeys?.includes(path || '') || expandKey === 'knowledge' ? 'primary' : 'secondary'}
                            size='large'
                            loading={loading}
                            beforeIcon={expandKey !== 'knowledge' ? <Icon /> : <ArrowUpIcon />}
                            afterIcon={expandKey !== 'knowledge' && <ArrowUpIcon />}
                            children={name}
                            onClick={handleClickKnowledge}
                        />
                        {
                            expandKey === 'knowledge' &&
                            <Space size="middle" direction="vertical">
                                <Button
                                    className={clsx(`${prefixCls}-btn-add`)}
                                    onClick={controls["section.button.create.view"] && handleAddSectionClick}
                                >
                                    {getLocalisation("add")}
                                </Button>
                                {(sections || []).map((item: SectionItemProps) => (
                                    <ButtonText
                                        key={item.id}
                                        onClick={() => handleSectionClick(item, pathWithProject)}
                                        size="middle"
                                        type="secondary"
                                    >
                                        {item.name}
                                    </ButtonText>
                                ))}
                            </Space>
                        }
                    </Space>}
                {!hasChildren && !expandKey && path !== "/project/:project/knowledge" &&
                    <ButtonText
                        size='large'
                        type={selectedKeys?.includes(path || '') ? 'primary' : 'secondary'}
                        beforeIcon={<Icon />}
                        children={name}
                        onClick={() => handleClick({ path: pathWithProject, children, name, Icon, ...props })}
                    />}
            </div>
        )
    }

    useEffect(() => {
        const pathname = location?.pathname;

        if (pathname) {
            const menuItem = menuItems.concat(itemsSystem).find(
                ({ path }: MenuItemProps) =>
                    (path && pathname.replace(`project/${currentProject?.value}`, 'project/:project').indexOf(path) === 0) ||
                    (path && pathname.indexOf(path) === 0)
            );
            setSelectedMenuKeys(menuItem && menuItem.path ? [menuItem.path] : []);
        }
    }, [location?.pathname, currentProject?.value, itemsSystem]);


    return (
        <>
            <ButtonText
                className={clsx(`${prefixCls}-btn`, {
                    [`${prefixCls}-btn-mobile`]: isMobile
                })}
                onClick={() => setVisible(true)}
                beforeIcon={<BurgerIcon />}
            />
            <Drawer
                placement="left"
                onClose={() => setVisible(false)}
                visible={visible}
                size={"small"}
            >
                <ul className={clsx(`${prefixCls}`)}>
                    <Space size={"large"} direction='vertical'>
                        {(!expandKey || expandKey === 'knowledge') && <Space size={"large"} direction='vertical'>
                            {menuItems
                                .filter(
                                    (item: MenuItemProps) => expandKey === 'knowledge' ? item.path?.includes('/project/:project/knowledge') :
                                        !item.control || !!controls[item.control]
                                ).map(renderMenuItem)}
                        </Space>}
                        {(!expandKey || expandKey === 'system') && <ButtonText
                            className={clsx({
                                [`${prefixCls}-icon-left`]: expandKey === 'system',
                                [`${prefixCls}-icon-rigth`]: expandKey !== 'system'
                            })}
                            children={getLocalisation('services')}
                            beforeIcon={expandKey !== 'system' ? <AppIcon /> : <ArrowUpIcon />}
                            afterIcon={expandKey !== 'system' && <ArrowUpIcon />}
                            size='large'
                            type={(selectedKeys[0] || '').includes('system') || expandKey === 'system' ? 'primary' : 'secondary'}
                            onClick={() => handleClickExpend('system')}
                        />}
                    </Space>
                </ul>
                {
                    expandKey === 'system' &&
                    <Space className={`${prefixCls}-items`} size="small" direction="vertical">
                        {(itemsSystem || []).map((item: any) => (
                            <ButtonText
                                key={item.path}
                                onClick={() => handleClick?.(item)}
                                size="middle"
                                type="secondary"
                            >
                                {item.name}
                            </ButtonText>
                        ))}
                    </Space>
                }
            </Drawer>
        </>
    );
};
