import React from 'react';
import { Table, TableProps } from '@components';
import { PageListDataItemProps, PageListFieldsItemProps, PageListProps } from './PageList';
import { PageListTitle } from './PageListTitle';

import './PageListTable.less'
import clsx from 'clsx';


export interface PageListTableProps {
    data: Array<PageListDataItemProps>;
    fields: Array<PageListFieldsItemProps>;
    keyField: PageListProps["keyField"]
    onTableChange?: TableProps["onChange"]
    tableSummary?: TableProps["summary"]
    onEdit?: PageListProps["onEdit"]
    onRemove?: (record: PageListDataItemProps) => void
    onBlock?: (record: PageListDataItemProps) => void
    getPrefix?: PageListProps["getPrefix"]
    getIconPrefix?: PageListProps["getIconPrefix"]
    getIconSuffix?: PageListProps["getIconSuffix"]
    getTitleDelete?: PageListProps["getTitleDelete"]
    onCheckEdit?: PageListProps["onCheckEdit"]
    onCheckRemove?: PageListProps["onCheckRemove"]
    onCheckRestore?: PageListProps["onCheckRestore"]
    onCheckBlock?: PageListProps["onCheckBlock"]
    onRestore?: PageListProps["onRestore"]
}

const prefixCls = 'cmp-page-list-table';

export const PageListTable = (props: PageListTableProps) => {
    const {
        fields, data, keyField = 'id', onEdit, onRemove, onBlock, getPrefix,
        getIconPrefix, getIconSuffix, getTitleDelete, onCheckEdit, onCheckRemove,
        onCheckRestore, onCheckBlock, onRestore, onTableChange, tableSummary
    } = props;

    const indexName = fields[0]?.dataIndex

    const getColumns = () => {
        const innerFields = fields?.map((item: any) => ({
            title: item.label,
            ...item,
        }))

        innerFields[0]['fixed'] = 'left'

        if (getPrefix) {
            innerFields.unshift({
                title: '',
                dataIndex: 'avatar',
                fixed: 'left',
            })
        }

        return innerFields
    }

    const getDataSource = () => {
        let innerData = data.map((item: any) => ({
            ...item,
            key: item[keyField],
            [indexName]: <PageListTitle
                title={item[indexName]}
                classNames={`${prefixCls}-title`}
                getTitleDelete={getTitleDelete}
                onEdit={onEdit}
                onBlock={onBlock}
                onRemove={onRemove}
                onRestore={onRestore}
                record={item}
                getIconPrefix={getIconPrefix}
                getIconSuffix={getIconSuffix}
                onCheckEdit={onCheckEdit}
                onCheckRemove={onCheckRemove}
                onCheckRestore={onCheckRestore}
                onCheckBlock={onCheckBlock}
            />,
        }));
        if (getPrefix) {
            innerData = innerData.map((item: any) => ({
                ...item,
                avatar: getPrefix && <div
                    className={clsx(`${prefixCls}-avatar`)}
                >
                    {getPrefix({ ...item, label: item?.label?.props?.title })}
                </div>,
            }))
        }
        return innerData
    }

    if (!data?.length) {
        return null
    }

    return (
        <Table
            summary={tableSummary}
            onChange={onTableChange}
            size="middle"
            sticky={{ offsetHeader: 80 }}
            columns={getColumns()}
            dataSource={getDataSource()}
        />
    )
};