import React, {useEffect, useRef, useState} from "react";
import {
    Input as AntdInput,
    InputProps as AntdInputProps,
} from "antd";
import { Button } from "@components";
import {EyeIcon, SearchIcon} from "@icons";
import {SizeProp} from "@types";
import clsx from "clsx";
import "./Input.less";

export const prefixCls = "cmp-input";

export interface InputProps {
    value?: AntdInputProps["value"];
    defaultValue?: AntdInputProps["defaultValue"];
    onBlur?: AntdInputProps["onBlur"];
    onClick?: AntdInputProps["onClick"];
    onFocus?: AntdInputProps["onFocus"];
    onChange?: AntdInputProps["onChange"];
    onPressEnter?: AntdInputProps["onPressEnter"];
    onKeyDown?: AntdInputProps["onKeyDown"]
    onKeyPress?: AntdInputProps["onKeyPress"]
    suffix?: AntdInputProps["suffix"];
    prefix?: AntdInputProps["prefix"];
    className?: AntdInputProps["className"];
    placeholder?: AntdInputProps["placeholder"];
    disabled?: AntdInputProps["disabled"];
    type?: AntdInputProps["type"];
    prefixCls?: AntdInputProps["prefixCls"];
    size?: SizeProp;
    loading?: boolean
    ref?: any;
    allowClear?: AntdInputProps["allowClear"];
}

export const Input: React.FC<InputProps> = (props: InputProps) => {
    const {
        disabled, type, value, onBlur, prefix, suffix,
        onChange, onPressEnter, className, placeholder,
        size = "middle", onKeyDown, allowClear, onFocus,
        onKeyPress, loading
    } = props;

    const [spin, setSpin] = useState<boolean>();
    const tmLoading = useRef<any>(null);

    useEffect(() => {

        if (loading) {
            setSpin(loading);
            return
        }

        tmLoading.current && clearTimeout(tmLoading.current);

        tmLoading.current = setTimeout(() => setSpin(loading), 700);

    }, [loading]);

    return (
        <AntdInput
            onFocus={onFocus}
            type={type}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            disabled={disabled}
            onPressEnter={onPressEnter}
            className={clsx(className, `${prefixCls}-${size}`)}
            placeholder={placeholder}
            suffix={spin ? <Button
                type="dropdown" size='small'
                loading={spin}
            /> : suffix}
            prefix={prefix}
            prefixCls={prefixCls}
            allowClear={allowClear}
        />
    );
};

export interface InputPasswordProps extends Omit<InputProps, 'type' | 'suffix'> { }

export const InputPassword: React.FC<InputPasswordProps> = (props: InputPasswordProps) => {
    const [type, setType] = useState<AntdInputProps["type"]>("password");

    const handleClick = () => {
        setType(type === "password" ? "text" : "password");
    };

    return (
        <Input
            type={type}
            suffix={<EyeIcon onClick={handleClick} />}
            {...props}
        />
    );
};

export interface InputSearchProps extends Omit<InputProps, 'type'> {}

export const InputSearch: React.FC<InputSearchProps> = ({ size = "large", prefix, loading, ...props }: InputSearchProps) => {

    return (
        <Input
            size={size}
            loading={loading}
            prefix={<>
                <SearchIcon/>
                {prefix}
            </>}
            {...props}
        />
    );
};
