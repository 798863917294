import {createContext} from 'react';

export type SessionsContextProps = {
  onRemoveSession?: any
  onChangeSession?: any
}

const defaultValue: SessionsContextProps = {
  onRemoveSession: null,
  onChangeSession: null
}

export const SessionsContext = createContext(defaultValue);