import React, { ReactNode } from "react";
import { Slider as AntdSlider, SliderSingleProps as AntdSliderProps } from 'antd';
import { prefixCls as tooltipPrefixCls } from "@components/Tooltip/Tooltip";
import clsx from "clsx";

import './Slider.less'

export interface SliderProps {
    className?: AntdSliderProps['className']
    onChange?: AntdSliderProps['onChange']
    value?: AntdSliderProps['value']
    min?: AntdSliderProps['min']
    max?: AntdSliderProps['max']
    step?: AntdSliderProps['step']
    prefix?: ReactNode
    suffix?: ReactNode
}

const prefixCls = "cmp-slider";

export const Slider: React.FC<SliderProps> = (props) => {

    const { className, prefix, suffix, onChange, value, min = 0, max = 100, step = 1 } = props;


    return (
        <div className={clsx(`${prefixCls}-body`)}>
            {prefix}
            <AntdSlider
                tooltipPrefixCls={tooltipPrefixCls}
                className={className}
                onChange={onChange}
                value={value}
                min={min}
                max={max}
                step={step}
            />
            {suffix}
        </div>
    );
}