import { createContext } from 'react';

export type UpdateKnowledgeContextProps = {
    updateItems?: any[]
    loadUpdateItems?: any
}

const defaultValue: UpdateKnowledgeContextProps = {
    updateItems: undefined,
    loadUpdateItems: undefined
}

export const UpdateKnowledgeContext = createContext(defaultValue);