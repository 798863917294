import React from "react";
import {Form as AntdFrom, FormProps as AntdFromProps} from "antd";
import {FormItemProps as AntdFormItemProps} from "antd/lib/form/FormItem";
import clsx from "clsx";
import "./Form.less";

export interface FormProps {
    form?: AntdFromProps["form"];
    name?: AntdFromProps["name"];
    layout?: AntdFromProps["layout"];
    autoComplete?: AntdFromProps["autoComplete"];
    onFinish?: AntdFromProps["onFinish"];
    onValuesChange?: AntdFromProps["onValuesChange"];
    validateTrigger?: AntdFromProps["validateTrigger"];
    initialValues?: AntdFromProps["initialValues"];
    className?: AntdFromProps["className"];
    children?: AntdFromProps["children"];
}

const prefixCls = "cmp-form";

export const Form: React.FC<FormProps> = (props: FormProps) => {
    const {
        children, form, className, name, onValuesChange,
        layout, autoComplete, onFinish, initialValues,
        validateTrigger = "onSubmit"
    } = props;

    return (
        <AntdFrom
            validateTrigger={validateTrigger}
            prefixCls={prefixCls}
            name={name}
            form={form}
            layout={layout}
            autoComplete={autoComplete}
            onFinish={onFinish}
            initialValues={initialValues}
            onValuesChange={onValuesChange}
            className={className}
        >{children}</AntdFrom>
    );
};

export interface FormItemProps {
    label?: AntdFormItemProps["label"];
    noStyle?: AntdFormItemProps["noStyle"];
    shouldUpdate?: AntdFormItemProps["shouldUpdate"];
    name?: AntdFormItemProps["name"];
    rules?: AntdFormItemProps["rules"];
    children?: AntdFormItemProps["children"];
    className?: AntdFormItemProps["className"];
    layout?: AntdFromProps["layout"];
    initialValues?: AntdFromProps["initialValues"];
    hidden?: AntdFromProps["hidden"];
    hasFeedback?: AntdFormItemProps["hasFeedback"];
    validateStatus?: AntdFormItemProps["validateStatus"];
}

export const FormItem: React.FC<FormItemProps> = (props: FormItemProps) => {
    const {
        className, label, name, rules, layout, hasFeedback,
        children, initialValues, noStyle, shouldUpdate, hidden, validateStatus
    } = props;

    return (
        <AntdFrom.Item
            noStyle={noStyle}
            shouldUpdate={shouldUpdate}
            hasFeedback={hasFeedback}
            validateStatus={validateStatus}
            prefixCls={clsx(`${prefixCls}`)}
            label={label}
            name={name}
            rules={rules}
            hidden={hidden}
            initialValue={initialValues}
            className={clsx(className, {
                [`${prefixCls}-item-${layout}`]: layout,
            })}
        >{children}</AntdFrom.Item>
    );
};

export const useForm = AntdFrom.useForm;
