import React, { useState } from "react";
import {
    Card, Tag, Space, TypographyText,
    Loader, Popover, ButtonText, ButtonPopconfirm, FavoriteButton, MemberView, Tooltip
} from "@components";
import {
    SquareCheckIcon, FaceIcon, PencilIcon, TrashIcon, HistoryIcon,
} from "@assets/icons";
import { toDateTime, useBreakpoint, useTranslator } from "@helpers";
import { NewsItemProps, UsersNewsProps } from "@types";
import clsx from "clsx";

import "./ButtonNewsItem.less";
import { ButtonNewsListProps } from "./ButtonNewsList";

const prefixCls = "cmp-button-news-item";

export interface ButtonNewsItemProps {
    item: NewsItemProps;
    onEdit?: ButtonNewsListProps['onEdit'];
    onRemove?: ButtonNewsListProps['onRemove'];
    onCheck?: ButtonNewsListProps['onCheck'];
    onClickFavorites?: ButtonNewsListProps['onClickFavorites'];
    onClickHistory?: ButtonNewsListProps['onClickHistory']
}

export const ButtonNewsItem: React.FC<ButtonNewsItemProps> = (props: ButtonNewsItemProps) => {

    const { item, onRemove, onEdit, onCheck, onClickFavorites, onClickHistory } = props;

    const { content, id, type_name, title, member_name, create_date, news_members, is_view, projects } = item;
    const [visibleUsers, setVisibleUsers] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false);

    const { isMobile } = useBreakpoint();
    const { getLocalisation } = useTranslator("components")


    const handleFamiliarizeClick = ({setVisible}: any) => {
        onCheck && onCheck(id, { setLoading })
        setVisible(false)
    }

    const handleRemovePopClick = ({ setVisible }: any) => {
        onRemove && onRemove(id, { setLoading });
        setVisible(false)
    }

    const handleShowUsers = () => {
        setVisibleUsers(true)
    }

    const handleVisibleChangeUsers = (visible: boolean) => {
        setVisibleUsers(visible);
    };

    const getContentUsers = () => (
        <>
            {news_members && news_members.map(({ member_fio, member_id, create_date }: UsersNewsProps) => (
                <Space className={clsx(`${prefixCls}-users-item`)} key={member_id}>
                    <TypographyText disabled >{member_fio}</TypographyText>
                    <div className={clsx(`${prefixCls}-splitter`)}/>
                    <TypographyText disabled >{toDateTime(create_date)}</TypographyText>
                </Space>
            )
            )}
        </>
    )

    return (
        <Card className={clsx(`${prefixCls}`, {
            [`${prefixCls}-mobile`]: isMobile
        })}>
            <Loader spinning={loading} size="middle">
                <Space direction='vertical' size='middle' className={clsx(`${prefixCls}-body`)}>

                    <Space className={clsx(`${prefixCls}-card-top`)}>
                        <Space size='middle'>
                            <Tag size="large" type={type_name}>{title}</Tag>
                            <FavoriteButton
                                value={!!item.is_favorite}
                                onClick={(_: any, metaData: any) => onClickFavorites && onClickFavorites(item.id, item.is_favorite, metaData)}
                            />
                        </Space>
                        <Space>
                            <ButtonPopconfirm
                                size="small"
                                buttonType="text"
                                placement="topRight"
                                beforeIcon={<SquareCheckIcon/>}
                                onClick={handleFamiliarizeClick}
                                title={getLocalisation('really_familiarize')}
                                className={clsx(`${prefixCls}-button-action`, `${prefixCls}-button-view`, {
                                    [`${prefixCls}-button-action-check`]: !!is_view,
                                })}
                            >{!!is_view ? getLocalisation("viewed") : getLocalisation("view")}</ButtonPopconfirm>
                        </Space>
                    </Space>

                    {content && <div
                        className={clsx(`${prefixCls}-content`)}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />}

                    <Space
                        wrap
                        align="center"
                        size='middle'
                    >
                        <Space>
                            {projects && projects.map((item: any) =>
                                <Tag key={item.id}>{item.name}</Tag>
                            )}
                        </Space>
                    </Space>

                    <Space className={clsx(`${prefixCls}-card-bottom`)}>
                        <Space justify={isMobile ? 'space-between' : undefined}>
                            <MemberView
                                name={member_name}
                            />
                            <TypographyText level='small'>{toDateTime(create_date)}</TypographyText>
                        </Space>

                        <Space
                            className={clsx(`${prefixCls}-card-bottom-right`)}
                            size='large'
                        >

                            <Popover
                                placement='bottomLeft'
                                visible={visibleUsers}
                                content={getContentUsers}
                                onVisibleChange={handleVisibleChangeUsers}
                                trigger="click"
                            >
                                <ButtonText
                                    size="small"
                                    className={clsx(`${prefixCls}-button-action`, {
                                        [`${prefixCls}-button-action-users-hide`]: !news_members?.length
                                    })}
                                    beforeIcon={<FaceIcon />}
                                    onClick={handleShowUsers}
                                ><TypographyText level='small'>{news_members?.length || 0}</TypographyText></ButtonText>
                            </Popover>

                            {(onEdit || onRemove || onClickHistory) && <Space size='small'>
                                {onClickHistory && <Tooltip title={getLocalisation('history')} placement="left">
                                    <ButtonText
                                        size="small"
                                        className={clsx(`${prefixCls}-button-action`)}
                                        onClick={() => onClickHistory?.(id)}
                                        beforeIcon={<HistoryIcon />}
                                    />
                                </Tooltip>}
                                {onEdit && <ButtonText
                                    size="small"
                                    className={clsx(`${prefixCls}-button-action`)}
                                    onClick={() => onEdit && onEdit(id)}
                                    afterIcon={<PencilIcon />}
                                />}
                                {onRemove && <ButtonPopconfirm
                                    buttonType="text"
                                    size="small"
                                    className={clsx(`${prefixCls}-button-action`)}
                                    afterIcon={<TrashIcon />}
                                    placement="topRight"
                                    title={getLocalisation('want_delete')}
                                    onClick={handleRemovePopClick}
                                />}
                            </Space>}
                        </Space>
                    </Space>
                </Space>
            </Loader>
        </Card>
    );
};