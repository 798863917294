import { createContext } from 'react';

export type FavoritesContextProps = {
    favoriteItems?: any
    favoriteItemsProject?: any
    loadFavoriteItems?: any
}

const defaultValue: FavoritesContextProps = {
    favoriteItems: undefined,
    favoriteItemsProject: undefined,
    loadFavoriteItems: undefined
}

export const FavoritesContext = createContext(defaultValue);