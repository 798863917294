import React from "react";
import {Avatar as AntdAvatar, AvatarProps as AntdAvatarProps} from 'antd';
import clsx from "clsx";

import './Avatar.less'

export interface AvatarProps {
    className?: AntdAvatarProps['className']
    size?: AntdAvatarProps['size']
    children: AntdAvatarProps['children']
    src?: AntdAvatarProps['src']
}

const prefixCls = "cmp-avatar";

export const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => {

    const { className, size, children, src } = props;

    return (
        <AntdAvatar
            {...props}
            prefixCls={clsx(prefixCls)}
            src={src}
            size={size}
            className={className}
        >{children}</AntdAvatar>
    );
}