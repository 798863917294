import {useEffect, useState} from "react";
import {
    Modal, Button, Form, Loader, FormItem, useForm,
    TagsList, TagsListOptionProps, Space, TextEditor, FieldDisplay
} from "@components";
import {TagsApi, KnowledgesApi, VersionsApi} from "@api";
import { useBreakpoint, useTranslator } from "@helpers";
import {KnowledgeVersionChangedProps, KnowledgeVersionProps} from "@types";

import clsx from "clsx";

import "./KnowledgeVersionForm.less";


const defaultValue: KnowledgeVersionProps = {
    id: null,
    name: "",
    content: "",
    content_change: "",
    start: undefined,
    end: undefined,
    tags: []
};

const prefixCls = "knowledge-version-form";

export interface KnowledgeVersionFormProps {
    id: any
    knowledgeId: any
    visible?: boolean
    setVisible: (visible: any) => void
    onSubmit: (values: any, metaData: any) => void
}

export const KnowledgeVersionForm = (props: KnowledgeVersionFormProps) => {
    const [form] = useForm();
    const {isMobile} = useBreakpoint();

    const { getLocalisation } = useTranslator("knowledge");

    const { visible, setVisible, id, onSubmit, knowledgeId } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [record, setRecord] = useState<KnowledgeVersionProps>();
    const [optionsTags, setOptionsTags] = useState<TagsListOptionProps[]>([]);
    
    
    const handleCancel = () => {
        setVisible(false);
    };

    const handleSave = () => {
        form.submit();
    };

    const renderVersion = (record?: any): KnowledgeVersionProps["name"] => {
        const { version, status_id } = record;

        if (!version) {
            return '1.0'
        }
        const parts = version!.split('.')

        if (status_id !== 4) {
            parts[0] = parseInt(parts[0]) + 1;
            parts[parts.length - 1] = 0;
        } else {
            parts[parts.length - 1] = parseInt(parts[parts.length - 1]) + 1;
        }
        return parts.join('.');
    }

    const handleRemoveTag = async ({value}: any) => {
        const result = await TagsApi.removeVersionTag(value);

        if (result) {
            const tags = await TagsApi.getVersionTags();

            setOptionsTags(tags);
        }

    }

    const handleSubmitTag = async (values: any, { setVisible, setLoading }: any) => {
        setLoading(true);
        const result = await TagsApi.createVersionTag({ ...values});

        if (!!result) {
            const tags = await TagsApi.getVersionTags();

            setOptionsTags(tags);

            setVisible(() => {
                setLoading(false);
                return false
            });
        }
        else {
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchInit = async () => {

            setLoading(true);
            const tags = await TagsApi.getVersionTags();

            const result = id !== "none" && (await VersionsApi.getRecord(id))
            const changed: KnowledgeVersionChangedProps = knowledgeId && knowledgeId !== "none" && await VersionsApi.getChanged(knowledgeId)
            const record: KnowledgeVersionProps = {...defaultValue, ...(result || {}), ...(changed || {})};

            if (id === "none") {
                const result =  knowledgeId && knowledgeId !== "none" && (
                    await KnowledgesApi.getRecord(knowledgeId)
                );
                record.name = renderVersion(result && result)
            }

            setOptionsTags(tags);
            setRecord({
                ...record,
                tags: record?.tags?.map(({ value }: any) => value)
            });

            setLoading(false);
        };

        form.resetFields();

        if (id && visible) {
            fetchInit().catch(console.error);
        }
    }, [id, knowledgeId, visible, form]);

    useEffect(() => {
        form.setFieldsValue({...record});
    }, [record, form]);

    return (
        <Modal
            className={clsx(prefixCls)}
            title={getLocalisation('create_version')}
            visible={visible}
            width="900px"
            onCancel={handleCancel}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleSave}
                    loading={submitting}
                >
                    {getLocalisation('save')}
                </Button>,
                <Button
                    key="cancel"
                    type="secondary"
                    onClick={handleCancel}
                    disabled={submitting}
                >
                    {getLocalisation('close')}
                </Button>,
            ]}
        >
            <Loader spinning={loading} size="middle">
                {!loading && (
                    <Form
                        form={form}
                        layout="vertical"
                        autoComplete="off"
                        onFinish={(values: any) => onSubmit(values, { setSubmitting })}
                        initialValues={{ ...record }}
                    >
                        <Space
                            size="large"
                            align="start"
                            className={clsx(`${prefixCls}-row`)}
                            direction={isMobile ? "vertical" : "horizontal"}
                        >
                            <Space direction="vertical" size="middle">

                                <FormItem
                                    rules={[{ required: true, message: getLocalisation('please_write_number_version') }]}
                                    className={`${prefixCls}-name`}
                                    label={getLocalisation('number_version')}
                                    name="name"
                                    layout="horizontal"
                                >
                                    <FieldDisplay/>
                                </FormItem>

                                <FormItem
                                    className={clsx(clsx(`${prefixCls}-field-annotation`))}
                                    name="content_change"
                                    label={getLocalisation('annotation')}
                                    rules={[
                                        { required: knowledgeId, message: getLocalisation('please_annotation') },
                                        { whitespace: knowledgeId, message: getLocalisation('please_annotation') }
                                    ]}
                                ><TextEditor/></FormItem>

                                <FormItem
                                    label={getLocalisation('select_tag_version')}
                                    name="tags"
                                >
                                    <TagsList
                                        onRemove={handleRemoveTag}
                                        onCreate={handleSubmitTag}
                                        options={optionsTags}
                                    />
                                </FormItem>
                            </Space>
                            <Space direction="vertical" size="middle">

                                <FormItem
                                    name="content"
                                    className={clsx(clsx(`${prefixCls}-field-content`))}
                                    label={getLocalisation('source')}
                                    rules={[
                                        { required: knowledgeId, message: getLocalisation('please_source') },
                                        { whitespace: knowledgeId, message: getLocalisation('please_source') }
                                    ]}
                                ><TextEditor/></FormItem>
                            </Space>

                        </Space>
                    </Form>
                )}
            </Loader>
        </Modal>
    );
};