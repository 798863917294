import axios from "axios";
import {getLanguageIcon, settings} from "@helpers";
import { LanguageItemProps } from "@types";

const transformItem = ({ iso639_3, ...item}: any) => ({
    ...item,
    iso: iso639_3,
    icon: getLanguageIcon(iso639_3)
})

export async function getProjectList(params?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/languages`,
        method: 'GET',
        params
    }).catch((e) => {console.error(e)});

    const result: Array<LanguageItemProps> = res?.data?.success && res?.data?.result.map((item: any) => transformItem(item));

    return result;
}

export async function getList() {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/languages`,
        method: 'GET',
        params: { all: 1 }
    }).catch((e) => { console.error(e) });

    const result: Array<LanguageItemProps> = res?.data?.success && res?.data?.result.map((item: any) => transformItem(item));

    return result;
}

export const LanguagesApi = { getProjectList, getList };