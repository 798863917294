import React, { useState } from 'react';
import { Tooltip, ButtonText, notificationSuccess } from '@components';
import { useTranslator } from "@helpers";
import { WarningIcon } from "@icons";
import { UsersApi } from "@api";
import clsx from "clsx";

import "./ButtonEmailCheck.less";

const prefixCls = "cmp-button-email-check";

export const ButtonEmailCheck = (props: any) => {
    const { getLocalisation } = useTranslator("components")
    const { record } = props;
    const [loading, setLoading] = useState<boolean>();

    const handleCheckEMail = async () => {
        setLoading(true)
        const result = await UsersApi.checkEmail(record.id);

        if (result) {
            notificationSuccess({
                message: getLocalisation('send_confirm'),
                description: getLocalisation('send_mail_confirm')
            });
        }

        setLoading(false);
    }
    return <Tooltip
        placement="left"
        title={getLocalisation('retry_mail_confirm', {
            "{email}": record.email
        })}
    >
        <ButtonText
            loading={loading}
            onClick={handleCheckEMail}
            className={clsx(`${prefixCls}`)}
            beforeIcon={<WarningIcon />}
        />
    </Tooltip>
}