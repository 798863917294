import {useTranslator} from "@helpers";
import {BlankPage, Card} from "@components";
import {HomeIcon} from "@assets/icons";
import {KnowledgeViewItem} from "./KnowledgeViewItem";
import {useParams} from "react-router-dom";
import "./KnowledgeView.less";

const prefixCls = "page-knowledge-view";

export const KnowledgeView = () => {

    const {getLocalisation} = useTranslator("knowledge");

    const {item, project}: any = useParams();

    const getBreadcrumbItems = () => {
        return [
            {
                href: "/home",
                title: getLocalisation('home_page'),
                icon: <HomeIcon/>,
            },
            {
                title: getLocalisation('view_article'),
            }
        ];
    };

    return (
        <BlankPage
            breadcrumb={getBreadcrumbItems()}
        >
            <Card className={prefixCls}>
                <KnowledgeViewItem
                    itemId={item}
                    contextId={Number(project)}
                />
            </Card>
        </BlankPage>
    );
};
