import React, {useState, useEffect} from "react";
import {
    TypographyText, Tag, Space, Tooltip,
    Loader, Drawer, CompareVersionItem, List, MemberView, ButtonText
} from "@components";
import { KnowledgeItemProps, KnowledgeVersionProps } from "@types";
import { toDateTime, toDate, useTranslator, useBreakpoint } from "@helpers";

import clsx from "clsx";

import "./KnowledgeItemVersions.less";
import {KnowledgesApi, VersionsApi} from "@api";

const prefixCls = "page-knowledge-item-versions";

export interface KnowledgeItemVersionsProps {
    id: any
    visible?: boolean
    simple?: boolean
    onClose?: () => void;
}

export const KnowledgeItemVersions: React.FC<KnowledgeItemVersionsProps> = (props: KnowledgeItemVersionsProps) => {
    const { id, visible, onClose, simple } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [versions, setVersions] = useState<Array<KnowledgeVersionProps>>([]);

    const [selectedVersion, setSelectedVersion] = useState<KnowledgeVersionProps>();
    const [differentVersion, setDifferentVersion] = useState<KnowledgeVersionProps>();
    const [selectedKnowledgeItem, setSelectedKnowledgeItem] = useState<KnowledgeItemProps | undefined>();
    const [differentKnowledgeItem, setDifferentKnowledgeItem] = useState<KnowledgeItemProps | undefined>();
    const [currentItem, setCurrentItem] = useState<KnowledgeItemProps>();
    
    const { isTablet } = useBreakpoint();
    const { getLocalisation } = useTranslator("knowledge");

    const handleClickVersion = async (key: any) => {

        let differentVersion: any;
        const version: any = versions.find(({id}: any, index: number) => {

            if (id !== key) {
                return false;
            }

            differentVersion = versions[index - 1];
            return true;
        });

        setLoading(true);

        const selectedKnowledgeItem = await KnowledgesApi.getContent(key);
        const differentKnowledgeItem = differentVersion ? await KnowledgesApi.getContent(differentVersion.id) : undefined;

        setSelectedVersion(version);
        setSelectedKnowledgeItem(selectedKnowledgeItem);

        setDifferentVersion(differentVersion);
        setDifferentKnowledgeItem(differentKnowledgeItem);

        setLoading(false);

    }

    const getTypeColor = (id: any) => {
        switch (id) {
            case 4: return "danger";
            case 3: return "success";
            case 2: return "warning";
            case 1: return "secondary";
        }
    }

    const isSelectedVersion = () => (isTablet && !selectedKnowledgeItem) || !isTablet;

    useEffect(() => {
        const fetchInit = async () => {

            setSelectedVersion(undefined);
            setCurrentItem(undefined);
            setSelectedKnowledgeItem(undefined);

            setLoading(true);

            const record = await KnowledgesApi.getRecord(id);

            const result = record ? await VersionsApi.getList(record.section_id, id) : [];

            if (result) {
                setVersions([...result].sort((a, b) => a.id - b.id))
            }

            setLoading(false)
        }

        if (visible && id) {
            fetchInit().catch(console.error)
        }

    }, [visible, id])

    return (
        <Drawer
            mask={true}
            closable={true}
            placement="right"
            visible={visible}
            onClose={onClose}
            className={clsx(`${prefixCls}`)}
            size={!isTablet ? 'large' : 'small'}
        >
            <Loader spinning={loading} size="middle">
                <Space size='large' direction='vertical'>
                    {isSelectedVersion() && <TypographyText level="large">{getLocalisation('list_versions')}</TypographyText>}
                    {!isSelectedVersion() && <ButtonText
                        size="large"
                        type="primary"
                        onClick={() => setSelectedKnowledgeItem(undefined)}
                    >{getLocalisation('return_version_list')}
                    </ButtonText>}
                    <div className={clsx(`${prefixCls}-body`)}>
                        {isSelectedVersion() && <List
                            onChange={handleClickVersion}
                            selectKey={selectedVersion?.id}
                            items={versions
                                .filter(({status_id}: any) => !simple || status_id === 3)
                                .sort((a: any, b: any) => b.id - a.id)
                                .map(({ id, name, start, end, tags, comment, status_id, status_name, member_name, author, is_current, create_date }: KnowledgeVersionProps) => ({
                                    value: id,
                                    label: (<Space>
                                        {`${getLocalisation('version')}: ${name}`}
                                        {!!is_current && <Tag
                                            type="error"
                                            size='small'
                                        >{getLocalisation('current_version')}</Tag>}
                                    </Space>),
                                    description: <Space direction="vertical">
                                        <Space direction="horizontal">
                                            {tags && tags.map(({ value, type, label }: any) => (
                                                <Tag
                                                    key={value}
                                                    type={type}
                                                    size='small'
                                                >{label}</Tag>
                                            ))}
                                        </Space>
                                        {(start || end) && <Space size='small' direction="horizontal">
                                            {start && <TypographyText disabled level="small">{getLocalisation('from')}: {toDate(start)}</TypographyText>}
                                            {end && <TypographyText disabled level="small">{getLocalisation('to')}: {toDate(end)}</TypographyText>}
                                        </Space>}
                                        <Space>
                                            <TypographyText disabled level="small">{getLocalisation('status')}:</TypographyText>
                                            <Tooltip title={comment} placement="right">
                                                <span>
                                                    <TypographyText type={getTypeColor(status_id)} level="small">{status_name}</TypographyText>
                                                </span>
                                            </Tooltip>
                                        </Space>
                                        <Space>
                                            <MemberView name={member_name || author} />
                                            <TypographyText disabled level="small">{toDateTime(create_date)}</TypographyText>
                                        </Space>
                                    </Space>
                                }))}
                        />}

                        {selectedKnowledgeItem && <div className={clsx(`${prefixCls}-diff`)}>
                            <CompareVersionItem
                                currentVersion={selectedVersion}
                                currentKnowledgeItem={selectedKnowledgeItem}
                                differentVersion={differentVersion}
                                differentKnowledgeItem={differentKnowledgeItem}
                            />
                        </div>}
                    </div>
                </Space>

            </Loader>
        </Drawer>
    );
};