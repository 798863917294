import React from "react";
import {Breadcrumb as AntdBreadcrumb, BreadcrumbProps as AntdBreadcrumbProps} from 'antd';
import {Space} from '@components';
import {SizeProp} from '@types';
import {useNavigate} from "react-router-dom"
import clsx from 'clsx';
import "./Breadcrumb.less";

export interface BreadcrumbItemProps {
    title: React.ReactNode
    icon?: React.ReactNode
    href?: string
    onClick?: (href: BreadcrumbItemProps["href"], event: any) => void
}

export interface BreadcrumbProps {
    className?: AntdBreadcrumbProps["className"]
    size?: SizeProp
    items: Array<BreadcrumbItemProps>
}

const prefixCls = "cmp-breadcrumb";
const AntdBreadcrumbItem = AntdBreadcrumb.Item;

export const Breadcrumb: React.FC<BreadcrumbProps> = ({className, items, size = "middle"}: BreadcrumbProps) => {
    const navigate = useNavigate();

    const handleClick = (href: any, event: any) => {
        href && navigate(href);
        event.preventDefault();
    }

    const renderItems = (items: BreadcrumbProps["items"]) => (
        items.map(({ title, href, icon, onClick }: BreadcrumbItemProps, i: number) => (
            <AntdBreadcrumbItem
                key={i}
                href={href}
                prefixCls={clsx(`${prefixCls}-item`)}
                onClick={(event: any) => (onClick || handleClick)(href, event)}
            >
                <Space>{icon}{title}</Space>
            </AntdBreadcrumbItem>
        ))
    )

    return (<AntdBreadcrumb
        prefixCls={prefixCls}
        className={clsx(className, `${prefixCls}-${size}`)}
    >{renderItems(items)}</AntdBreadcrumb>);
}