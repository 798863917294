import React, { useEffect, useRef, useState } from "react";
import {
    Drawer, Space, Select, Button, EmptyRequest,
    Input, BackTop, AffixLoader
} from "@components";
import { useBreakpoint, useTranslator } from "@helpers";
import clsx from "clsx";

import "./ButtonNewsList.less";
import { ButtonNewsItem } from "./ButtonNewsItem";
import { NewsItemProps } from "@types";
import { StarIcon } from "@assets/icons";

const prefixCls = "cmp-button-news-list";

export interface ButtonNewsListProps {
    defaultFilter?: SortType
    news?: NewsItemProps[]
    visible?: boolean
    onClose?: (visible?: boolean) => void;
    onCreate?: () => void;
    onEdit?: (id: any) => void;
    onLoad?: (filter: any, search: any, metaData?: any) => void;
    onRemove?: (id: any, metaData?: any) => void;
    onCheck?: (id: any, metaData?: any) => void;
    onClickFavorites?: (id: any, is_favorite: any, metaData: any) => void;
    onClickHistory?: (id: any) => void;
}

export type SortType = 'all' | 'new' | 'last_day' | 'last_week' | 'last_month' | 'last_year';

export const ButtonNewsList: React.FC<ButtonNewsListProps> = (props: ButtonNewsListProps) => {

    const {
        news, visible, onClose, onCreate, defaultFilter,
        onRemove, onEdit, onCheck, onLoad, onClickFavorites, onClickHistory
    } = props;

    const timerRef = useRef<any>(null);

    const [filter, setFilter] = useState<SortType>("new");
    const [search, setSearch] = useState<string>()
    const [loading, setLoading] = useState<boolean>(true);
    const { isMobile } = useBreakpoint();

    const { getLocalisation } = useTranslator("components")

    const getSortOptions = () => [
        {
            value: "all",
            label: getLocalisation('all'),
        },
        {
            value: "new",
            label: getLocalisation('new'),
        },
        {
            value: "favorites",
            label: <span className = { clsx(`${prefixCls}-sort`)}>
                {getLocalisation('favorites')}
                <span className={clsx(`${ prefixCls }-sort-star`)}>
                    <StarIcon />
                </span>
            </span>,
        },
        {
            value: "last_day",
            label: getLocalisation('last_day'),
        },
        {
            value: "last_week",
            label: getLocalisation('last_week'),
        },
        {
            value: "last_month",
            label: getLocalisation('last_month'),
        },
        {
            value: "last_year",
            label: getLocalisation('last_year'),
        },
    ];

    const handleClose = () => {
        onClose && onClose(false)
        setSearch('')
    }

    const handleChangeSearch = (e: any) => {
        const value = e.target.value;

        timerRef.current && clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => setSearch(value), 800);
    }

    useEffect(() => {
        if (visible && filter) {
            onLoad && onLoad(filter, search, {setLoading});
        }

    }, [filter, visible, search, onLoad])

    useEffect(() => {
        if (visible && defaultFilter) {
            setFilter(defaultFilter);
        }

    }, [visible, defaultFilter])

    return (
        <Drawer
            mask={true}
            closable={true}
            placement="right"
            visible={visible}
            onClose={handleClose}
            className={clsx(`${prefixCls}`)}
            size={isMobile ? 'small' : 'middle'}
        >
            <Space size='large' direction='vertical'>
                <Space
                    className={clsx(`${prefixCls}-top`, {
                        [`${prefixCls}-top-mobile`]: isMobile
                    })}
                    size="large"
                >
                    <Input
                        size="small"
                        onChange={handleChangeSearch}
                        onPressEnter={handleChangeSearch}
                        placeholder={getLocalisation('search')}
                    />

                    <Space
                        size="large"
                    >

                        <Select
                            value={filter}
                            onChange={setFilter}
                            bordered={false}
                            options={getSortOptions()}
                        />
                        {onCreate && <Button
                                type="primary"
                                onClick={onCreate}
                            >
                                {getLocalisation('create_news')}
                            </Button>}
                        </Space>
                    </Space>
                    <AffixLoader
                        offsetTop={300}
                        target={() =>
                            document.querySelector('.cmp-drawer-wrapper-body') as HTMLElement}
                        spinning={loading}
                        size="middle"
                    />
                    {news && news.map((item: any) =>
                        <ButtonNewsItem
                            key={item.id}
                            item={item}
                            onRemove={onRemove}
                            onEdit={onEdit}
                            onCheck={onCheck}
                            onClickFavorites={onClickFavorites}
                            onClickHistory={onClickHistory}
                        />
                    )}
                </Space>
                {news && !news.length && <EmptyRequest/>}
            <BackTop
                target={() =>
                    document.querySelector('.cmp-drawer-wrapper-body') as HTMLElement}
            />
        </Drawer>
    );
};