import React, {useEffect, useRef, useState} from "react";
import {
    Input,
    InputProps, InputSearch,
    Popover, PopoverProps
} from "@components";
import clsx from "clsx";

import "./Combobox.less";


export interface ComboboxProps {
    className?: PopoverProps["className"];

    delay?: number
    onSearch?: (value: any, controller?: AbortController) => void;

    controller?: any;
    onVisibleChange?: (visible: boolean) => void;

    onInputChange?: (value: any) => void
    onInputKeyDown?: InputProps['onKeyDown']
    onInputKeyPress?: InputProps['onKeyPress']
    minChars?: number;

    prefix?: InputProps['prefix'];
    suffix?: InputProps['suffix'];
    content?: PopoverProps['content'];

    disabled?: InputProps['disabled']
    loading?: InputProps['loading']
    size?: InputProps['size'];
    type: 'input' | 'search';
    placement?: PopoverProps['placement'];
    placeholder?: InputProps['placeholder'];
}

const prefixCls = "cmp-combobox";

export const useComboboxController = () =>  {
    const controller = useRef<any>({});

    return controller.current;
}

export const Combobox: React.FC<ComboboxProps> = (props: ComboboxProps) => {
    const refTimer = useRef<any>(null);
    const apiController = useRef<AbortController>();

    const {
        placeholder, className, content, prefix, delay = 500, controller,
        suffix, size, type, onSearch, placement, onVisibleChange,
        onInputChange, minChars = 0, onInputKeyDown, onInputKeyPress, disabled, loading
    } = props;

    const [search, setSearch] = useState<string>('');
    const [visible, setVisible] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);

    const handleChange = (event: any) => {
        const { value } = event.target;
        setSearch(value);
    }

    const handleVisibleChange = (visible: any) => {
        setVisible(visible);
        onVisibleChange?.(visible);
    }

    const handleFocus = () => {
        setFocused(true);
    }

    const handleBlur = () => {
        setFocused(false);
    }

    useEffect(() => {

        if (refTimer.current) {
            apiController.current?.abort();
            clearTimeout(refTimer.current);
        }

        refTimer.current = setTimeout(async () => {
            apiController.current = new AbortController();

            if (search.length >= minChars && focused) {
                setVisible(true);
                onVisibleChange?.(true);
                onSearch && onSearch(search, apiController.current);
            }
        }, delay);

        onInputChange && onInputChange(search);

        return () => {
            if (refTimer.current) {
                clearTimeout(refTimer.current);
                apiController.current?.abort();
            }
        }
    // eslint-disable-next-line
    }, [focused, search, delay])

    useEffect(() => {
        if (controller) {
            controller.setSearch = setSearch
            controller.setVisible = setVisible
        }

    }, [controller])

    return (
        <Popover
            arrow={false}
            content={content}
            visible={visible && !!content}
            placement={placement}
            className={clsx(className, `${prefixCls}-popover`)}
            onVisibleChange={handleVisibleChange}
        >
            {type === 'input' && <Input
                loading={loading}
                disabled={disabled}
                size={size}
                value={search}
                onChange={handleChange}
                placeholder={placeholder}
                suffix={suffix}
                prefix={prefix}
                onKeyDown={onInputKeyDown}
                onKeyPress={onInputKeyPress}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />}
            {type === 'search' && <InputSearch
                disabled={disabled}
                loading={loading}
                size={size}
                value={search}
                onChange={handleChange}
                placeholder={placeholder}
                suffix={suffix}
                prefix={prefix}
                onKeyDown={onInputKeyDown}
                onKeyPress={onInputKeyPress}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />}
        </Popover>
    );
};
