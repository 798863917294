import React from 'react';
import clsx from 'clsx';

import { ForwardIcon } from '@assets/icons';

import "./List.less"

export type ListItemProps = {
    value: any
    label: React.ReactNode
    description: React.ReactNode
    getPrefix?: (record: ListItemProps) => React.ReactNode
}

export interface ListProps {
    items?: ListItemProps[];
    selectKey?: any;
    onChange?: (key: any) => void;
    className?: string;
}

const prefixCls = "cmp-list";


export const List: React.FC<ListProps> = (props: ListProps) => {
    const { items, selectKey, onChange, className } = props;

    const handleChange = (item: any) => {
        onChange && onChange(item)
    }

    return (
        <div className={clsx(prefixCls, className)}>
            {items?.map(({ value, label, description, getPrefix, ...props }: ListItemProps) =>
                <div
                    key={value}
                    onClick={() => handleChange(value)}
                    className={clsx(`${prefixCls}-item`,
                        { [`${prefixCls}-item-active`]: selectKey === value }
                    )}
                >
                    {getPrefix && getPrefix({ value, label, description, ...props })}

                    <div className={clsx(`${prefixCls}-item-body`)}>
                        <div className={clsx(`${prefixCls}-label`)}>{label}</div>
                        {description && <div className={clsx(`${prefixCls}-description`)}>{description}</div>}
                    </div>

                    <span className={clsx(`${prefixCls}-icon`)}>
                        <ForwardIcon />
                    </span>

                </div>)}
        </div>
    )
};