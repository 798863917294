import React from "react";
import {FileItemProps, KnowledgeItemProps, SizeProp} from "@types";
import {
    Tag, Space, TypographyText,
    Breadcrumb, EmptyRequest, FilesList, MemberView, TagsList
} from "@components";
import {getShortContent, toDateTime, useTranslator} from "@helpers";

import clsx from "clsx";

import "./KnowledgeList.less";

export interface KnowledgeListProps {
    size?: SizeProp
    onClick?: (id: KnowledgeItemProps["id"], e: any) => void;
    items?: KnowledgeItemProps[];
}

const prefixCls = "cmp-knowledge-list";

export const KnowledgeList: React.FC<KnowledgeListProps> = (props: KnowledgeListProps) => {
    const {onClick, items, size = "middle"} = props;
    const {getLocalisation} = useTranslator("components");

    const getVersionTag = (version: string) => {
        const parts = version.split(".");
        const isUpdate = parts[0] && parseInt(parts[0]) > 1

        return <Tag
            size="small"
            type={!isUpdate ? "warning" : "default"}
        >{getLocalisation(!isUpdate ? "new" : "update")}</Tag>
    }

    return (
        <div className={clsx(`${prefixCls}`, `${prefixCls}-${size}`)}>
            {items && items.map(({
                member_name, section_name, category_name, modify_date, author,
                title, tags, id, content, files, languageIcon, version, version_content_change
            }: KnowledgeItemProps) => (
                <Space
                    key={id}
                    size={size}
                    direction="vertical"
                    className={clsx(`${prefixCls}-item`)}
                >
                    {size !== "small" && tags && !!tags.length && <Space wrap direction="horizontal" size="small">
                        <TagsList
                            size="small"
                            active
                            maxCount={8}
                            options={tags}
                        />
                    </Space>}
                    <Breadcrumb
                        size={size}
                        items={[
                            {
                                icon: languageIcon,
                                title: section_name,
                            },
                            {
                                title: category_name,
                            },
                            {
                                title: <Space size="small">
                                    <span
                                        className={clsx(`${prefixCls}-link`)}
                                        onMouseUp={(e: any) => onClick && onClick(id, e)}
                                    >{title}</span>
                                    {!!version && getVersionTag(version)}
                                </Space>

                            }
                        ]}
                    />

                    {version && <Space
                        direction="vertical"
                        className={clsx(`${prefixCls}-row-version`)}
                    >
                        <Space size="small">
                            <TypographyText level="small">{getLocalisation("version")}:</TypographyText>
                            <Tag size="small">{version}</Tag>
                        </Space>

                        {version_content_change && <div
                            className={clsx(`${prefixCls}-content-version`)}
                            dangerouslySetInnerHTML={{__html: version_content_change}}
                        />}
                    </Space>}

                    {content && <div className={clsx(`${prefixCls}-content`)}>{getShortContent(content, size)}</div>}

                    {size !== "small" && files && <FilesList
                        size={size}
                        readonly={true}
                        files={files.filter(({archived}: FileItemProps) => !archived)}
                        value={files.map(({id}: any) => id)}
                    />}

                    <Space justify="space-between">
                        <MemberView name={member_name || author} />
                        <TypographyText disabled level="small">{toDateTime(modify_date)}</TypographyText>
                    </Space>
                </Space>
            ))}
            {(!items || !items.length) && <EmptyRequest/>}
        </div>

    );
}
