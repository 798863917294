import { useContext } from 'react';
import { Button, Space, TypographyTitle } from '@components';
import { useTranslator } from "@helpers";
import { WarningIcon } from '@assets/icons';
import { SessionsContext } from '@context';
import clsx from 'clsx';

import './PageDenied.less'

const prefixCls = "page-denied";

export const PageDenied = () => {
    const { onRemoveSession } = useContext(SessionsContext);
    
    const { getLocalisation } = useTranslator("page-denied")

    return (
        <div className={clsx(`${prefixCls}`)}>
            <Space align="center" direction="vertical">
                <span className={clsx(`${prefixCls}-warning-icon`)}><WarningIcon /></span>
                <TypographyTitle type="secondary">{getLocalisation('not_projects_available')}</TypographyTitle>
                <Button
                    type="primary"
                    onClick={onRemoveSession}
                >
                    {getLocalisation('back_home')}
                </Button>
            </Space>
        </div>
    );
};