import React from "react";
import { DatePicker as AntdDatePicker, DatePickerProps as AntdDatePickerProps} from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import clsx from "clsx";

import "./DatePicker.less";


import { CloseIcon, СalendarIcon } from "@assets/icons";

export interface DatePickerProps {
    placement?: AntdDatePickerProps["placement"],
    className?: AntdDatePickerProps["className"],
    value?: AntdDatePickerProps["value"],
    defaultValue?: AntdDatePickerProps["defaultValue"],
    onChange?: AntdDatePickerProps["onChange"]
    showCloseIcon?: boolean
}

const dateFormat = 'DD-MM-YYYY';

export const DatePicker: React.FC<DatePickerProps> = (props: DatePickerProps) => {
    const {
        placement, className, onChange, value, defaultValue, showCloseIcon = true
    } = props;

    return (
        <AntdDatePicker
            locale={locale}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            placement={placement}
            className={clsx(className)}
            suffixIcon={<СalendarIcon />}
            clearIcon={showCloseIcon ? <CloseIcon/> : null}
            bordered={false}
            format={dateFormat}
            showToday={false}
        />
    )
}