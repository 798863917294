import React, { useRef } from "react";
import { useBreakpoint, useTranslator } from "@helpers";
import { DatePicker, DatePickerProps, InputSearch, Select, SelectProps, Space } from "@components";
import { DirectoryListIcon, TreeListIcon } from "@assets/icons";
import moment from "moment";
import clsx from "clsx";

import "./Toolbar.less";

export type ToolbarGroupByType = 'card' | 'table';
export type PeriodType = 'from' | 'to';

export interface ToolbarProps {
    sortOptions?: SelectProps["options"];
    defaultSort?: SelectProps["defaultValue"];
    groupBy?: ToolbarGroupByType;
    onChangeSearch?: (value: string) => void;
    onChangeSort?: (value: string) => void;
    onChangeGroupBy?: () => void;
    onChangePeriod?: (period: PeriodType, value: DatePickerProps['value']) => void;
}

const prefixCls = "cmp-toolbar";

export const Toolbar = (props: ToolbarProps) => {
    const { getLocalisation } = useTranslator("components")

    const {
        onChangeSearch, sortOptions, onChangeSort,
        defaultSort, groupBy, onChangeGroupBy, onChangePeriod
    } = props;

    const tm = useRef<any>(null);
    const { isMobile } = useBreakpoint();

    const handleChangeSearch = (event: any) => {
        const value = event.target.value;

        tm.current && clearTimeout(tm.current);

        tm.current = setTimeout(() => onChangeSearch && onChangeSearch(value), 500);
    };

    return (
        <div className={clsx(`${prefixCls}`, {
            [`${prefixCls}-mobile`]: isMobile
        })}>
            <Space wrap size='middle' className={clsx(`${prefixCls}-action`)}>
                {!isMobile && onChangeGroupBy && <div
                    className={clsx(`${prefixCls}-icon`, `${prefixCls}-group`)}
                    onClick={onChangeGroupBy}
                >
                    {groupBy === 'card' && <TreeListIcon />}
                    {groupBy === 'table' && <DirectoryListIcon />}
                </div>}
                {sortOptions && (
                    <Select
                        bordered={false}
                        onChange={onChangeSort}
                        defaultValue={defaultSort}
                        value={defaultSort}
                        className={clsx(`${prefixCls}-sort-field`)}
                        placeholder={getLocalisation('sort_placeholder')}
                        options={sortOptions}
                    />
                )}
                {onChangePeriod && <>
                    <DatePicker
                        onChange={(value) => onChangePeriod('from', value)}
                        defaultValue={moment()}
                        showCloseIcon={false}
                    />
                    <DatePicker
                        onChange={(value) => onChangePeriod('to', value)}
                        defaultValue={moment()}
                        showCloseIcon={false}
                    />
                </>}
            </Space>

            {onChangeSearch && (
                <InputSearch
                    className={clsx(`${prefixCls}-input-search`)}
                    onChange={handleChangeSearch}
                    onPressEnter={handleChangeSearch}
                    placeholder={getLocalisation('search')}
                />
            )}
        </div>
    );
};
