import React, {useState} from "react";
import {getRoute, useBreakpoint} from "@helpers";
import {Drawer, KnowledgeList} from "@components";
import {KnowledgeViewItem} from "@pages";
import {CloseIcon} from "@icons";
import { Tabs } from 'antd';
import clsx from "clsx";

import "./GlobalSearch.less";

export interface GlobalSearchProps {
    visible?: boolean
    onClose?: () => void
    items?: any
    contextId?: any
}

const prefixCls = "cmp-page-global-search";

export const GlobalSearch: React.FC<GlobalSearchProps> = (props: GlobalSearchProps) => {
    const { items, visible, onClose, contextId } = props;

    const { isMobile, isTablet } = useBreakpoint();
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [activeKey, setActiveKey] = useState<any>("");

    const handleEditTab = (key: any) => {
        const newSelectedItems = selectedItems.filter((id: any) => `key-${id}` !== key);
        if (key === activeKey) {
            setActiveKey(newSelectedItems[0] && `key-${newSelectedItems[0]}`);
        }
        setSelectedItems(newSelectedItems)
    };

    const handleChangeTab = (activeKey: any) => setActiveKey(activeKey);

    const handleKnowledgeClick = (id: any, e: MouseEvent) => {
        if (e.ctrlKey || e.button === 1) {
            window.open(getRoute('knowledgeView', { 'project': contextId, 'item': id }))
            return;
        }

        setActiveKey(`key-${id}`);

        setSelectedItems(
            (selectedItems: any) => !selectedItems.includes(id) ? [id, ...selectedItems] : selectedItems
        )

    }

    const handleLinkEdit = () => {
        onClose?.()
    }

    return (
        <Drawer
            mask={true}
            destroyOnClose={false}
            closable={!(isTablet || isMobile)}
            placement="top"
            visible={visible && !!items}
            onClose={onClose}
            size='large'
            className={clsx(`${prefixCls}-drawer`, {
                [`${prefixCls}-drawer-tablet`]: isTablet,
                [`${prefixCls}-drawer-mobile`]: isMobile
            })}
        >
            <section className={clsx(`${prefixCls}-list`)}>

                {items && <KnowledgeList
                    onClick={handleKnowledgeClick}
                    size="small"
                    items={items}
                />}

            </section>

            <section className={clsx(`${prefixCls}-view`)}>
                {contextId && selectedItems && !!selectedItems.length && <Tabs
                    hideAdd
                    size="small"
                    type="editable-card"
                    activeKey={activeKey}
                    onChange={handleChangeTab}
                    onEdit={handleEditTab}
                    tabPosition="left"
                >
                    {selectedItems.map((id: any) => <Tabs.TabPane
                        key={`key-${id}`}
                        tab={`ID: ${id}`}
                        closable={true}
                        closeIcon={<CloseIcon />}
                    >
                        <KnowledgeViewItem
                            itemId={id}
                            contextId={contextId}
                            onLinkEdit={handleLinkEdit}
                        />
                    </Tabs.TabPane>)}

                </Tabs>}
            </section>


        </Drawer>
    );
};