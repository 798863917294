import React, { useState, FC, ReactNode } from 'react';
import { Avatar, Button, Modal, Slider, Space } from '@components';
import { MinusIcon, PlusIcon, RedoIcon, UndoIcon } from '@assets/icons';
import clsx from "clsx";
import { useTranslator } from "@helpers";

import "./UploadLogo.less";
import { Upload } from 'antd';
import { UploadLogoProvider, useUploadLogoLibs } from './UploadLogoProvider';

export interface UploadLogoProps {
    placeholder?: ReactNode
    title?: any
    value?: any
    onChange?: (value: any) => void;
}

const prefixCls = "cmp-upload-logo";

const defaultValue = {
    scale: 1,
    rotate: 0
}

export const Logo: FC<UploadLogoProps> = (props: UploadLogoProps) => {
    const { placeholder, title, value, onChange } = props;
    const {ReactAvatar} = useUploadLogoLibs();

    let editor: any;
    const { getLocalisation } = useTranslator("components")

    const [imageModal, setImageModal] = useState<File>(value);
    const [visible, setVisible] = useState<boolean>(false)
    const [scale, setScale] = useState<number>(defaultValue.scale);
    const [rotate, setRotate] = useState<number>(defaultValue.rotate);

    const handleNewImage = (value: any) => {
        setImageModal(value.file.originFileObj)
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
        setRotate(defaultValue.rotate)
        setScale(defaultValue.scale)
    }

    const handleSave = () => {
        if (!editor) {
            return
        }

        const canvasScaled = editor.getImageScaledToCanvas().toDataURL();
        if (canvasScaled) {
            onChange && onChange(canvasScaled)
        }
        setVisible(false)
        setRotate(defaultValue.rotate)
        setScale(defaultValue.scale)

    };

    const handleChangeScale = (value: any) => {
        setScale(value)
    }

    const handleChangeRotate = (value: any) => {
        setRotate(value)
    }

    const setEditorRef = (ed: any) => {
        editor = ed;
    };

    return (
        <div className={prefixCls}>
            <Avatar size={200}>
                {!value ? placeholder : <img src={value} alt="avatar" />}
                <Upload
                    multiple={false}
                    accept='image/*'
                    maxCount={1}
                    prefixCls={clsx(`${prefixCls}-file`)}
                    onChange={handleNewImage}
                    customRequest={({ onSuccess }: any) => onSuccess && onSuccess()}
                />
            </Avatar>

            <Modal
                width='516px'
                footer={false}
                visible={visible}
                onCancel={handleCancel}
                title={title}
            >
                <Space direction='vertical'>
                    {/*eslint-disable-next-line*/}
                    <ReactAvatar.default
                        ref={setEditorRef}
                        image={imageModal}
                        width={300}
                        height={300}
                        border={50}
                        color={[255, 255, 255, 0.5]}
                        scale={scale}
                        rotate={rotate}
                        borderRadius={200}
                    />
                    <Slider
                        prefix={<MinusIcon />}
                        suffix={<PlusIcon />}
                        value={scale}
                        step={0.1}
                        min={0.3}
                        max={4}
                        onChange={handleChangeScale}
                    />
                    <Slider
                        prefix={<UndoIcon />}
                        suffix={<RedoIcon />}
                        max={360}
                        value={rotate}
                        onChange={handleChangeRotate}
                    />
                    <Space>
                        <Button type='primary' children={getLocalisation('save')} onClick={handleSave} />
                        <Button type='secondary' children={getLocalisation('close')} onClick={handleCancel} />
                    </Space>
                </Space>
            </Modal>
        </div>
    );
};


const UploadLogoAsync = (props: UploadLogoProps) => {
    const { isLoaded } = useUploadLogoLibs();

    if (!isLoaded) {
        return null;
    }

    return <Logo {...props} />;
};

export const UploadLogo = (props: UploadLogoProps) => {
    return (
        <UploadLogoProvider>
            <UploadLogoAsync {...props} />
        </UploadLogoProvider>
    );
};