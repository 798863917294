import React from "react";
import {Space, TypographyTitle, Button} from '@components';
import {useTranslator} from "@helpers";
import {SuccessIcon} from "@icons";
import clsx from 'clsx';

import './PageRestoreSuccess.less'


const prefixCls = "page-restore-success";

export const PageRestoreSuccess = ({onBack, type}: any) => {
    const { getLocalisation } = useTranslator("page-restore");

    return (
        <Space className={clsx(`${prefixCls}`)} align="center" direction="vertical" size="large">

            <span className={clsx(`${prefixCls}-icon`)}>
                <SuccessIcon />
            </span>

            <TypographyTitle>{getLocalisation(type === "active" ? "active_user_title" : 'change_password_title')}</TypographyTitle>

            <Button
                key="sign-in"
                type="secondary"
                onClick={onBack}
            >{getLocalisation('next')}</Button>
        </Space>
    );
};