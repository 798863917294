import axios from 'axios'
import {settings} from "@helpers";
import {TagItemProps} from "@types";

const transformItem = ({name, id, type_name}: any) => ({
    label: name,
    value: id,
    type: type_name
})

export async function getArticleTags() {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/tags/article`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: Array<TagItemProps> = res?.data?.success && res?.data?.result.map((item: any) => transformItem(item));

    return result;
}

export async function getFileTags() {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/tags/file`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: Array<TagItemProps> = res?.data?.success && res?.data?.result.map((item: any) => transformItem(item));

    return result;
}

export async function getVersionTags() {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/tags/version`,
        method: 'GET'
    }).catch((e) => {console.error(e)});

    const result: Array<TagItemProps> = res?.data?.success && res?.data?.result.map((item: any) => transformItem(item));

    return result;
}

export async function createFileTag(data: TagItemProps) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/tags/file`,
        method: 'POST',
        data: data
    }).catch((e) => {console.error(e)});

    if (!res) return null;

    const result: TagItemProps = res?.data?.success && transformItem(res.data.result[0]);

    return result;
}

export async function createArticleTag(data: TagItemProps) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/tags/article`,
        method: 'POST',
        data: data
    }).catch((e) => {console.error(e)});

    if (!res) return null;

    const result: TagItemProps = res?.data?.success && transformItem(res.data.result[0]);

    return result;
}

export async function createVersionTag(data: TagItemProps) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/tags/version`,
        method: 'POST',
        data: data
    }).catch((e) => {console.error(e)});

    if (!res) return null;

    const result: TagItemProps = res?.data?.success && transformItem(res.data.result[0]);

    return result;
}

export async function removeFileTag(id: any) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/tags/file/${id}`,
        method: 'DELETE'
    }).catch((e) => { console.error(e) });

    const result: boolean = res?.data?.success;

    return result;
}

export async function removeArticleTag(id: any) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/tags/article/${id}`,
        method: 'DELETE'
    }).catch((e) => { console.error(e) });
    
    const result: boolean = res?.data?.success;

    return result;
}

export async function removeVersionTag(id: any) {
    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/tags/version/${id}`,
        method: 'DELETE'
    }).catch((e) => {console.error(e)});

    const result: boolean = res?.data?.success;

    return result;
}

export const TagsApi = {
    getArticleTags, createArticleTag, removeArticleTag,
    getVersionTags, createVersionTag, removeVersionTag,
    getFileTags, createFileTag, removeFileTag
}
