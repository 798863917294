import React, { useState } from "react";
import { Card, List } from "@components";
import clsx from "clsx";

import "./DirectoryList.less";

export type DirectoryListItemValueType = any

export type DirectoryListItemProps = any

export interface DirectoryListProps {
    activeKey?: DirectoryListItemValueType
    items: Array<DirectoryListItemProps>
    onClick?: (key: any) => void
}

const prefixCls = "cmp-directory-list";

export const DirectoryList: React.FC<DirectoryListProps> = (props: DirectoryListProps) => {
    const { items, activeKey, onClick } = props;

    const [expandKeys, setExpandKeys] = useState<DirectoryListItemValueType>();

    const handleExpand = (key: string) => {
        if (key === expandKeys) setExpandKeys(undefined)
        else setExpandKeys(key);
    }
    const handleActive = (key: any) => {
        onClick!(key);
    }

    const getItemCard = ({ label, value, type = "default", items }: DirectoryListItemProps) => (
        <div className={clsx(`${prefixCls}-card`)} key={value}>
            <Card
                type={type}
                onClick={() => handleExpand(value)}
                className={clsx({ [`${prefixCls}-link`]: expandKeys === value })}
            >
                {label}
            </Card>
            {items && expandKeys === value &&
                <>
                    <div
                        className={clsx(`${prefixCls}-body`, "expand")}
                    >
                        <List
                            onChange={handleActive}
                            selectKey={activeKey}
                            items={items}
                        />
                    </div>
                    <div className={clsx(`${prefixCls}-content`)}><List items={items} /></div>
                </>
            }
        </div>
    )

    return (<div className={clsx(prefixCls)}>
        {items?.map((item: DirectoryListItemProps) => getItemCard(item))}
    </div>);
}