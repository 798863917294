import { createContext } from 'react';
import { ProjectItemProps } from '@types';

export type ProjectContextProps = {
    setCurrentProject?: any
    currentProject?: ProjectItemProps
}

const defaultValue: ProjectContextProps = {
    setCurrentProject: undefined,
    currentProject: undefined
}

export const ProjectsContext = createContext(defaultValue);