import {Alert as AntdAlert, AlertProps as AntdAlertProps} from 'antd'
import clsx from 'clsx'
import './Alert.less'
import {CloseIcon} from "@icons";
import React from "react";

const prefixCls = "cmp-alert"


export interface AlertProps {
    type?: AntdAlertProps["type"]
    message?: AntdAlertProps["message"]
    description?: AntdAlertProps["description"]
    closable?: AntdAlertProps["closable"]
}

export const Alert: React.FC<AlertProps> = (props: AlertProps) => {
    const { type, message, description, closable } = props;

    return (
        <AntdAlert
            type={type}
            message={message}
            closable={closable}
            description={description}
            prefixCls={clsx(`${prefixCls}`)}
            closeIcon={<CloseIcon/>}
        />
    )
}