import {MenuItemProps} from '@types';
import {getRoute} from './Utils';

const menuSystemItems: Array<MenuItemProps> = [
    {
        path: getRoute('controls'),
        control: "menu.item.controls.view",
        name: "Контролы",
        id: 1
    },
    {
        path: getRoute('procedures'),
        control: "menu.item.procedures.view",
        name: "Процедуры",
        id: 2
    },
    {
        path: getRoute('roles'),
        control: "menu.item.roles.view",
        name: "Роли",
        id: 3
    },
    {
        path: getRoute('projects'),
        control: "menu.item.projects.view",
        name: "Проекты",
        id: 4
    },
    {
        path: getRoute('roleTemplates'),
        control: "menu.item.role.templates.view",
        name: "Шаблоны ролей",
        id: 5
    },
    {
        path: getRoute('permissions'),
        control: "menu.item.permissions.view",
        name: "Доступы",
        id: 6
    }
];

export {menuSystemItems};
